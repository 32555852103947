import { Link } from "react-router-dom";
import facebook from "images/en/social/facebook.svg";
import instagram from "images/en/social/instagram.svg";
import linkedin from "images/en/social/linkedin.svg";
import tiktok from "images/en/social/tiktok.svg";
import youtube from "images/en/social/youtube.svg";

const FooterSocials = () => {
  return (
    <ul className="social">
      <li>
        <Link
          to="https://www.facebook.com/myorovera"
          target="_blank"
        >
          <img src={facebook} alt="facebook" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.instagram.com/myorovera?igshid=OGQ5ZDc2ODk2ZA=="
          target="_blank"
        >
          <img src={instagram} alt="instagram" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.linkedin.com/company/85636352/admin/feed/posts/"
          target="_blank"
        >
          <img src={linkedin} alt="linkedin" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.tiktok.com/@orovera?_t=8iHx3EmcbwL&_r=1"
          target="_blank"
        >
          <img src={tiktok} alt="tiktok" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.youtube.com/@orovera"
          target="_blank"
        >
          <img src={youtube} alt="youtube" />
        </Link>
      </li>
    </ul>
  );
};

export default FooterSocials;
