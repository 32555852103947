const useQuestions = () => {
  const questions = [
    {
      id: 1,
      orderIndex: 0,
      translations: [
        {
          id: 1,
          lang: "en",
          title: "Is my money safe?",
          body: "Yes. Orovera is proud to partner with a globally trusted, FCA regulated payment processor. All transactions made via Orovery are automatically secured with 3D secure and require authorization from your bank or card issuer for each payment sent."
        },
        {
          id: 2,
          lang: "pl",
          title: "Czy moje pieniądze są bezpieczne?",
          body: "Tak. Orovera z dumą współpracuje z zaufanym na całym świecie procesorem płatności podlegającym regulacjom FCA. Wszystkie transakcje dokonywane za pośrednictwem Orovery są automatycznie zabezpieczone 3D secure i wymagają autoryzacji ze strony Twojego banku lub wystawcy karty dla każdej wysłanej płatności."
        },
        {
          id: 3,
          lang: "uk",
          title: "Чи в безпеці мої гроші?",
          body: "Так. Orovera пишається тим, що співпрацює з платіжним процесором, якому довіряють у всьому світі і який регулюється FCA. Всі транзакції, здійснені через Orovera, автоматично захищаються за допомогою 3D secure і вимагають авторизації від вашого банку або емітента картки для кожного відправленого платежу."
        },
      ],
    },
    {
      id: 2,
      orderIndex: 1,
      translations: [
        {
          id: 4,
          lang: "en",
          title: "How and where is my data stored?",
          body: "Your personal data is safely stored on our servers, which are secured in accordance with applicable regulations and standards. Financial data is only stored temporarily and is stored as securely as personal data."
        },
        {
          id: 5,
          lang: "pl",
          title: "Jak i gdzie przechowywane są moje dane?",
          body: "Twoje dane osobowe są bezpiecznie przechowywane na naszych serwerach, które są zabezpieczone zgodnie z obowiązującymi przepisami i standardami. Dane finansowe są przechowywane wyłącznie tymczasowo i są tak samo bezpiecznie przechowywane jak dane osobowe."
        },
        {
          id: 6,
          lang: "uk",
          title: "Як і де зберігаються мої дані?",
          body: "Ваші персональні дані надійно зберігаються на наших серверах, які захищені відповідно до чинних норм і стандартів. Фінансові дані зберігаються лише тимчасово і зберігаються так само надійно, як і персональні дані."
        },
      ],
    },
    {
      id: 3,
      orderIndex: 2,
      translations: [
        {
          id: 7,
          lang: "en",
          title: "How to complete KYC and why is it necessary?",
          body: "To verify your account, we need photo ID and other information. This is a standard security measure we take to protect our customers from fraud. By verifying your identity, we can help you ensure that your account is not being used for illegal or unauthorized purposes. If you need help with what documents we can accept, please contact us and one of the team will be happy to guide you through the process."
        },
        {
          id: 8,
          lang: "pl",
          title: "Jak przejść KYC i dlaczego jest to konieczne?",
          body: "Aby zweryfikować Twoje konto, potrzebujemy dokumentu tożsamości ze zdjęciem i innych danych. Jest to standardowy środek bezpieczeństwa, który podejmujemy, aby chronić naszych klientów przed oszustwami. Weryfikując Twoją tożsamość, możemy pomóc Ci upewnić się, że Twoje konto nie jest wykorzystywane do celów nielegalnych lub nieautoryzowanych. Jeśli potrzebujesz pomocy w zakresie dokumentów, które możemy zaakceptować, skontaktuj się z nami, a jeden z członków zespołu z przyjemnością przeprowadzi Cię przez cały proces."
        },
        {
          id: 9,
          lang: "uk",
          title: "Як заповнити KYC і навіщо це потрібно?",
          body: "Щоб підтвердити ваш обліковий запис, нам потрібно посвідчення особи з фотографією та інша інформація. Це стандартний захід безпеки, який ми вживаємо, щоб захистити наших клієнтів від шахрайства. Перевіряючи вашу особу, ми можемо допомогти вам переконатися, що ваш обліковий запис не використовується в незаконних або несанкціонованих цілях. Якщо вам потрібна допомога щодо того, які документи ми можемо прийняти, будь ласка, зв'яжіться з нами, і один з членів команди буде радий допомогти вам у цьому процесі"
        },
      ],
    },
    {
      id: 4,
      orderIndex: 3,
      translations: [
        {
          id: 10,
          lang: "en",
          title: "What are the accepted forms of transactions",
          body: "Orovera allows you to make transactions using Visa and Mastercard payment cards added to your Apple Pay or Google Pay wallet on your phone.",
        },
        {
          id: 11,
          lang: "pl",
          title: "Jakie są akceptowane formy transakcji?",
          body: "Orovera umożliwia dokonywanie transakcji przy użyciu kart płatniczych Visa i Mastercard dodanych do twojego portfela Apple Pay lub Google Pay w telefonie."
        },
        {
          id: 12,
          lang: "uk",
          title: "Які форми транзакцій є прийнятними",
          body: "Orovera дозволяє здійснювати транзакції за допомогою платіжних карток Visa та Mastercard, доданих до гаманця Apple Pay або Google Pay на вашому телефоні."
        },
      ],
    },
    {
      id: 5,
      orderIndex: 4,
      translations: [
        {
          id: 13,
          lang: "en",
          title: "How long does it take to process transactions at Orovera?",
          body: "Transactions are settled immediately, no longer than a minute. This is where our magic shines."
        },
        {
          id: 14,
          lang: "pl",
          title: "Jak długo trwa przetwarzanie transakcji w Oroverze?",
          body: "Transakcje rozliczane są natychmiast, nie dłużej niż minuta. Na tym właśnie polega nasza magia."
        },
        {
          id: 15,
          lang: "uk",
          title: "Скільки часу займає обробка транзакцій в Orovera?",
          body: "Транзакції обробляються миттєво, не довше хвилини. Тут і проявляється наша магія."
        },
      ],
    },
    {
      id: 5,
      orderIndex: 4,
      translations: [
        {
          id: 13,
          lang: "en",
          title: "Do I need to top up my Orovera account before making any transaction?",
          body: "No, in Orovera you use your own payment cards that you already have, added to your Apple Pay or Google Pay wallet on your phone."
        },
        {
          id: 14,
          lang: "pl",
          title: "Czy muszę wcześniej doładować konto Orovera zanim dokonam jakiejkolwiek transakcji?",
          body: "Nie, w Oroverze korzystasz z własnych posiadanych już wcześniej kart płatniczych dodanych do twojego portfela Apple Pay lub Google Pay w telefonie."
        },
        {
          id: 15,
          lang: "uk",
          title: "Чи потрібно поповнювати рахунок Orovera перед здійсненням будь-якої транзакції?",
          body: "Ні, в Orovera ви використовуєте власні платіжні картки, які у вас вже є, додані до гаманця Apple Pay або Google Pay на вашому телефоні."
        },
      ],
    },
    {
      id: 6,
      orderIndex: 5,
      translations: [
        {
          id: 16,
          lang: "en",
          title: "How can I trade with Orovera?",
          body: "Via any chat using the financial keyboard."
        },
        {
          id: 17,
          lang: "pl",
          title: "W jaki sposób mogę robić transakcje Oroverą?",
          body: "Za pośrednictwem dowolnego czatu korzystając z klawiatury finansowej."
        },
        {
          id: 18,
          lang: "uk",
          title: "Як я можу торгувати в Orovera?",
          body: "Через будь-який чат за допомогою фінансової клавіатури."
        },
      ],
    },
    {
      id: 7,
      orderIndex: 6,
      translations: [
        {
          id: 19,
          lang: "en",
          title: "What type of transactions can I make with Orovera?",
          body: "You can make a transfer, request for money and group collections."
        },
        {
          id: 20,
          lang: "pl",
          title: "Jakiego rodzaju transakcji mogę dokonać Oroverą?",
          body: "Możesz dokonać przelewu, prośby o pieniądze oraz zrzutki grupowej."
        },
        {
          id: 21,
          lang: "uk",
          title: "Які типи транзакцій я можу здійснювати в Orovera?",
          body: "Ви можете зробити переказ, запит на отримання грошей та групові збори."
        },
      ],
    },
    {
      id: 8,
      orderIndex: 7,
      translations: [
        {
          id: 22,
          lang: "en",
          title: "Does the person receiving the transfer also need to have the Orovera app?",
          body: "It doesn't have to, the recipient only needs a payment card added to the Apple Pay or Google Pay wallet on the phone to collect the money."
        },
        {
          id: 23,
          lang: "pl",
          title: "Czy osoba odbierająca przelew musi również posiadać aplikację Orovera?",
          body: "Nie musi, do odebrania pieniędzy adresat potrzebuje jedynie karty płatniczej dodanej do portfela Apple Pay lub Google Pay w telefonie."
        },
        {
          id: 24,
          lang: "uk",
          title: "Чи повинна особа, яка отримує переказ, також мати додаток Orovera?",
          body: "Не обов'язково, для отримання грошей одержувачу потрібна лише платіжна картка, додана до гаманця Apple Pay або Google Pay на телефоні."
        },
      ],
    },
    {
      id: 9,
      orderIndex: 8,
      translations: [
        {
          id: 25,
          lang: "en",
          title: "How can I check if the transaction has been completed?",
          body: "After making the transaction, use the link that was sent or in the application where you can also find and download the confirmation."
        },
        {
          id: 26,
          lang: "pl",
          title: "W jaki sposób mogę sprawdzić czy transakcja została zrealizowana?",
          body: "Po dokonaniu transakcji w linku, który został wysłany lub w aplikacji gdzie również możesz znaleźć i pobrać potwierdzenie."
        },
        {
          id: 27,
          lang: "uk",
          title: "Як перевірити, чи завершилася транзакція?",
          body: "Після здійснення транзакції перейдіть за посиланням, яке було надіслано або в додатку, де також можна знайти та завантажити підтвердження."
        },
      ],
    },
    {
      id: 10,
      orderIndex: 9,
      translations: [
        {
          id: 28,
          lang: "en",
          title: "Can I transfer to people in other countries?",
          body: "Yes, you won't encounter any obstacles in this regard."
        },
        {
          id: 29,
          lang: "pl",
          title: "Czy mogę dokonywać transakcji z osobami w innych krajach?",
          body: "Tak, w tym zakresie nie napotkasz na żadne przeszkody."
        },
        {
          id: 30,
          lang: "uk",
          title: "Чи можу я переказати людям в інших країнах?",
          body: "Так, у вас не виникне жодних перешкод у цьому питанні."
        },
      ],
    },
    {
      id: 11,
      orderIndex: 10,
      translations: [
        {
          id: 31,
          lang: "en",
          title: "Are transactions with people in other countries subject to additional fees?",
          body: "No, transfers to other countries do not cost extra."
        },
        {
          id: 32,
          lang: "pl",
          title: "Czy transakcje z osobami w innych krajach są dodatkowo płatne?",
          body: "Nie, przelewy do innych krajów nie są dodatkowo płatne."
        },
        {
          id: 33,
          lang: "uk",
          title: "Чи стягується додаткова комісія за транзакції з резидентами інших країн?",
          body: "Ні, перекази в інші країни не оплачуються додатково."
        },
      ],
    },
    {
      id: 12,
      orderIndex: 11,
      translations: [
        {
          id: 34,
          lang: "en",
          title: "What are the fees for transactions made with Orovera?",
          body: "At the moment there are none, you can enjoy the possibilities that Orovera provides you as much as you want."
        },
        {
          id: 35,
          lang: "pl",
          title: "Jakie są opłaty za transakcje dokonywane za pomocą Orovery?",
          body: "Na ten moment nie ma żadnych, możesz do woli cieszyć się możliwościami jakie zapewnia Ci Orovera."
        },
        {
          id: 36,
          lang: "uk",
          title: "Яка комісія за транзакції з Orovera?",
          body: "На даний момент їх немає, ви можете насолоджуватися можливостями, які надає вам Orovera, стільки, скільки захочете."
        }
      ],
    },
  ];

  return { questions };
};

export default useQuestions;
