import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavPath } from "types/nav";

import classNames from "classnames";
import logo from "icons/logo.svg";
import logoUk from "icons/logo-uk.svg";
import menu from "images/en/navigation/menu.svg";
import cross from "images/en/navigation/cross.svg";
import keyboard from "images/en/navigation/keyboard.svg";
import invoice from "images/en/navigation/invoice.svg";
import chat from "images/en/navigation/chat.svg";
import globe from "images/en/navigation/globe.svg";
import email from "images/en/navigation/email.svg";
import collect from "images/en/navigation/collect.svg";
import NavSelect from "./navigation/NavSelect";
import NavLanguageSelect from "./navigation/NavLanguageSelect";
import useCurrLang from "hooks/useCurrLang";

const Nav = () => {
  const [activeMenu, setActiveMenu] = useState(false);

  const { t } = useTranslation();

  const currLang = useCurrLang();
  const navigate = useNavigate();

  const logoIcon = {
    en: logo,
    pl: logo,
    uk: logoUk,
  };

  return (
    <nav>
      <ul className="container">
        <div className="mobile-block">
          <li
            className={classNames("menu-button", { active: activeMenu })}
            onClick={() => {
              setActiveMenu(!activeMenu);
            }}
          >
            <div className="nav-item">
              <img src={activeMenu ? cross : menu} alt="menu" />
            </div>
          </li>
          <li className="brand">
            <Link to={NavPath.Home} className="nav-item">
              <img src={logoIcon[currLang]} alt="orovera brand image" />
            </Link>
          </li>
          {/*<li className="download-btn d-lg-none">*/}
          {/*  <Link to="https://jar.orovera.com" className="nav-item">*/}
          {/*    <img src={collect} alt="collect" style={{ width: "24px" }} />*/}
          {/*    <span>{t("home.nav.Moneybox")}</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </div>
        <div
          className={classNames("expand-block", {
            active: activeMenu,
          })}
        >
          <li className="select-item">
            <NavSelect
              title={t("home.nav.Features")}
              items={[
                // {
                //   name: t("home.nav.Moneybox"),
                //   img: collect,
                //   onClick: () => {
                //     window.open("https://jar.orovera.com");
                //   },
                // },
                {
                  name: t("home.nav.Financial keyboard"),
                  img: keyboard,
                  onClick: () => {
                    navigate(NavPath.Home + "#keyboard-section");
                  },
                },
                {
                  name: t("home.nav.Personal payments"),
                  img: invoice,
                  onClick: () => {
                    navigate(NavPath.Home + "#personal-payments-section");
                  },
                },
              ]}
            />
          </li>
          <li className="select-item d-none d-lg-block">
            <NavSelect
              title={t("home.nav.Company")}
              items={[
                {
                  name: t("home.nav.FAQ"),
                  img: chat,
                  onClick: () => {
                    navigate(NavPath.FAQ);
                  },
                },
                {
                  name: t("home.nav.Partners"),
                  img: globe,
                  onClick: () => {
                    navigate(NavPath.ForShareholders);
                  },
                },
                {
                  name: t("home.nav.Contact Us"),
                  img: email,
                  onClick: () => {
                    navigate(NavPath.ContactUs);
                  },
                },
              ]}
            />
          </li>
          <li className={classNames("select-item lang-select-item d-lg-block")}>
            <NavLanguageSelect />
          </li>
          {/*<li className="download-btn d-none d-lg-block">*/}
          {/*  <Link*/}
          {/*    to="https://jar.orovera.com"*/}
          {/*    target="_blank"*/}
          {/*    className="nav-item"*/}
          {/*    style={{*/}
          {/*      display: "flex",*/}
          {/*      alignItems: "center",*/}
          {/*      justifyContent: "center",*/}
          {/*      gap: "10px",*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <img src={collect} alt="collect" />*/}
          {/*    {t("home.nav.Moneybox")}*/}
          {/*  </Link>*/}
          {/*</li>*/}
        </div>
      </ul>
    </nav>
  );
};

export default Nav;
