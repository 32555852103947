import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <section id="terms-of-services">
      <TermsAndPrivacyTemplate
        title={t("terms-of-services.Title")}
        titleVersion={t("terms-of-services.titleVersion")}
        titleUpdated={t("terms-of-services.titleUpdated")}
        items={[
          {
            title: "",
            body: (
              <Trans i18nKey={"terms-of-services.sections.0.Body"}>
                <p><strong>PLEASE READ THESE TERMS OF USE CAREFULLY. BY CLICKING ON THE "ACCEPT" BUTTON DURING THE REGISTRATION IN THE APPLICATION YOU EXPRESS YOUR INFORMED CONSENT TO THE PROVISIONS OF TERMS OF USE AND PRIVACY POLICY AND THEY WILL BE BOUNDING FOR YOU. IF YOU DO NOT AGREE TO THESE TERMS OF USE OR PRIVACY POLICY, YOU CANNOT USE THE APP.</strong></p>
                <br></br>
                <p><strong>THESE TERMS OF USE REPRESENT A STANDARD DOCUMENT APPLYING TO ANY AND ALL USERS OF THE SERVICES, AND YOU ARE NOT ENTITLED TO CHANGE THEM OR ACT IN ANY WAY INCONSISTENT WITH THE PRESENT TERMS OF USE.</strong></p>
                <br></br>               
                <p><strong>NOTE: YOU MUST BE 18 YEARS OLD OR OVER TO ACCEPT THESE TERMS OF USE AND USE THE APPLICATION, SERVICES OR MEET ANOTHER AGE OBLIGATIONS STIPULATED IN THE APPLICABLE LAW EMPOWERING YOU TO ENTER INTO THE PRESENT CONTRACT. USERS UNDER 18 YEARS OLD MAY USE THE SERVICES ONLY WITH RESPECT TO PECULIARITIES ESTABLISHED BY THE APPLICABLE LAW.</strong></p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.1.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.1.Body"}>
                <p>The parties to these Terms of Use (hereinafter the “Terms”) are OROVERA S.A., a joint-stock company based in Lublin (20-103), ul.Rusałka 17a, registered in the National Court Register under the KRS number: 0001020988 (hereinafter also “Company”, “We”, “Us”) and you, users of our services available through the mobile application under the name Orovera owned by Us.</p><br></br>
                <p>These Terms of Use apply both to Users registering for individual usage and business use.</p><br></br>
                <p>The subject of these Terms shall be your access to services provided by Orovera (<strong>hereinafter also “Application” / “App”</strong>) software, along with the data supplied with the software, and any accompanying FAQ’s, our user guide and other documentation provided to you. Detailed explanation of Services is given later in the present Terms. Please be advised, that exact payment services such as processing payment transaction initiating payment transactions, processing them or receiving them as payee, other issuers related to payment processing are provided by the respectful third party, namely: Quicko, Fenige. </p><br></br>
                <p>Technical and other cooperation between the Payment Provider and the Company are governed by the separate agreement.</p><br></br>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.2.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.2.Body"}>
                <ol>
                  <li><strong>Individual user</strong> – user registering with Application for personal, non-commercial needs;</li>
                  <li><strong>Business user</strong> – one registering as sole proprietor, or on the behalf of the legal entity for business, commercial needs with our Application;</li>
                  <li><strong>User</strong> – any of Individual or Business User which is using the Application;</li>
                  <li><strong>Services</strong> – representing an information and telecommunication system provided by the Company designed to enable users of the Service to:
                    <ol>
                      <li>create Payment Instructions necessary for transferring funds to their own accounts, or Multiple Payment Instructions,</li>
                      <li>initiate, using electronic means of payment, transfers of funds to the accounts of the Payees based on Payment Instructions for the transfer of funds formed within the Service and,</li>
                      <li>receive technical services accompanying the provision of funds transfer services initiated using the Service.</li>
                    </ol>
                  </li>
                  <li><strong>Payment Instructions</strong> – meaning an order of the Payer to the Company to initiate the payment transaction by creating a link for the Payee enabling him to collect funds.</li>
                  <li><strong>Payment Transaction</strong> – successfully initiated via the Payment Instruction transfer of funds for the Payee to be received.</li>
                  <li><strong>Payment link</strong> – link to the website page created by the Payment Provider subject to the Payment Instruction of the Payer allowing the Payee to receive funds via the Payment Transaction.</li>
                  <li><strong>Payee</strong> – user receiving the funds basing on the Payment Instructions by following requirements and the Payment Link.</li>
                  <li><strong>Payer</strong> – user initiating the Payment Instructions and willing to send funds.</li>
                  <li><strong>Tokenized Payment Card</strong> – meaning the credit card emulated on the mobile device (with NFC) enabling the contactless payment.</li>
                  <li><strong>Sanctions List</strong> - Shall mean the Specially Designated Nationals and Blocked Persons ("SDN") List and the Non-SDN List, including the "Sectoral Sanctions Identifications List", published by OFAC; the Section 311 Special Measures for Jurisdictions, Financial Institutions, or International Transactions of Primary Money Laundering Concern published by FinCEN; and, any other foreign terrorist organization or other sanctioned, restricted, or debarred party list published by the FIA, or under Economic Sanctions, Anti-Money laundering, or Combating the Financing of Terrorism Laws of or by Governments of the United States, the United Nations, or any other jurisdiction or Government, as applicable to you or to the Services, as amended, supplemented, or substituted from time to time.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.3.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.3.Body"}>
                <ol>
                  <li>By registering in Application or using our Services in any way you represent, that following obligations of yours have been fulfilled or will be fulfilled (in case if the apply for the future relations and ongoing obligations):
                    <ol>
                      <li>You have read and agreed with the present Terms and the Privacy Policy in full;</li>
                      <li>You have all the rights to enter into the present Agreement;</li>
                      <li>You understand and accept, that Company is providing you solely technically related services and the Payment Provider is responsible for payment processing;</li>
                      <li>You are not included in the Sanctions Lists;</li>
                      <li>You are the rightful owner of the money, funds which You at any time transfer using the Services and you will use only credit/payment cards belonging solely to you and not to any other third party;</li>
                      <li>In case if you register as Individual User – you will use it (Application/Services) solely for your individual account, and if as Business User – vice versa;</li>
                      <li>You will not copy the Application or Services except where such copying is incidental to normal use;</li>
                      <li>When you initiate Payment Instructions via Application we assume, that they are initiated by You only and third parties must not use the Application on your behalf;</li>
                      <li>We do not verify the identity of the Payee and the Payment Link of yours shall be shared confidentially if required;</li>
                      <li>You will not rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the Application or Services, modify the App or Services, or permit them to be combined with, or become incorporated in, any other programs or services, not disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the App or other Services; and will comply with all technology control or export laws that apply to the technology used by the App or other Services. YOU WILL NOT USE ANY AND ALL TOOLS USED FOR THE AUTOMZATIZATION OF SERVCIES HEREUNDER.</li>
                      <li>You understand, that in case of any breach of the present Terms of Use, We may take action as We deem appropriate, including suspending or deleting your account, reporting the relevant authorities, terminating Services provision for you, etc.</li>
                      <li>You will use our Services solely for lawful purposes and will not attempt to affect anything within the Services with criminal intent or in a manner that adversely affects the reliability of the Services or the integrity of our Company or Payment Provider. Prohibited Activities, transactions, and usage of the Services, beside mentioned in other clauses specifically of these Terms, include actions related to (but not limited to):
                        <ol>
                          <li>Money laundering, terrorist financing, proliferation of weapons of mass destruction;</li>
                          <li>Human trafficking;</li>
                          <li>Any goods or services that are illegal or the promotion, offer or marketing of which is illegal or that are offered in connection with illegal, obscene or pornographic content, depict children or minors in sexual postures, depict means of propaganda or signs of unconstitutional organizations glorifying war or violating human dignity;</li>
                          <li>Any goods or services, promotion, offer or marketing of which would violate copyrights, industrial property rights or other rights of any person;.</li>
                        </ol>
                      </li>
                      <li>You shall not misuse the Application or the Services to cause any harm, damage, losses or interference for users, any third parties, Company, Payment Provider as well as the operation of the Application and/or provision of the Services. Under no circumstance You shall use the Application or Services to:</li>
                      <li>Publish, post, send, upload, submit, display or disseminate any information or material and/or otherwise make available or engage in any conduct that is unlawful, discriminatory, harassing, libelous, defamatory, abusive, threatening, harmful, offensive, obscene or otherwise objectionable;</li>
                      <li>Display, upload or transmit material that encourages conduct that may constitute a criminal offense, result in civil liability or otherwise violate or breach any applicable laws, regulations or code of practice;</li>
                      <li>Interfere or violate the legal rights (such as rights of privacy and publicity) of others or violate other violate usage conditions of the Application or the Services;</li>
                      <li>Violate any applicable laws, regulations or these Terms, Privacy Policy;</li>
                      <li>Violate, infringe or misappropriately use any Intellectual Property Right of any person (such as copyright, trademarks, patents, or trade secrets, or other proprietary rights of any party) or commit a tort;</li>
                      <li>Interfere with, disrupt, negatively affect or inhibit other Users from using the Application, or Services or links to the Application, or damage, disable, overburden or impair the functioning of the Application, or Services, or our servers or any networks connected to any of our servers in any manner;</li>
                      <li>Engage in or promote any activity that violates these Terms;</li>
                      <li>Mislead or deceive Us;</li>
                      <li>Disguise the origin of any information, send false materials or documents to be transmitted through the Application or the Services or to our designated KYC-provider;</li>
                      <li>Upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of the Application or Services or the operation of another’s computer or property;</li>
                      <li>Send, upload, display or disseminate or otherwise make available information or material containing or associated with spam, junk mail, advertising for pyramid schemes, chain letters, virus warnings (without first confirming the authenticity of the warning), or any other form of unauthorized advertising or promotional information or material;</li>
                      <li>Make available any content which is false, misleading and/or promoting illegal activities;</li>
                      <li>Access any content, area or functionality of the Service, Application that you are prohibited or restricted from accessing or attempt to bypass or circumvent measures employed to prevent or limit Your access to any content, area or functionality of Application or Service;</li>
                      <li>Attempt to gain unauthorized access to any Services, Application, computer systems, or networks connected to any of our servers through hacking, password mining, or any other means;</li>
                      <li>Harvest or otherwise collect, whether aggregated or otherwise, data about others including e-mail addresses and/or distribute or sell such data in any manner; or</li>
                      <li>Collect and store personal data, private and personally identifiable information without express consent and authorization of the holder.</li>
                      <li>In case if required in accordance with the Applicable Law, you will follow requests necessary to adhere to applicable anti-money laundering (including, but not limited to Act of 1 March 2018 on counteracting money laundering and terrorist financing or other regulations if any).</li>
                    </ol>
                  </li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.4.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.4.Body"}>
                <ol>
                  <li>The App is available to access and download, free of charge, using the link on our website onto your device, from either “Google Play” or Apple's “App Store” marketplaces (the "App Marketplaces”). Downloading is free of charge, but other fees may apply from time to time for certain Services. More information is available in the Section 5 of the present Terms.</li>
                  <li>In order to use the Services, you must pass the registration procedure prescribed in the Application. Thus, user must:
                    <ol>
                      <li>Add contact cellphone number;</li>
                      <li>Press the button “receive the code” which will automatically send the sms-confirmation code to the previously provided phone number;</li>
                      <li>Insert the received code and press “next”;</li>
                      <li>Then the Application requires you to set the PIN-code which shall be used to enter to the Application. In order to confirm the Payment Instructions before sending you need to use your biometrics depending on the version of Application, mobile device. The Payment Instructions will not be send in case if the biometric check is not completed.</li>
                    </ol>
                  </li>
                  <li>After passing the registration in accordance with clause 3.2., User will be able to initiate Payment Instructions subject to certain threshold limitations (currently equaling to PLN 500). In order to increase this limit, please refer to clause 4.3.</li>
                  <li>Business Users may be asked to provide additional information (including, but not limited to IBAN-account number or other) and they may be subject to additional requirements and able to use additional Services;</li>
                  <li>In order to create a Payment Instruction, you need to add Tokenized Card of your bank/EMI, etc and follow the on-screen instructions.</li>
                  <li>Payees can receive the funds sent to them via the Payment Instructions without passing the registration procedure using the received Payment Link and adding their Tokenize Payment Card or just adding credit/payment card number.</li>
                  <li>We do not store/process any credit card information of the User, as payment processing services are supplied by the Payment Provider.</li>
                  <li>Application has certain minimum technical requirements such as version of the operative system, and others accessible on the pages in App Store and Google Play.</li>
                  <li>In case you would like to delete your account – you can contact us.</li>
                  <li>We reserve the right to request the provision of additional documents required for identification, KYC or other purposes if needed. Services can be temporarily suspended until the requested documentation is provided and checked accordingly.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.5.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.5.Body"}>
                <ol>
                  <li>Subject to successful registration, via Application you can:
                    <ol>
                      <li>Initiate Payment Instructions within the limits applied;
                        <ol>
                          <li>Payment Instructions can be created by the Payers.</li>
                          <li>Please be advised, that in accordance with the Applicable Law, or as may be requested by the Payment Provider, or in our sole discretion, We reserve the right to set limits to daily amount of funds which can be sent by the Payment Instructions, transactions amounts, quantity of Payment Instructions or others even to Users, which have passed the KYC as is set in the clause 4.3.</li>
                          <li>To initiate Payment Instruction the Payer must use the virtual terminal (also maybe referred as to the “Orovera Keyboard”) and follow on-screen instructions (which obligatory includes using of Tokenized Payment Card). Procedure for sending funds to Business Users may vary from the one applicable to Individual Users;</li>
                          <li>Initiation of the Payment Instruction consists of two steps:
                            <ol>
                              <li>op-up of the User’s balance (provided by the Payment Provider) with a blocking for the amount of the initiated Payment Instruction and upon that;</li>
                              <li>Actual sending of the funds to the Payee using the services of Payment Provider.</li>
                              <li>In case Payer wants to cancel the Payment Instruction, the procedure can be described as the process of funds withdrawal from the User’s balance to the receiving party (which is the User initiating the Payment Instruction).</li>
                            </ol>
                          </li>
                          <li>Sending of the Payment Instructions by the Payer constitutes giving his/her expressed consent to the Payment Provider to facilitate the transfer of funds with respect to the provisions and requirements of the Payment Instruction; Receiving the funds constitutes the same consent to Payment Provider to facilitate the transfer of funds.</li>
                          <li>Payment Instructions and links for them (unless they are Multiple enabling them to be used several times subject to certain conditions) expire upon funds receiving by the Payee;</li>
                          <li>Upon successful receiving of the Payment Instruction, the Payment Provider sends the Payer receipt indicating the transaction amount and some other information as is prescribed in the Applicable Law;</li>
                          <li>Once the Payment Instruction is successfully initiated, you will receive a link that needs to be sent to the Payee.</li>
                        </ol>
                      </li>
                      <li>Cancel Payment Instructions until the funds are not transferred to the Payee;</li>
                      <li>Initiate Multiple Payment Instructions (Payment Instructions which expire after occurrence of specific events such as receiving certain transaction amount of certain quantity of transactions, etc). User can adjust Multiple Payment Instructions at his own discretion;
                        <ol>
                          <li>For example, user can generate Payment Instructions for receiving the funds transfer (Funds Request), instead of sending them. In that case, User is able to select amount of funds to be received by him, number of transactions and etc. The Payment Link for such Payment Instructions will expire only upon occurrence of the events prescribed by the user initiating the Multiple Payment Instruction. The operation is similar to the one described in clause 4.2 subject to additional conditions, which can be established. Users also can create the Personal Payment Page which constitutes a page allowing Users to create Multiple Payment Instructions for desired sum (subject to applicable limitations, if any).</li>
                          <li>Please pay attention, the cancelation of the Initiated Multiple Payment Instruction for the Funds Request is impossible. The only actual way is using the Funds Request functionally and actual withdrawal of the funds to the Tokenized Payment Card.</li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>To receive funds (also can be called “Funds Request”), the Payee needs to follow the Payment Instructions link, enter his credit card number/or choose the Tokenized Payment Card and follow other on-screen instructions. This information is obtained and processed by the Payee and latter has to agree also with the Terms and Conditions of Payment Provider applicable to him/her. Operation consists of two steps:
                    <ol>
                      <li>Firstly, the User’s balance shall be replenished;</li>
                      <li>And after the funds shall instantly be transferred from the User’s balance to the Tokenized Payment Card via the services of Payment Provider. <br/>This operation can be canceled by deactivating the Funds Request. <br/>It shall be noted, that once the Funds Request is completed and the funds are withdrawn to the Tokenized Payment Card there are no refund/reversal mechanisms available. Please make sure that the information on the Payment Instruction is correct prior to making any transactions.</li>
                    </ol>
                  </li>
                  <li>In order to increase the Payment Instructions transactions limits, uses are required to pass the KYC (Know Your Customer) procedure. Information on how We handle and process the Personal Data can be found in the respective Privacy Policy. The KYC may be conducted by designated, certified KYC-provider. We do not store cardholder data from our side and this information is transmitted straight to the compliant Payment Provider. More information on how we handle the personal data processing can be found in the respective Privacy Policy available within the Application.  The Payment Instructions limits can be changed/adjusted by the Company in it’s own discretion.</li>
                  <li>Functionality available for the Business Users and Individual one may vary. You can always check the available features in the Application.</li>
                  <li>In order to use Services Users will be opened with account (-s) in the payment system Quicko. By using our Services you express your explicit consent with opening such accounts and respective terms of use of the latter available under the following link https://sportigio.s3.eu-west-2.amazonaws.com/spaces/114/uploads/5rhR1wMFBlgyHTRtNfLIHlZlPNs7eJ4zkJBGa3z4.pdf. Please restrain from using our Services in case if you don’t agree with the aforementioned.</li>
                  <li><strong>NOT A BANKING OR PAYMENT INSITUTION. THE APPLICATION DOES NOT HAVE ACCESS TO THE FUNDS HELD ON USERS BANKING ACCOUNTS OR PAYMENT CARDS (INCLUDING TOKENIZED PAYMENT CARDS). OUR SOFTWARE SIMPLY INTERACTS IN CONVINIENT FOR THE USER WAY WITH THE PAYMENT PROVIDER, AND SENDS COMMUNICATIONS TO THE LATTER, SO THAT THE PAYMENT PROCESSING INSTRUCTIONS BETWEEN THE PAYER, THE BANKS [OR OTHER PAYMENT CARD ISSUERS], THE PAYEE AND PAYMENT SERVICES PROVIDER, ARE REFLECTED IN THE APPLICATION.</strong></li>
                  <li>You also may use any documentation which We provide to you, to support your permitted use of the Application and the Services, provided you comply with the license restrictions set out below, make a reasonable number of copies of the content and data within the Application for back-up purposes; and receive and use any free supplementary software code or update of the Application incorporating "patches" and corrections of errors as we may provide to you.</li>
                  <li>Basing on different circumstances (such as rules established in the Applicable Law, requirements of the Payment Provider or in any other case we deem fit) we may suspend provision of some Services to you or provide them in a restricted fashion.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.6.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.6.Body"}>
                <ol>
                  <li>Fees vary depending on the type of the account for which you have registered, type of Services used, type of payment systems used, geolocation and other reasons. We may conduct different marketing events which may affect the fees applied;</li>
                  <li>Downloading of the Application is free of any charge;</li>
                  <li>Individual User:
                    <ol>
                      <li>From every processed Payment Transaction, the Payment Provider charge a small fee. The exact amount of the fee to be charged is available in the Payment Provider’s Terms of Use which must be paid by the Payer and will be visible in the Application prior to confirming the Payment Instruction;</li>
                    </ol>
                  </li>
                  <li>Business User:
                    <ol>
                      <li>As is in the example with Individual Users, some fee designated by the Payment Provider will be charged from every Payment Instruction, which as in case with the Individual Account can be settled only by the Payer. It also is visible in the Application prior to confirming the Payment Instruction;</li>
                      <li>All fees are non-refundable regardless of whether you are a Business User or Individual User.</li>
                    </ol>
                  </li>
                  <li>We reserve the right to change the Fees or charge new anytime at our own discretion, but you will be promptly notified before such changes take place as well as apply personalized tariffs for any Users as we deem necessary. </li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.7.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.7.Body"}>
                <ol>
                  <li>If the information requested for authorization/registration/ KYC and using the Application is correct, We have the right to assume that it is you the one who take actions on your account. In the event that You suspect that your Application is illegally used by a third party, You must inform Us about it as soon as possible by contacting support team from your registered phone or email (if was provided). You can also contact the support department via chat in the Application (if any). In the event that your registered email address has been hacked, let Us know about it, but in this case, We may require additional information or documents to verify your identity. You are responsible for any actions taken from your account, including third party access.</li>
                  <li>Your account is not transferable. Under no circumstances shall You allow or permit any other person or third party, including, without limitation, any person under the legal age requirements, or included in the Sanctions list to use the Application or transfer these Terms or use your account.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.8.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.8.Body"}>
                <ol>
                  <li>Company retains all rights, title and interest in and to the Application and the Services, whether express or implied, including all copyrights, patents, trade secrets, trademarks, other intellectual property rights, trade names, logos, slogans, custom graphics, button icons, scripts, videos, text, images, software, code, files, content, information and other material available in the Application and other Intellectual Property Rights as prescribed in the ‘Section’ Definitions of these Terms.</li>
                  <li>Accessing the App and using the Services does not vest You with any right, title or interest in the Intellectual Property and other rights to content which is accessible in the Application unless otherwise is provided in this Agreement.</li>
                  <li>In order to use the Application and / or any other Service, You are granted personal, non-exclusive, limited, non-assignable, non-transferrable, royalty free, revocable license to use the Application subject to all limitations contained herein.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.9.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.9.Body"}>
                <ol>
                  <li>We reserve the right to unilaterally change the present Terms anytime at our sole discretion. In the event of significant updating these Terms, We will ask you to re-consent to the updated Terms before any changes take effect. In the event that significant changes or any other are unacceptable to you, We ask You to stop using Our Services immediately. We will notify You about minor changes by sending notification in the Application. All changes will indicate the date from which they will take effect.</li>
                  <li>You can always find the latest version of the Terms in the Application. If you use our Services after the changes come into effect, We deem it as your consent to the changes.</li>
                  <li>From time to time, we may automatically update the Application and change the Services to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively, We may ask you to update the Application for these reasons.</li>
                  <li>If you choose not to install such updates or if you opt out of automatic updates, you may not be able to continue using the Application and the Services.</li>
                  <li>We will use reasonable endeavors to ensure that the Application will work with the current or previous version of the operating system (as it may be updated from time to time).</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.10.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.10.Body"}>
                <ol>
                  <li><strong>Disclaimer of Warranties.</strong> To the maximum extent permitted under the Applicable Law, users expressly acknowledge and agree that use of the Application, Services are at their sole risk. Users further acknowledge and agree that the Services, Application are provided on an “AS IS” and “AS AVAILABLE” basis. Neither Company nor any of its partners, subsidiaries, affiliates, licensees, licensors, contractors, agents, content providers, vendors, component suppliers (both hardware and software), and/or any third party who provides products or services purchased from or distributed by Company as well their respective officers, directors, members, managers, representatives, agents, employees, investors or the like (collectively “Orovera Providers”), warrant Services, Application offered by Us, affiliates or third-parties services providers will be uninterrupted, error-free, or free of viruses, worms, Trojan horses, keyboard loggers, spyware, adware, malware, harmful or malicious code, or free of other defects.</li>
                  <li><strong>We are responsible to you for foreseeable loss and damage caused by us for breach of obligations hereunder.</strong> If We fail to comply with obligations arising from these Terms of Use, We are responsible for loss or damage you suffer that is a foreseeable result of our breaking these Terms of Use or our failing to use reasonable care and skill, but We are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these Terms of Use, both We and you knew it might happen. We bear no responsibility for improper fulfillment of obligations hereunder in case if it is such inability caused by third-party events of actions (such as technical failure of Payment Provider, etc).</li>
                  <li>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN AND REGARDLESS OF THE FORM OF THE ACTION, COMPANY’S LIABILITY TO USERS FOR ANY CAUSE WHATSOEVER WILL AT ALL TIMES BE LIMITED TO THE AMOUNT OF PAYMENT INSTRUCTIONS MADE, IF ANY, BY SUCH USER TO COMPANY FOR USE OF THE SERVICES DURING THE TERM OF THEIR REGISTRATION.</li>
                  <li>IN THE EVENT THAT A USER RESIDES IN A JURISDICTION THAT DOES NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SOME OF THE LIMITATIONS ABOVE MAY NOT APPLY TO SUCH USER OR APPLY IN RESTRICTED MANNER.</li>
                  <li>We are not liable to the user for violations or damages arising from:
                    <ol>
                      <li>the user's own mistake;</li>
                      <li>Usage of the Application in breach of the present Terms of Use, or Applicable Law;</li>
                      <li>for all issues arising between the Payer and the Payee (including, but not limited to fraud made by Payer or Payee, for quality of the goods shipped where the payment is made using the Services, etc);</li>
                      <li>for temporary delays making impossible creation/submission the Payment Instructions;</li>
                      <li>actions of a third party that is not related to fulfillment of our obligations under these Terms (for example, failure due to improper operation of the communication system, malfunctions of user’s devices, its overload and problems connecting to it or due to improper operation of the user's computer equipment, VISA/Master Card processing issues, if the balance of Tokenized Payment Card is insufficient, etc);</li>
                      <li>other events that could not have been foreseen or prevented by Us or our Orovera Providers, even if We or our contractors took all the reasonable precautions.</li>
                      <li>In case with Individual Users, We are not liable for any business losses that Individual Users suffer and vice versa.</li>
                    </ol>
                  </li>
                  <li>We recommend to:
                    <ol>
                      <li>Check compatibility of our Service with your device/equipment before use;</li>
                      <li>Take reasonable precautions against malware or devices, including installation of antivirus software.</li>
                    </ol>
                  </li>
                  <li>We are not responsible for any problems or delays in operation of our Services that arise as a result of force majeure, because force majeure is not subject to our influence, despite the steps We take to prevent it. The events that shall be deemed as a force majeure under these Terms are the following: natural disasters, lack of power supply due to its sudden disconnection, failure in the provision of telecommunications service, action, termination or inaction of any government or authority, any other delays or failures by our contractors or other third parties. We are not responsible for any loss or damage incurred in connection with such events. We reserve the right to suspend provision of Services until elimination of problems connected with operation of our Services caused by force majeure, with no liability incurred.</li>
                  <li>User undertakes to indemnify and hold Company harmless from and against any penalties imposed by scheme owners, losses, liabilities, damages and expenses resulting from any breach of the of the present Terms, Applicable Laws(-s), warranties or representations, covenants, or any misrepresentation by User, or arising out of the user’s negligence, misconduct or omission. The User shall be held fully responsible for the operation made with usage of Services.</li>
                  <li>Should any fines being imposed on the Company as a result of Users breach of the present Terms, User must not only reimburse such fines as well as legal fees associated with them, but also to pay an additional fee in the amount of EUR 250 no VAT equivalent for each case to the Company as soon as possible, but not later, then within 5 calendar days upon submission of the request. </li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.11.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.11.Body"}>
                <ol>
                  <li>We may end your rights to use the Application and Services, or suspend your account, at any time by contacting you if We consider that you have broken these Terms of Use. If what you have done can be put right, we will give you a reasonable opportunity to do so. We also have the right to terminate these Terms of Use and your Application account if the agreement between us and Payment Provider or you and Payment provider is terminated/canceled. Moreover, the Agreement with you may be terminated (or Services may be temporarily suspended in the following cases as well): in case of if any suspicious activity is discovered, violation of clause 3.10, if the respective request/signal has been submitted by the Payment Provider of by other our counterparties or at own discretion how we deem fit.</li>
                  <li>In case if decide to terminate your rights to use the App and Services:
                    <ol>
                      <li>you must stop all activities authorized by these Terms of Use, including your use of the Application and any Services;</li>
                      <li>you must delete or remove the Application from all devices in your possession and immediately destroy all copies of the Application which you have and confirm to us that you have done this; and</li>
                    </ol>
                  </li>
                  <li>If you close your account or it is terminated by us, we will take steps to return funds which are already sent as Payment Transactions but are not received by the Payee (if it is possible).</li>
                  <li>In case if you would like to terminate the Services, you can either delete the Application, Orovera Keyboard and require us to delete your Personal Data, or you can just submit the respective request at info@orovera.com. Once your data (where possible) is deleted we will send you a respective notice.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.12.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.12.Body"}>
                <ol>
                  <li>LIMITED ASSIGNMENT. We reserve the rights to assign this agreement in part or in full to third parties. You may only transfer your rights or your obligations under these Terms of Use to another person if we agree in writing to such transfer in prior.</li>
                  <li>If any of these Terms determined to be invalid, unlawful or unenforceable to any extent, such term shall be excluded from these Terms, and the remaining terms shall continue to be valid to the fullest extent permitted by law.</li>
                  <li>All questions concerning the construction, validity, enforcement and interpretation of these Terms shall be governed by and construed and enforced in accordance with the laws of Poland (Applicable Law).</li>
                  <li>If the dispute failed to be solved by negotiations, then the dispute shall be submitted for arbitration to the respective court of Poland.</li>
                  <li>The Services may contain links to websites and third-party content, advertisements, promotions, logos and other materials ("Third-Party Content"). We make no representations or warranties of any kind regarding Third-Party Content, including, without limitation, the accuracy, validity, legality, copyright compliance, or decency of such Third-Party Content.</li>
                  <li>In case if due to Applicable Law we are required to include taxes to the Fees applied, you agree to cover them expressly.</li>
                  <li>In case if you have any complaints regarding the quality of Services or related to other issues, you can contact us by sending email to the address info@orovera.com or via chat in the Application (if any). We will do our best to resolve your issue if possible during 30 calendar days since the receipt of the complaint, but the first answer will be provided during 72 hours.</li>
                  <li>We reserve the right to limit / or restrict your ability to participate in marketing activities at any time.</li>
                </ol>
              </Trans>
            ),
          },
        ]}
      />
    </section>
  );
};

export default TermsOfService;
