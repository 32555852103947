import { ThreeDots } from "react-loader-spinner";

interface SpinnerProps {
  color?: string;
}

const Spinner = ({ color = "#fff" }: SpinnerProps) => {
  return (
    <ThreeDots
      width="40"
      radius="9"
      color={color}
      ariaLabel="three-dots-loading"
      visible={true}
    />
  );
};

export default Spinner;
