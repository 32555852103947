import { useState } from "react";
import NavArrowUp from "../icons/NavArrowUp";
import NavArrowDown from "../icons/NavArrowDown";
import classNames from "classnames";

type Item = {
  name: string;
  img: string;
  itemClassName?: string;
  onClick?: () => void;
};

interface NavSelectProps {
  title: string;
  items: Item[];
}

const NavSelect = ({ title, items }: NavSelectProps) => {
  const [active, setActive] = useState(false);

  const renderItems = () => {
    return items.map((item) => (
      <li
        key={item.name}
        className={classNames(item.itemClassName)}
        onClick={item.onClick}
      >
        <a>
          <img src={item.img} alt="keyboard" className="icon-image" />
          {item.name}
        </a>
      </li>
    ));
  };

  return (
    <div
      className={classNames("nav-item nav-select", {
        active,
      })}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      <div className="title">
        <p>{title}</p>
        {active ? <NavArrowUp /> : <NavArrowDown />}
      </div>
      <div className="body">
        <ul>{renderItems()}</ul>
      </div>
    </div>
  );
};

export default NavSelect;
