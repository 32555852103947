import whatsappEn from "images/en/home/phone-slider/phones/whatsapp.png";
import facebookEn from "images/en/home/phone-slider/phones/facebook.png";
import instagramEn from "images/en/home/phone-slider/phones/instagram.png";
import telegramEn from "images/en/home/phone-slider/phones/telegram.png";
import slackEn from "images/en/home/phone-slider/phones/slack.png";

import whatsappPl from "images/pl/home/phone-slider/phones/whatsapp.png";
import facebookPl from "images/pl/home/phone-slider/phones/facebook.png";
import instagramPl from "images/pl/home/phone-slider/phones/instagram.png";
import telegramPl from "images/pl/home/phone-slider/phones/telegram.png";
import slackPl from "images/pl/home/phone-slider/phones/slack.png";

import whatsappUk from "images/uk/home/phone-slider/phones/whatsapp.png";
import facebookUk from "images/uk/home/phone-slider/phones/facebook.png";
import instagramUk from "images/uk/home/phone-slider/phones/instagram.png";
import telegramUk from "images/uk/home/phone-slider/phones/telegram.png";
import slackUk from "images/uk/home/phone-slider/phones/slack.png";

import whatsappBlur from "images/en/home/phone-slider/blurs/whatsapp.jpg";
import facebookBlur from "images/en/home/phone-slider/blurs/facebook.jpg";
import instagramBlur from "images/en/home/phone-slider/blurs/instagram.jpg";
import telegramBlur from "images/en/home/phone-slider/blurs/telegram.jpg";
import slackBlur from "images/en/home/phone-slider/blurs/slack.jpg";

import whatsappBlurMobile from "images/en/home/phone-slider/mobile-blurs/whatsapp.jpg";
import facebookBlurMobile from "images/en/home/phone-slider/mobile-blurs/facebook.jpg";
import instagramBlurMobile from "images/en/home/phone-slider/mobile-blurs/instagram.jpg";
import telegramBlurMobile from "images/en/home/phone-slider/mobile-blurs/telegram.jpg";
import slackBlurMobile from "images/en/home/phone-slider/mobile-blurs/slack.jpg";

import firstBottomSlideEn from "images/en/home/phone-slider/bottom-slider/1.png";
import secondBottomSlideEn from "images/en/home/phone-slider/bottom-slider/2.png";
import thirdBottomSlideEn from "images/en/home/phone-slider/bottom-slider/3.png";
import fourthBottomSlideEn from "images/en/home/phone-slider/bottom-slider/4.png";
import fifthBottomSlideEn from "images/en/home/phone-slider/bottom-slider/5.png";

import firstBottomSlidePl from "images/pl/home/phone-slider/bottom-slider/1.png";
import secondBottomSlidePl from "images/pl/home/phone-slider/bottom-slider/2.png";
import thirdBottomSlidePl from "images/pl/home/phone-slider/bottom-slider/3.png";
import fourthBottomSlidePl from "images/pl/home/phone-slider/bottom-slider/4.png";
import fifthBottomSlidePl from "images/pl/home/phone-slider/bottom-slider/5.png";

import firstBottomSlideUk from "images/uk/home/phone-slider/bottom-slider/1.png";
import secondBottomSlideUk from "images/uk/home/phone-slider/bottom-slider/2.png";
import thirdBottomSlideUk from "images/uk/home/phone-slider/bottom-slider/3.png";
import fourthBottomSlideUk from "images/uk/home/phone-slider/bottom-slider/4.png";
import fifthBottomSlideUk from "images/uk/home/phone-slider/bottom-slider/5.png";

const useKeyboardSlides = () => {
  const slides = [
    {
      name: "whatsapp",
      blur: whatsappBlur,
      blurMobile: whatsappBlurMobile,
      image: {
        en: whatsappEn,
        pl: whatsappPl,
        uk: whatsappUk,
      },
      imageBottom: {
        en: firstBottomSlideEn,
        pl: firstBottomSlidePl,
        uk: firstBottomSlideUk,
      },
    },
    {
      name: "facebook",
      blur: facebookBlur,
      blurMobile: facebookBlurMobile,
      image: {
        en: facebookEn,
        pl: facebookPl,
        uk: facebookUk,
      },
      imageBottom: {
        en: secondBottomSlideEn,
        pl: secondBottomSlidePl,
        uk: secondBottomSlideUk,
      },
    },
    {
      name: "instagram",
      blur: instagramBlur,
      blurMobile: instagramBlurMobile,
      image: {
        en: instagramEn,
        pl: instagramPl,
        uk: instagramUk,
      },
      imageBottom: {
        en: thirdBottomSlideEn,
        pl: thirdBottomSlidePl,
        uk: thirdBottomSlideUk,
      },
    },
    {
      name: "telegram",
      blur: telegramBlur,
      blurMobile: telegramBlurMobile,
      image: {
        en: telegramEn,
        pl: telegramPl,
        uk: telegramUk,
      },
      imageBottom: {
        en: fourthBottomSlideEn,
        pl: fourthBottomSlidePl,
        uk: fourthBottomSlideUk,
      },
    },
    {
      name: "slack",
      blur: slackBlur,
      blurMobile: slackBlurMobile,
      image: {
        en: slackEn,
        pl: slackPl,
        uk: slackUk,
      },
      imageBottom: {
        en: fifthBottomSlideEn,
        pl: fifthBottomSlidePl,
        uk: fifthBottomSlideUk,
      },
    },
  ];

  return slides;
};

export default useKeyboardSlides;
