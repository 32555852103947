import { Routes, Route } from "react-router-dom";
import { NavPath } from "types/nav";
import Layout from "./components/Layout";
import Home from "pages/Home";
import TermsOfService from "pages/TermsOfService";
import FAQ from "pages/FAQ";
import PrivacyPolicy from "pages/PrivacyPolicy";
import Rates from "pages/Rates";
import ForShareholders from "pages/ForShareholders";
import NewsShow from "pages/NewsShow";
import NewsPublicBetaTesting from "pages/NewsPublicBetaTesting";
import ContactUs from "pages/ContactUs";
import useScrollToTop from "hooks/useScrollToTop";
import TermsOfTransactionServicesEN from "pages/TermsOfTransactionServicesEN";
import TermsOfTransactionServicesPL from "pages/TermsOfTransactionServicesPL";
import TermsOfAcquiringServicesEN from "pages/TermsOfAcquiringServicesEN";
import TermsOfAcquiringServicesPL from "pages/TermsOfAcquiringServicesPL";
import TermsEN from "pages/TermsEN";
import TermsPL from "pages/TermsPL";
import PrivacyPolicyEN from "pages/PrivacyPolicyEN";
import PrivacyPolicyPL from "pages/PrivacyPolicyPL";

const App = () => {
  useScrollToTop();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={NavPath.FAQ} element={<FAQ />} />
        <Route path={NavPath.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route path={NavPath.TermsOfService} element={<TermsOfService />} />
        <Route path={NavPath.Rates} element={<Rates />} />
        <Route path={NavPath.ForShareholders} element={<ForShareholders />} />
        <Route path={NavPath.NewsShow} element={<NewsShow />} />
        <Route path={NavPath.NewsPublicBetaTesting} element={<NewsPublicBetaTesting />} />
        <Route path={NavPath.ContactUs} element={<ContactUs />} />

        <Route path={NavPath.TermsOfTransactionServicesEN} element={<TermsOfTransactionServicesEN />} />
        <Route path={NavPath.TermsOfTransactionServicesPL} element={<TermsOfTransactionServicesPL />} />
        <Route path={NavPath.TermsOfAcquiringServicesEN} element={<TermsOfAcquiringServicesEN />} />
        <Route path={NavPath.TermsOfAcquiringServicesPL} element={<TermsOfAcquiringServicesPL />} />
        
        <Route path={NavPath.TermsEN} element={<TermsEN />} />
        <Route path={NavPath.TermsPL} element={<TermsPL />} />
        <Route path={NavPath.PrivacyPolicyEN} element={<PrivacyPolicyEN />} />
        <Route path={NavPath.PrivacyPolicyPL} element={<PrivacyPolicyPL />} />
      </Route>
    </Routes>
  );
};

export default App;
