import { CSSProperties, ReactNode } from "react";

interface ButtonProps {
  className?: string;
  style?: CSSProperties;
  href: string;
  children: ReactNode;
  target?: any;
  onClick: () => void;
}

const Button = ({
  className,
  style,
  href,
  children,
  target,
  onClick,
}: ButtonProps) => {
  return (
    <a
      href={href}
      onClick={onClick}
      style={style}
      className={className}
      target={target}
    >
      {children}
    </a>
  );
};

export default Button;
