import SectionHeader from "components/SectionHeader";
import { Trans, useTranslation } from "react-i18next";
import trustedIcon from "images/en/home/security/icon-1.png";
import authIcon from "images/en/home/security/icon-2.png";
import fraudIcon from "images/en/home/security/icon-3.png";

const Security = () => {
  const { t } = useTranslation();

  return (
    <section className="security">
      <div className="container">
        <SectionHeader
          title={<Trans i18nKey="home.security.title" />}
          body={t("home.security.subtitle")}
        />
        <div className="security-cards">
          <div className="security-card-wrapper">
            <img src={trustedIcon} alt="trusted" />
            <h4>{t("home.security.firstTitle")}</h4>
            <p>{t("home.security.firstBody")}</p>
          </div>
          <div className="security-card-wrapper">
            <img src={authIcon} alt="trusted" />
            <h4>{t("home.security.secondTitle")}</h4>
            <p>{t("home.security.secondBody")}</p>
          </div>
          <div className="security-card-wrapper">
            <img src={fraudIcon} alt="trusted" />
            <h4>{t("home.security.thirdTitle")}</h4>
            <p>{t("home.security.thirdBody")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Security;
