import "styles/phone-input.scss";
import germanyFlag from "images/en/flags/germany-flag.png";
import ukraineFlag from "images/en/flags/ukraine-flag.svg";
import bulgariaFlag from "images/en/flags/bulgaria-flag.png";
import polandFlag from "images/en/flags/poland-flag.svg";
import classNames from "classnames";
import { FormEvent, useState } from "react";

import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

type Item = {
  number: string;
  title: string;
  img: string;
};

interface PhoneInputProps {
  containerClassName?: string;
  hasError?: boolean;
  loading: boolean;
  onChange: (phone: string) => any;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

const PhoneInput = ({
  containerClassName,
  hasError,
  loading,
  onChange,
  onSubmit,
}: PhoneInputProps) => {
  const { t } = useTranslation();

  const items: Item[] = [
    { number: "+380", title: t("contactUs.Ukraine"), img: ukraineFlag },
    { number: "+49", title: t("contactUs.Germany"), img: germanyFlag },
    { number: "+35", title: t("contactUs.Bulgaria"), img: bulgariaFlag },
    { number: "+48", title: t("contactUs.Poland"), img: polandFlag },
  ];

  const [active, setActive] = useState(false);
  const [country, setCountry] = useState(items[0]);
  const [number, setNumber] = useState("");
  const [focused, setFocused] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    await onSubmit(event);
    setActive(false);
    setFocused(false);
    setNumber("");
  };

  const handleNumberChange = (newNumber: string) => {
    setNumber(newNumber);
    onChange(country.number + newNumber);
  };

  const handlePhoneCodeChange = (item: Item) => {
    setActive(false);
    setCountry(item);
    onChange(item.number + number);
  };

  const renderItems = () => {
    return items.map((item) => {
      return (
        <li
          key={item.title}
          onClick={() => handlePhoneCodeChange(item)}
          className={classNames({
            active: item.number === country.number,
          })}
        >
          <img src={item.img} alt="keyboard" />
          {item.number + " " + item.title}
        </li>
      );
    });
  };

  return (
    <div className={classNames("phone-row-wrapper", containerClassName)}>
      <div
        className={classNames("phone-input-wrapper", {
          active,
          error: hasError,
          focused,
        })}
      >
        <div className="main">
          <div className="code-wrapper" onClick={() => setActive(!active)}>
            <div className="code">
              <img src={country.img} alt={country.number} />
              <p>{country.number}</p>
            </div>
          </div>
          <form onSubmit={handleSubmit} id="phone-form">
            <input
              value={number}
              type="tel"
              placeholder={t("general.Number")}
              onChange={(e) => handleNumberChange(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              required
            />
            <button className="button d-none d-lg-flex" type="submit">
              {loading ? <Spinner /> : t("general.Request a call")}
            </button>
          </form>
        </div>
        <div className="body">
          <ul>{renderItems()}</ul>
        </div>
      </div>
      {hasError && (
        <p className="error">{t("general.Invalid phone number format")}</p>
      )}
      <button className="button d-lg-none" type="submit" form="phone-form">
        {loading ? <Spinner /> : t("general.Request a call")}
      </button>
    </div>
  );
};

export default PhoneInput;
