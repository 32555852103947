import { Trans, useTranslation } from "react-i18next";

import Card from "components/Card";
import SectionHeader from "components/SectionHeader";
import UndelineLinkSection from "components/links/UnderlineLinkSection";

import firstBgEn from "images/en/home/personal-payments/bg-1.png";
import secondBgEn from "images/en/home/personal-payments/bg-2.png";
import thirdBgEn from "images/en/home/personal-payments/bg-3.png";
import fourthBgEn from "images/en/home/personal-payments/bg-4.png";

import firstBgPl from "images/pl/home/personal-payments/bg-1.png";
import secondBgPl from "images/pl/home/personal-payments/bg-2.png";
import thirdBgPl from "images/pl/home/personal-payments/bg-3.png";
import fourthBgPl from "images/pl/home/personal-payments/bg-4.png";

import firstBgUk from "images/uk/home/personal-payments/bg-1.png";
import secondBgUk from "images/uk/home/personal-payments/bg-2.png";
import thirdBgUk from "images/uk/home/personal-payments/bg-3.png";
import fourthBgUk from "images/uk/home/personal-payments/bg-4.png";
import useCurrLang from "hooks/useCurrLang";

const localeImages = {
  en: {
    firstBg: firstBgEn,
    secondBg: secondBgEn,
    thirdBg: thirdBgEn,
    fourthBg: fourthBgEn,
  },
  pl: {
    firstBg: firstBgPl,
    secondBg: secondBgPl,
    thirdBg: thirdBgPl,
    fourthBg: fourthBgPl,
  },
  uk: {
    firstBg: firstBgUk,
    secondBg: secondBgUk,
    thirdBg: thirdBgUk,
    fourthBg: fourthBgUk,
  },
};

const PersonalPayments = () => {
  const { t } = useTranslation();
  const currLang = useCurrLang();

  return (
    <section className="personal-payments" id="personal-payments-section">
      <div className="container">
        <SectionHeader
          title={<Trans i18nKey="home.payments.Personal payments" />}
          body={t("home.payments.Header description")}
          img={require("images/en/home/personal-payments/header-title.png")}
        />
        <Card
          className="card-mb-2"
          title={t("home.payments.Send money with a payment link")}
          body={t("home.payments.sendMoneyDescription")}
          iconSrc={require("images/en/home/personal-payments/icon-1.png")}
          backgroundImgSrc={localeImages[currLang].firstBg}
          backgroundGradient="linear-gradient(179deg, #FFFCE5 1.23%, #FFF1CC 98.71%)"
          cardSize="large"
        />
        <div className="card-group-wrapper">
          <Card
            className="card-mb-2"
            title={t("home.payments.Make group payments")}
            body={t("home.payments.makePaymentsDescription")}
            iconSrc={require("images/en/home/personal-payments/icon-2.png")}
            backgroundImgSrc={localeImages[currLang].secondBg}
            backgroundGradient="linear-gradient(180deg, #F4F1FF 0.01%, #E3E1FE 100%)"
            cardSize="small"
          />
          <Card
            className="card-mb-2"
            title={t("home.payments.Payment history and tracking")}
            body={t("home.payments.paymentHistoryDescription")}
            iconSrc={require("images/en/home/personal-payments/icon-3.png")}
            backgroundImgSrc={localeImages[currLang].thirdBg}
            backgroundGradient="linear-gradient(180deg, #EAF3FD 0%, #DCEBFB 100%)"
            cardSize="small"
          />
        </div>
        <Card
          className="mb-6"
          title={t("home.payments.Request money with payment link")}
          body={t("home.payments.requestMoneyDescription")}
          iconSrc={require("images/en/home/personal-payments/icon-4.png")}
          backgroundImgSrc={localeImages[currLang].fourthBg}
          backgroundGradient="linear-gradient(175deg, #EFFDE0 4.36%, #D9FADE 95.66%)"
          cardSize="large"
        />
        <UndelineLinkSection to="/" name={t("general.Learn more")} />
      </div>
    </section>
  );
};

export default PersonalPayments;
