import calendarIcon from "icons/calendar.svg";
import image from "images/en/shareholders/news-image.png";
import europianImage from "images/en/news/europian-logos.png";
import arrowLeft from "icons/arrow-left.svg";
import { Link } from "react-router-dom";
import { NavPath } from "types/nav";
import { Trans, useTranslation } from "react-i18next";
import useCurrLang from "hooks/useCurrLang";
import AppStoreButton from "./../components/buttons/AppStoreButton";
import GooglePlayButton from "./../components/buttons/GooglePlayButton";

const NewsPublicBetaTesting = () => {
  const { t } = useTranslation();
  const currLang = useCurrLang();

  return (
    <section id="news-show">
      <div className="container">
        <div className="back-to-news">
          <Link to={NavPath.ForShareholders}>
            <img src={arrowLeft} alt="arrow back" />
            <p>Back to all news</p>
          </Link>
        </div>
        <div className="date">
          <img src={calendarIcon} alt="calendar" />
          February 14, 2024
        </div>
        <h2>
          <Trans i18nKey="news.publicBetaTesting.Title">
            Public beta testing
          </Trans>
        </h2>
        <div className="body">
          <img src={image} alt="blur orovera app" />
          <p>
            <Trans i18nKey="news.publicBetaTesting.paragraph-1">
              We are excited to announce the launch of the public beta testing of our newest service for convenient, secure, and fast payments on social networks. Your opinion is extremely important to us, and we are committed to improving our service based on your feedback.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="news.publicBetaTesting.paragraph-2">
              Please note that to access all features of the service, it is necessary to go through the identification process. The bonus balance is activated only for verified users.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="news.publicBetaTesting.paragraph-3">
              During the beta testing period, we ask you to refrain from making big payments. Your safety and comfort are our top priority.
            </Trans>
          </p>
          <p className="mt-md">
            <Trans i18nKey="news.publicBetaTesting.paragraph-4">
              We eagerly await your feedback and suggestions. Please share them through our feedback form or send them to our email at support@orovera.com. Links to download the application are available below.
            </Trans>
          </p>
          <div className="download">
            <AppStoreButton link="https://apps.apple.com/app/id6476556920" openInNewTab={true} />
            <GooglePlayButton link="https://play.google.com/store/apps/details?id=com.orovera.app" openInNewTab={true} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsPublicBetaTesting;
