import { Trans, useTranslation } from "react-i18next";
import useCurrLang from "hooks/useCurrLang";

const Rates = () => {
	const { t } = useTranslation();
	const currLang = useCurrLang();

	return (
		<section id="rates">
			<div className="container">
				<h2>
					<Trans i18nKey="rates.Title">
						Nowa taryfa!
					</Trans>
				</h2>
				<div className="body">
					<p>
						<Trans i18nKey="rates.p1">
							Najprostsze i najbardziej przejrzyste prowizje, bez żadnych ukrytych i dodatkowych opłat.
						</Trans>
					</p>
					<div className="container-table">
						<div className="table_rate table_eur">
							<table>
								<caption>3-250 €</caption>
								<tr>
									<th><Trans i18nKey="rates.table_col1">Prowizja</Trans></th>
									<th><Trans i18nKey="rates.table_col2">Kwota przelewu</Trans></th>
								</tr>
								<tr>
									<td>1 €</td>
									<td>3-15 €</td>
								</tr>
								<tr>
									<td>3 €</td>
									<td>16-100 €</td>
								</tr>
								<tr>
									<td>5 €</td>
									<td>101-200 €</td>
								</tr>
								<tr>
									<td>7 €</td>
									<td>201-250 €</td>
								</tr>
							</table>
						</div>
						<div className="table_rate table_pln">
							<table>
								<caption>5-1000 zł</caption>
								<tr>
									<th><Trans i18nKey="rates.table_col1">Prowizja</Trans></th>
									<th><Trans i18nKey="rates.table_col2">Kwota przelewu</Trans></th>
								</tr>
								<tr>
									<td>3 zł</td>
									<td>5-25 zł</td>
								</tr>
								<tr>
									<td>5 zł</td>
									<td>26-100 zł</td>
								</tr>
								<tr>
									<td>7 zł</td>
									<td>101-200 zł</td>
								</tr>
								<tr>
									<td>9 zł</td>
									<td>201-300 zł</td>
								</tr>
								<tr>
									<td>11 zł</td>
									<td>301-400 zł</td>
								</tr>
								<tr>
									<td>13 zł</td>
									<td>401-500 zł</td>
								</tr>
								<tr>
									<td>15 zł</td>
									<td>501-600 zł</td>
								</tr>
								<tr>
									<td>17 zł</td>
									<td>601-700 zł</td>
								</tr>
								<tr>
									<td>19 zł</td>
									<td>701-800 zł</td>
								</tr>
								<tr>
									<td>21 zł</td>
									<td>801-900 zł</td>
								</tr>
								<tr>
									<td>23 zł</td>
									<td>901-1000 zł</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Rates;
