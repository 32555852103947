import { FC, useCallback, useMemo, useState } from "react";
import arrowDownSvg from "icons/arrow-down-black.svg";
import arrowUpSvg from "icons/arrow-up-black.svg";

interface FaqItemProps {
  title: string;
  items?: {
    title?: string;
    body?: string;
  }[];
}

const FaqItem: FC<FaqItemProps> = (props) => {
  const [activeItemIndexes, setActiveItemIndexes] = useState<number[]>([]);

  const onPressItem = useCallback(
    (index: number) => {
      let res: number[];

      if (activeItemIndexes.includes(index)) {
        res = activeItemIndexes.filter((it) => it !== index);
      } else {
        res = [...activeItemIndexes, index];
      }

      setActiveItemIndexes(res);
    },
    [activeItemIndexes]
  );

  const itemsToRender = useMemo(() => {
    return props.items?.map((it, i) => {
      const isActive = activeItemIndexes.includes(i);
      const arrowIcon = isActive ? arrowUpSvg : arrowDownSvg;

      return (
        <div
          key={"faq-item-" + i}
          className={`item  ${isActive ? "active" : ""}`}
        >
          <div
            className="title-container"
            onClick={() => {
              onPressItem(i);
            }}
          >
            <p>{it.title}</p>

            <img src={arrowIcon} alt="arrow" />
          </div>

          <p className="body">{it?.body}</p>
        </div>
      );
    });
  }, [activeItemIndexes, props.items]);

  return (
    <div className="faq-item">
      <h2>{props?.title}</h2>

      <div>{itemsToRender}</div>
    </div>
  );
};

export default FaqItem;
