import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const TermsOfAcquiringServicesPL = () => {
  const { t } = useTranslation();

  return (
    <section id="terms-of-services">
      <TermsAndPrivacyTemplate
        title={'REGULAMIN POJEDYNCZEJ TRANSAKCJI'}
        titleVersion={t("Version 1.0.")}
        titleUpdated={t("As from twenty seventh of March,  2024.")}
        items={[
          {
            title: '1. DEFINICJE',
            body: (
              <ol>
                <span>Terminy stosowane w niniejszym Regulaminie, pisane dużą literą mają znaczenie nadane im poniżej:</span>
                <li><strong>Akceptant &ndash; </strong>Quicko Sp. z o.o. z siedzibą w Warszawie, oferujący towary lub usługi w Serwisie Sprzedażowym.</li>
                <li><strong>Bank</strong> &ndash; wydawca Karty Płatniczej Klienta lub prowadzący rachunek płatniczy dla Akceptanta albo wydawca BLIK dla Klienta.</li>
                <li><strong>BLIK </strong>&ndash; instrument płatniczy, wykorzystujący jednorazowe kody generowane w bankowości elektronicznej Klienta, wydawany w ramach schematu płatniczego prowadzonego przez Polski Standard Płatności sp. z o.o., kt&oacute;ry umożliwia zainicjowanie przez Klienta Transakcji.</li>
                <li><strong>Dzień Roboczy </strong>- każdy dzień od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy w Polsce.</li>
                <li><strong>Fenige</strong> &ndash; Fenige S.A. z siedzibą w Warszawie, przy ul. Promiennej 38/1, 03-672 Warszawa, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego, prowadzonego przez XIV Wydział Gospodarczy Sądu Rejonowego dla m. st. Warszawy, pod numerem KRS: 0001057371, posiadająca NIP: 1182092036, REGON: 146693435, adres e-mail: <a href="mailto:contact@fenige.pl">contact@fenige.pl</a>, posiadająca status krajowej instytucji płatniczej i nadzorowana przez Komisję Nadzoru Finansowego (Rejestr UKNF: IP42/2017).</li>
                <li><strong>Karta Płatnicza</strong> &ndash; instrument płatniczy; karta płatnicza wydana Klientowi w ramach systemu Mastercard lub Visa &ndash; kt&oacute;ra umożliwia wykonanie Transakcji.</li>
                <li><strong>Organizacja Płatnicza</strong> - Mastercard, Visa lub Polski Standard Płatności sp. z o.o.; organizacja udzielająca licencji na wydawanie Kart Płatniczych albo BLIK oraz zapewniająca systemy pozwalające autoryzować i rozliczać Transakcje.</li>
                <li><strong>Klient </strong>&ndash; osoba korzystająca z Karty Płatniczej, kt&oacute;ra zleca wykonanie Transakcji w celu zapłaty za towary lub usługi zakupione w Serwisie Sprzedażowym.</li>
                <li><strong>Procedura 3-D Secure</strong> &ndash; dodatkowa procedura uwierzytelniająca posiadacza Karty Płatniczej, wymagana przez Organizacje Płatnicze.</li>
                <li><strong>Regulamin</strong> &ndash; niniejszy regulamin, na podstawie kt&oacute;rego zawierana jest Umowa o Pojedynczą Transakcję pomiędzy Fenige i Klientem.</li>
                <li><strong>Regulamin Serwisu Sprzedażowego</strong> &ndash; regulamin korzystania z Serwisu Sprzedażowego zapewniany przez Akceptanta.</li>
                <li><strong>RODO </strong>- rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych).</li>
                <li><strong>Serwis Sprzedażowy</strong> &ndash; strona internetowa, aplikacja&nbsp; lub&nbsp; usługa&nbsp; wykorzystywana&nbsp; przez Akceptanta&nbsp; do oferowania&nbsp; towar&oacute;w&nbsp; lub usług.</li>
                <li><strong>Transakcja </strong>&ndash; transakcja płatnicza zainicjowana Kartą Płatniczą, wykonywana w celu zapłaty za towary lub usługi zakupione w Serwisie Sprzedażowym.</li>
                <li><strong>Umowa o Pojedynczą Transakcję </strong>&ndash; umowa wykonania pojedynczej Transakcji płatniczej w rozumieniu Ustawy, zawarta przez Klienta z Fenige poprzez akceptację przez Klienta Regulaminu.</li>
                <li><strong>Ustawa</strong> &ndash; ustawa z dnia 19 sierpnia 2011 r. o usługach płatniczych.</li>
                <li><strong>Ustawa AML </strong>&ndash; ustawa z dnia 1 marca 2018 roku o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu.</li>
                <li><strong>Zlecenie Płatnicze </strong>&ndash; oświadczenie Klienta zawierające dyspozycję wykonania przez Fenige Transakcji.</li>
              </ol>
            ),
          },
          {
            title: '2. POSTANOWIENIA WSTĘPNE I ZGODY',
            body: (
              <ol>
                <li>Niniejszy Regulamin określa warunki i zasady wykonania przez Fenige pojedynczej Transakcji na zlecenie Klienta za pośrednictwem Serwisu Sprzedażowego.</li>
                <li>Serwis Sprzedażowy stanowi w całości usługę Akceptant odpowiedzialny jest za jego dostępność, prawidłowe funkcjonowanie i bezpieczeństwo, w tym za działanie usługi konta Klienta w Serwisie Sprzedażowym &ndash; o ile taka usługa oferowana jest przez Akceptanta.</li>
                <li>Klient akceptując Regulamin zawiera na jego podstawie z Fenige Umowę o Pojedynczą Transakcję, przez co Klient zobowiązuje się do przestrzegania wszystkich postanowień Regulaminu, jak i obowiązujących przepis&oacute;w prawa oraz wywiązywania się z przewidzianych w Regulaminie zobowiązań.</li>
                <li>Fenige świadczy usługę Transakcji na podstawie otrzymanego wyłącznie od Klienta w Serwisie Sprzedażowym Zlecenia Płatniczego.</li>
                <li>Przy wykonywaniu Transakcji Fenige akceptuje Karty Płatnicze Mastercard i Visa.</li>
                <li>Wykaz walut, w jakich możliwe jest zlecenie wykonania Transakcji oraz walut na jakie może nastąpić przeliczenie kwoty Transakcji, dostępny jest dla Klienta w Serwisie Sprzedażowym podczas składania Zlecenia Płatniczego.</li>
                <li>Świadczenie usług Transakcji nie obejmuje otwarcia i prowadzenia przez Fenige dla Klienta rachunku płatniczego w rozumieniu Ustawy, nie jest też związane z otwarciem i prowadzeniem rachunku bankowego w rozumieniu ustawy z dnia 23 kwietnia 1964 r. &ndash; Kodeks cywilny oraz ustawy z dnia 29 sierpnia 1997 r. &ndash; Prawo bankowe, a czynności dokonywane przez Fenige na podstawie Regulaminu nie mają charakteru czynności bankowych.</li>
                <li>Klient wyraża zgodę na ujawnienie przez Fenige Akceptantowi informacji i danych dotyczących jego osoby, zlecanych przez niego w Serwisie Sprzedażowym Transakcji oraz składanych przez niego reklamacji, tj. informacji i danych chronionych tajemnicą zawodową, o kt&oacute;rej mowa w art. 11 Ustawy (tajemnica płatnicza). Akceptant przetwarza te dane jako ich administrator zgodnie z obowiązującymi przepisami o ochronie danych osobowych.</li>
                <li>Akceptując Regulamin Klient żąda, aby całkowite wykonanie Umowy o Pojedynczą Transakcję, a tym samym wykonanie samej Transakcji, nastąpiło przed upływem ustawowego terminu na odstąpienie od tego rodzaju umowy, terminu 14 dni od zaakceptowania Regulaminu.</li>
              </ol>
            ),
          },
          {
            title: '3. REALIZACJA PŁATNOŚCI',
            body: (
              <ol>
                <li>Zlecenie każdej pojedynczej Transakcji przez Klienta za pośrednictwem Serwisu wymaga od niego uprzednio rejestracji i zalogowania się do konta Klienta w Serwisie Sprzedażowym na zasadach określonych w Regulaminie Serwisu Sprzedażowego (o ile Serwis Sprzedażowy wymaga rejestracji w celu zlecania wykonywania Transakcji)z a także akceptacji treści niniejszego Regulaminu, co oznacza zapoznanie się z jego treścią oraz zobowiązanie się do przestrzegania jego warunk&oacute;w i zasad oraz jest r&oacute;wnoznaczne z zawarciem Umowy o Pojedynczą Transakcję.</li>
                <li>Wykonanie Transakcji wymaga podania przez Klienta następujących informacji i danych:
                  <ol type="A">
                    <li>imię i nazwisko Klienta,</li>
                    <li>adres e-mail Klienta,</li>
                    <li>wygenerowany dla Klienta kod BLIK (o ile jest on wymagany przy danej Transakcji),</li>
                    <li>numer, data ważności i kod CVV/CVC Karty Płatniczej Klienta, kt&oacute;rej rachunek ma zostać obciążony,</li>
                    <li>kod w ramach Procedury 3-D Secure (o ile jest on wymagany przy Transakcji daną Kartą Płatniczą),</li>
                  </ol>
                </li>
                <li>Klient zobowiązuje się do podawania, w celu wykonania Transakcji, prawdziwych, kompletnych i aktualnych informacji i danych. Klient jest w pełni odpowiedzialny za podanie nieprawidłowych danych.</li>
                <li>Przy realizacji Transakcji Fenige dokonuje samodzielnie, lub przy wsp&oacute;łpracy z innymi podmiotami, weryfikacji tożsamości Klienta i stosuje odpowiednie środki bezpieczeństwa finansowego.</li>
                <li>Jeżeli Transakcja wiąże się z przeliczeniem waluty, przed wykonaniem Transakcji Klient otrzymuje informację o faktycznym kursie walutowym, kt&oacute;ry zostanie zastosowany przez Fenige do Transakcji oraz o kwocie Transakcji po przeliczeniu walut. Klient ponosi koszty wymiany walut wliczone przez Fenige do wyświetlanego Klientowi kursu walutowego.</li>
                <li>Ewentualny zwrot kwoty Transakcji może wiązać się z przewalutowaniem po innym kursie, niż ten, kt&oacute;ry został zastosowany podczas składania Zlecenia Płatniczego.</li>
                <li>Przyjęcie Zlecenia Płatniczego od zweryfikowanego Klienta do realizacji następuje po podaniu przez Klienta wszystkich wymaganych informacji i danych, kliknięciu w Serwisie Sprzedażowym w przycisk zatwierdzający złożenie Zlecenia Płatniczego oraz przejściu procedury uwierzytelniającej, w szczeg&oacute;lności Procedury 3-D Secure (o ile jest wymagana) &ndash; co jest r&oacute;wnoznaczne z wyrażeniem zgody przez Klienta na wykonanie Transakcji (autoryzacja).</li>
                <li>Niezwłocznie po otrzymaniu Zlecenia Płatniczego Fenige udostępnia Klientowi w Serwisie Sprzedażowym lub przesyła na adres e-mail podany przez Klienta przy złożeniu Zlecenia Płatniczego informację:
                  <ol type="A">
                    <li>umożliwiającą Klientowi zidentyfikowanie Transakcji</li>
                    <li>o kwocie Transakcji w walucie użytej w Zleceniu Płatniczym,</li>
                    <li>o kursie walutowym zastosowanym w danej Transakcji przez Fenige oraz o kwocie Transakcji po przeliczeniu walut, jeżeli Transakcja wiązała się z przeliczaniem waluty,</li>
                    <li>o dacie otrzymania Zlecenia Płatniczego.</li>
                  </ol>
                </li>
                <li>Całkowite wykonanie Zlecenia Płatniczego rozpoczyna się w dniu, w kt&oacute;rym Klient przekazał do dyspozycji Fenige środki pieniężne na wykonanie Zlecenia Płatniczego. Jeżeli jednak ten dzień nie jest Dniem Roboczym uznaje się, że Zlecenie Płatnicze zostało otrzymane przez Fenige pierwszego Dnia Roboczego po tym dniu.</li>
                <li>Fenige będzie dążyć do jak najszybszego doprowadzenia do uznania kwotą Transakcji rachunku płatniczego Akceptanta, nawet w ciągu 30 minut od chwili otrzymania przez Fenige z Serwisu Sprzedażowego informacji o złożeniu Zlecenia Płatniczego, maksymalny czas wykonania Transakcji jest liczony jednakże do końca następnego Dnia Roboczego od momentu otrzymania przez Fenige Zlecenia Płatniczego, tj. od dnia, o kt&oacute;rym mowa w ust. 7 niniejszego paragrafu.</li>
                <li>Bank Klienta lub Bank Akceptanta może blokować, zatrzymać lub odrzucić Transakcję ze względ&oacute;w bezpieczeństwa lub z innych powod&oacute;w, takich jak np. brak środk&oacute;w pieniężnych na rachunku Klienta. Możliwe jest r&oacute;wnież, że środki zostaną zablokowane w wyniku przerwanej lub nieudanej Transakcji. Fenige nie ponosi odpowiedzialności za działania Bank&oacute;w w tym zakresie. W przypadku kiedy niemożliwe będzie przekazanie środk&oacute;w Bankowi Akceptanta, o ile nie stoją temu na przeszkodzie inne względy prawne, Fenige zwr&oacute;ci niezwłocznie kwotę Transakcji Klientowi.</li>
                <li>Ze względu na całkowite wykonanie Transakcji na żądanie Klienta będącego konsumentem przed upływem ustawowego terminu na odstąpienie od Umowy o Pojedynczą Transakcję, tj. terminu 14 dni od zaakceptowania Regulaminu, prawo do odstąpienia od Umowy o Pojedynczą Transakcję po wykonaniu Transakcji nie przysługuje.</li>
                <li>Klient może w każdej chwili wycofać zgodę na wykonanie Transakcji, nie p&oacute;źniej jednak niż do chwili otrzymania Zlecenia Płatniczego przez Fenige, w kt&oacute;rym to momencie Zlecenie Płatnicze staje się nieodwołalne.</li>
              </ol>
            ),
          },
          {
            title: '4. ODMOWA LUB WSTRZYMANIE WYKONANIA TRANSAKCJI',
            body: (
              <ol>
                <li>Fenige może odm&oacute;wić wykonania Transakcji, wstrzymać wykonanie Transakcji, w przypadku gdy:
                  <ol type="A">
                    <li>Klient narusza postanowienia Regulaminu,</li>
                    <li>Klient nie podał pełnych, kompletnych, ważnych lub prawdziwych informacji i danych niezbędnych do wykonania Transakcji,</li>
                    <li>nie uzyskano pozytywnego wyniku Procesu 3-D Secure w stosunku do Transakcji (w przypadku kiedy było to wymagane),</li>
                    <li>Karta Płatnicza Klienta uniemożliwia obciążenie lub uznanie rachunku środkami pieniężnymi z Transakcji zgodnie z obowiązującymi regulacjami Organizacji Płatniczych, będzie zablokowana, zastrzeżona lub nieważna w momencie wykonania Transakcji albo jeżeli BLIK będzie uniemożliwiał wykonanie Transakcji,</li>
                    <li>Bank Klienta lub Bank Akceptanta albo inny dostawca prowadzący rachunek płatniczy dla Klienta lub dla Akceptanta odm&oacute;wi wykonania Transakcji albo instytucje te nie odpowiedzą na prawidłowo wysłane do nich komunikaty płatnicze,</li>
                    <li>jest to uzasadnione przyczynami związanymi z bezpieczeństwem Transakcji, w tym w przypadku podejrzewania przez Fenige, że dochodzi do bezprawnego korzystania z usług Transakcji lub pr&oacute;by zainicjowania nieautoryzowanej Transakcji,</li>
                    <li>Fenige podejrzewa, że Zlecenie Płatnicze nie jest zgodne z obowiązującymi przepisami prawa, regulacjami Organizacji Płatniczych lub Regulaminem,</li>
                    <li>poziom ryzyka Transakcji będącej przedmiotem Zlecenia Płatniczego, według oceny ryzyka przeprowadzonej przez Fenige lub osobę trzecią, jest niedopuszczalny,</li>
                    <li>spełnione są warunki do wstrzymania lub odmowy wykonania Transakcji na podstawie obowiązujących przepis&oacute;w prawa związanych z przeciwdziałaniem praniu pieniędzy i finansowaniu terroryzmu,</li>
                    <li>Fenige otrzyma takie polecenie od organu władzy publicznej, w tym od organ&oacute;w ścigania.</li>
                  </ol>
                </li>
                <li>Fenige nie ponosi odpowiedzialności za szkody poniesione przez Klienta z powodu niewykonania lub op&oacute;źnienia w wykonaniu Transakcji na podstawie jednej z sytuacji wskazanych powyżej, chyba że odpowiedzialność Fenige przewidują bezwzględnie obowiązujące przepisy prawa.</li>
                <li>W przypadku odmowy wykonania Transakcji, po tym jak Fenige weszło już w posiadanie środk&oacute;w pieniężnych Klienta, Fenige zwraca Klientowi niezwłocznie kwotę Transakcji na rachunek płatniczy, z kt&oacute;rego została wykonana Transakcja z wyjątkiem kiedy jest to niedopuszczalne na podstawie powszechnie obowiązujących przepis&oacute;w prawa.</li>
              </ol>
            ),
          },
          {
            title: '5. PROWIZJA I OPŁATY',
            body: (
              <ol>
                <li>Z tytułu wykonania Transakcji Fenige nie pobiera od Klienta żadnych opłat.</li>
                <li>Klient przyjmuje do wiadomości, że może ponosić ewentualne dodatkowe opłaty na rzecz bank&oacute;w bądź innych dostawc&oacute;w usług płatniczych, zgodnie z treścią um&oacute;w łączących Klienta z tymi podmiotami (np. dodatkowa opłata na rzecz banku za transakcje kartą płatniczą), jednakże ani Fenige ani Akceptant nie jest stronami tych um&oacute;w i nie są to opłaty ponoszone na ich rzecz.</li>
              </ol>
            ),
          },
          {
            title: '6. ODPOWIEDZIALNOŚĆ',
            body: (
              <ol>
                <li>Fenige ponosi wobec Klienta odpowiedzialność na zasadach określonych w Ustawie, z wyłączeniem odpowiedzialności za utracone korzyści.</li>
                <li>Fenige nie odpowiada za niewykonanie lub nieprawidłowe wykonanie zobowiązania będącego skutkiem działania zdarzenia siły wyższej.</li>
                <li>Fenige nie jest stroną i nie odpowiada za wykonanie umowy o wydanie instrumentu płatniczego Karty Płatniczej ani BLIK</li>
                <li>Klient odpowiedzialny jest za korzystanie z usług Fenige na podstawie niniejszego Regulaminu zgodnie z przepisami powszechnie obowiązującego prawa. W szczeg&oacute;lności Klient nie może korzystać z usług Transakcji do zapłaty za towary lub usługi, kt&oacute;rymi obr&oacute;t jest nielegalny albo w celu realizacji transakcji handlowych, kt&oacute;re naruszają prawa os&oacute;b trzecich.</li>
              </ol>
            ),
          },
          {
            title: '7. REKLAMACJE',
            body: (
              <ol>
                <li>Fenige rozpatruje zgłoszone przez Klient&oacute;w reklamacje dotyczące Transakcji, w tym stwierdzonych Transakcji nieautoryzowanych, nieprawidłowo zainicjowanych, niewykonanych lub nienależycie wykonanych.</li>
                <li>Klient może złożyć reklamację:
                  <ol type="A">
                    <li>w formie pisemnej &ndash; przesyłką na adres Fenige albo osobiście w siedzibie Fenige,</li>
                    <li>w formie ustnej - osobiście w siedzibie Fenige lub pod numerem telefonu: +48 576 140 180,</li>
                    <li>w postaci elektronicznej na adres e-mail: <a href="mailto:complaints@fenige.pl">complaints@fenige.pl</a>.</li>
                  </ol>
                </li>
                <li>Reklamacje dotyczące Transakcji nieautoryzowanych, nieprawidłowo zainicjowanych, niewykonanych lub nienależycie wykonanych powinny zostać złożone niezwłocznie po zaistnieniu okoliczności budzących zastrzeżenia.</li>
                <li>Reklamacja powinna zawierać co najmniej:
                  <ol type="A">
                    <li>dane umożliwiające identyfikację Klienta (imię i nazwisko, adres do korespondencji, datę złożenia Zlecenia Płatniczego, ),</li>
                    <li>opis przedmiotu reklamacji,</li>
                    <li>określenie żądania Klienta,</li>
                    <li>wskazanie, że Klient chce aby odpowiedź na reklamację została przesłana mu na jego adres e-mail &ndash; o ile taka jest wola Klienta.</li>
                  </ol>
                </li>
                <li>Do reklamacji powinny być dołączone kopie dokument&oacute;w (np. potwierdzeń dokonania lub odmowy dokonania Transakcji), kt&oacute;rych reklamacja dotyczy.</li>
                <li>Jeżeli podane w reklamacji dane lub informacje wymagają uzupełnienia w celu rozpatrzenia reklamacji, Fenige zwraca się do Klienta o jej uzupełnienie we wskazanym zakresie i terminie. W takim przypadku Klient zobowiązany jest wsp&oacute;łpracować z Fenige, co ma na celu wyjaśnienie okoliczności reklamowanego zdarzenia.</li>
                <li>Fenige udziela odpowiedzi na reklamację w formie papierowej lub, po uzgodnieniu z Klientem, na innym trwałym nośniku informacji (np. w postaci elektronicznej na adres e-mail, z kt&oacute;rego zostało wysłane zgłoszenie reklamacyjne).</li>
                <li>Odpowiedź na reklamację jest przygotowana i przekazana Klientowi bez zbędnej zwłoki, jednak nie p&oacute;źniej niż w terminie 15 Dni Roboczych od dnia wpływu reklamacji. W szczeg&oacute;lnie skomplikowanych przypadkach uniemożliwiających rozpatrzenie reklamacji i udzielenie odpowiedzi w terminie, Fenige:
                  <ol type="A">
                    <li>wyjaśni przyczyny braku możliwości dotrzymania terminu,</li>
                    <li>wskaże okoliczności, kt&oacute;re muszą zostać ustalone dla rozpatrzenia sprawy,</li>
                    <li>wskaże przewidywany termin udzielenia odpowiedzi, nie dłuższy jednak niż 35 Dni Roboczych od dnia otrzymania przez nią reklamacji.</li>
                  </ol>
                </li>
                <li>Do zachowania termin&oacute;w, o kt&oacute;rych mowa w ust. 8 niniejszego paragrafu, wystarczy wysłanie odpowiedzi przed ich upływem, a w przypadku odpowiedzi udzielonych na piśmie &ndash; nadanie w plac&oacute;wce pocztowej operatora wyznaczonego w rozumieniu ustawy z dnia 23 listopada 2012 roku &ndash; Prawo pocztowe. W przypadku niedotrzymania termin&oacute;w i warunk&oacute;w z ust. 8 niniejszego paragrafu, reklamację uważa się za rozpatrzoną zgodnie z wolą Klienta.</li>
                <li>W przypadku uwzględnienia reklamacji, kt&oacute;rej wynikiem jest obowiązek zwrotu kwoty Transakcji, Fenige niezwłocznie zwraca Klientowi kwotę Transakcji na rachunek płatniczy, z kt&oacute;rego została wykonana Transakcja z wyjątkiem przypadku gdy Fenige ma uzasadnione i należycie udokumentowane podstawy, aby podejrzewać oszustwo, i poinformuje o tym w formie pisemnej organy powołane do ścigania przestępstw.</li>
                <li>Jeżeli Fenige nie uznała reklamacji Klienta będącego konsumentem, a ten nie zgadza się z decyzją Fenige, Klient może zwr&oacute;cić się o mediację do jednego z sąd&oacute;w polubownych działających przy wojew&oacute;dzkich inspektoratach Inspekcji Handlowej. Więcej informacji jest dostępnych na stronie Urzędu Ochrony Konkurencji i Konsument&oacute;w: <a href="https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php">https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</a>. Klient może r&oacute;wnież skorzystać z polubownego sposobu rozwiązania sporu przed Sądem Polubownym przy Komisji Nadzoru Finansowego.</li>
              </ol>
            ),
          },
          {
            title: '8. OCHRONA DANYCH OSOBOWYCH',
            body: (
              <ol>
                <li>Podanie danych osobowych w zakresie niezbędnym, wskazanym w 3 Regulaminu, jest dobrowolne, ale jest warunkiem koniecznym do zawarcia i wykonania Umowy o Pojedynczą Transakcję.</li>
                <li>Fenige posiada status krajowej instytucji płatniczej, co oznacza, że jest instytucją obowiązaną w rozumieniu Ustawy AML, kt&oacute;ra w ramach prowadzonej działalności ma obowiązek stosowania wobec Klient&oacute;w środk&oacute;w bezpieczeństwa finansowego (w tym identyfikacji i weryfikacji ich tożsamości) oraz wypełniania innych obowiązk&oacute;w z Ustawy AML i w tym zakresie pełni r&oacute;wnież rolę administratora ich danych osobowych.</li>
                <li>Fenige wyznaczyła inspektowa ochrony danych, z kt&oacute;rym można skontaktować się pod adresem e-mail: <a href="mailto:iod@fenige.pl">iod@fenige.pl</a> w sprawach dotyczących przetwarzania danych osobowych przez Fenige.</li>
                <li>Fenige przetwarza dane osobowe w celu:
                  <ol type="A">
                    <li>świadczenia usług płatniczych poprzez wykonywanie Transakcji oraz wykonywanie innych czynności przewidzianych w Regulaminie i w Ustawie (na podstawie art. 6 ust. 1 lit. b RODO),</li>
                    <li>realizacji obowiązk&oacute;w prawnych w związku z koniecznością stosowania odpowiednich środk&oacute;w bezpieczeństwa finansowego oraz realizacji innych obowiązk&oacute;w z Ustawy AML, a także innych obowiązk&oacute;w określonych przepisami powszechnie obowiązującego prawa, w szczeg&oacute;lności Ustawy czy ustaw rachunkowych i podatkowych (na podstawie art. 6 ust. 1 lit. c RODO),</li>
                    <li>ustalenia, dochodzenia lub obrony przed roszczeniami &ndash; na podstawie prawnie uzasadnionego interesu Fenige, jakim jest korzystanie z powyższych uprawnień (art. 6 ust. 1 lit. f RODO).</li>
                  </ol>
                </li>
                <li>Fenige jest uprawniona do przekazywania informacji o danych Klient&oacute;w oraz o zleconych przez nich Transakcjach odpowiednim organom państwowym i regulacyjnym, jeżeli taki obowiązek wynika z przepis&oacute;w prawa, w tym w szczeg&oacute;lności z Ustawy i Ustawy AML.</li>
                <li>Dane osobowe mogą być przekazywane Akceptantowi, podmiotom uczestniczącym w procesowaniu Transakcji, takim jak Organizacje Płatnicze, Bank Klienta i Bank Akceptanta oraz dostawcom usług IT, dostawcom usług telekomunikacyjnych i dostawcom usług obsługi klienta w celu prawidłowego wykonania Transakcji oraz obsługi reklamacji.</li>
                <li>Dane osobowe Klient&oacute;w mogą podlegać profilowaniu w rozumieniu RODO w celu stosowania przez Fenige obowiązk&oacute;w nałożonych na nią Ustawą AML. Zautomatyzowane przetwarzanie dotyczy w takim przypadku osoby Klienta i zlecanej przez niego Transakcji. Konsekwencją profilowania może być odmowa lub wstrzymanie wykonania Transakcji, zablokowanie możliwości zlecania wykonywania Transakcji w przyszłości oraz złożenie odpowiednich zawiadomień do organ&oacute;w państwowych przewidzianych w Ustawie AML.</li>
                <li>Każda osoba, kt&oacute;rej dane osobowe przetwarza Fenige, ma prawo dostępu do dotyczących jej danych, prawo ich sprostowania, poprawiania, żądania ograniczenia przetwarzania, przeniesienia, usunięcia, wniesienia sprzeciwu wobec ich dalszego przetwarzania przez Fenige, a w przypadku wyrażenia zgody na przetwarzanie danych do jej wycofania w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej wycofaniem, pisząc w tym celu na adres e-mail: <a href="mailto:iod@fenige.pl">iod@fenige.pl</a>. Wykonywanie powyższych praw nie może jednakże wpłynąć na prawa i obowiązki Fenige określone w ust. 4 b) i c) niniejszego paragrafu.</li>
                <li>Dane osobowe przetwarzane są przez okres niezbędny do wykonania Transakcji i rozpatrzenia reklamacji. Ponadto dane osobowe przetwarzane są w niezbędnym zakresie dla cel&oacute;w rachunkowych, podatkowych oraz dla cel&oacute;w zapobiegania praniu pieniędzy i finansowania terroryzmu przez okres wynikający z obowiązujących przepis&oacute;w prawa oraz w niezbędnym zakresie do ustalenia, dochodzenia lub obrony przed roszczeniami przez okres przedawnienia roszczeń wynikających z niniejszego Regulaminu oraz przez okres trwania toczącego się postępowania.</li>
                <li>Każda osoba, kt&oacute;rej dane osobowe przetwarza Fenige, ma prawo wniesienia skargi dotyczącej przetwarzania jej danych przez Fenige do organu nadzorczego zajmującego się ochroną danych osobowych - Prezesa Urzędu Ochrony Danych Osobowych.</li>
                <li>Pozostałe informacje na temat ochrony danych osobowych, a także na temat zasad wykorzystywania plik&oacute;w cookies zawiera polityka prywatności Fenige dostępna na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a>.</li>
              </ol>
            ),
          },
          {
            title: '9. BEZPIECZEŃSTWO',
            body: (
              <ol>
                <li>Klient powinien przede wszystkim stosować wszystkie środki bezpieczeństwa, wskazane mu w umowie o wydanie Karty Płatniczej i w umowie o wydanie BLIK przez wydawc&oacute;w tych instrument&oacute;w płatniczych, w celu bezpiecznego przechowywania tych instrument&oacute;w płatniczych. Klient powinien r&oacute;wnież stosować się do wydawanych przez tych wydawc&oacute;w i dostarczanych mu komunikat&oacute;w o zachowaniu bezpieczeństwa oraz ostrzeżeń. Niezależnie od powyższego Fenige informuje Klienta, że w stosunku do posiadanych instrument&oacute;w płatniczych, a także spersonalizowanych lub indywidualnych haseł, kod&oacute;w, urządzeń, informacji i danych wykorzystywanych przy zlecaniu Transakcji, musi on:
                  <ol type="A">
                    <li>odpowiednio je chronić, w tym nikomu nie ujawniać, ani nie udostępniać danych do logowania do konta w Serwisie Sprzedażowym ani instrument&oacute;w płatniczych służących do zlecania Transakcji,</li>
                    <li>ustalić hasło logowania, kt&oacute;rego odgadnięcie lub przełamanie jest trudne,</li>
                    <li>stosować blokady na urządzeniach elektronicznych służących do zlecania Transakcji oraz odpowiednie i aktualizowane na bieżąco programy antywirusowe,</li>
                    <li>nikomu nie udostępniać urządzeń lub innych materiał&oacute;w z zapisanymi lub widocznymi hasłami i kodami ani innego rodzaju informacjami służącymi do przejścia procedury uwierzytelniającej, co mogłoby spowodować wykonanie nieautoryzowanej Transakcji, a także starannie chronić takie materiały i urządzania, w tym nie używać na nich program&oacute;w do automatycznego zapisywania haseł,</li>
                  </ol>
                </li>
                <li>Klient zobowiązuje się niezwłocznie zgłosić Fenige stwierdzenie utraty, kradzieży, przywłaszczenia albo nieuprawnionego dostępu lub użycia instrumentu płatniczego, a także hasła do konta w Serwisie Sprzedażowym, hasła jednorazowego, telefonu oraz wszelkich innych danych służących do weryfikacji Klienta i uwierzytelnienia zlecanych przez niego Transakcji, co stwarza ryzyko przeprowadzenia Transakcji przez osobę nieuprawnioną z ich wykorzystaniem. Klient powinien dokonać zgłoszenia:
                  <ol type="A">
                    <li>pocztą elektroniczną na adres e-mail wskazany na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt,</li>
                    <li>telefonicznie pod numerem telefonu wskazany na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
          {
            title: '10. ZMIANA TREŚCI REGULAMINU',
            body: (
                <ol>
                  <li>Niniejszy Regulamin może zostać zmieniony przez Fenige jednostronnie w każdym czasie.</li>
                  <li>Aktualna treść Regulaminu dostępna jest w Serwisie Sprzedażowym.</li>
                </ol>
            ),
          },
          {
            title: '11. OKRES ZWIĄZANIA KLIENTA UMOWĄ O POJEDYNCZĄ TRANSAKCJĘ',
            body: (
                <ol>
                  <span>Umowa o Pojedynczą Transakcję w wersji zaakceptowanej przez Klienta wiąże strony wyłącznie w stosunku do jednej konkretnej Transakcji, do kt&oacute;rej wykonania została zawarta (przez co należy rozumieć zaakceptowanie przez Klienta Regulaminu bezpośrednio przed zleceniem tej Transakcji, o ile Transakcja została następnie zlecona przed wygaśnięciem sesji na urządzeniu) oraz przez czas wykonywania tej Transakcji, związanego z nią ewentualnego zwrotu, a także przez czas rozpatrywania reklamacji i spor&oacute;w w stosunku do niej.</span>
                </ol>
            ),
          },
          {
            title: '12. POSTANOWIENIA KOŃCOWE',
            body: (
              <ol>
                <li>Niniejszy Regulamin podlega prawu polskiemu. W sprawach nieuregulowanych w Regulaminie zastosowanie mają powszechnie obowiązujące przepisy polskiego prawa, a w szczeg&oacute;lności Ustawy i Ustawy AML.</li>
                <li>W przypadku, gdy poszczeg&oacute;lne postanowienia Regulaminu okażą się nieważne lub bezskuteczne w całości lub w części z dowolnej przyczyny, pozostałe postanowienia Regulaminu pozostają w mocy.</li>
                <li>Z zastrzeżeniem przepis&oacute;w bezwzględnie obowiązujących, sądem właściwym do rozpatrywania spor&oacute;w powstałych w związku z Regulaminem jest:
                  <ol type="A">
                    <li>w sprawach z Klientami nie będącymi konsumentami sąd miejscowo właściwy dla siedziby Fenige,</li>
                    <li>w sprawach z Klientami będącymi konsumentami sąd miejscowo właściwy dla miejsca zamieszkania konsumenta, a w przypadku kiedy pow&oacute;dztwo kierowane jest przeciwko Fenige r&oacute;wnież sąd miejscowo właściwy dla siedziby Fenige.</li>
                  </ol>
                </li>
                <li>Jeśli Klient nie jest konsumentem, nie stosuje się do niego przepis&oacute;w Ustawy, kt&oacute;rych wyłączenie w stosunkach z podmiotami innymi niż konsumenci jest dopuszczalne.</li>
                <li>Wszelkie pytania i zgłoszenia dotyczące niniejszego Regulaminu i świadczenia na jego podstawie usług należy kierować do biura obsługi klienta Fenige:
                  <ol type="A">
                    <li>pocztą elektroniczną na adres e-mail wskazany na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt,</li>
                    <li>telefonicznie pod numerem telefonu wskazanym na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt,</li>
                    <li>listowanie na adres siedziby Fenige.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
        ]}
      />
    </section>
  );
};

export default TermsOfAcquiringServicesPL;
