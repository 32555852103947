import firstEnFullSlide from "images/en/home/how-it-works/full/1.jpg";
import secondEnFullSlide from "images/en/home/how-it-works/full/2.jpg";
import thirdEnFullSlide from "images/en/home/how-it-works/full/3.jpg";
import fourthEnFullSlide from "images/en/home/how-it-works/full/4.jpg";
import fifthEnFullSlide from "images/en/home/how-it-works/full/5.jpg";
import sixthEnFullSlide from "images/en/home/how-it-works/full/6.jpg";
import seventhEnFullSlide from "images/en/home/how-it-works/full/7.jpg";
import eighthEnFullSlide from "images/en/home/how-it-works/full/8.jpg";
import ninethEnFullSlide from "images/en/home/how-it-works/full/9.jpg";
import tenthEnFullSlide from "images/en/home/how-it-works/full/10.jpg";

import firstPlFullSlide from "images/pl/home/how-it-works/full/1.jpg";
import secondPlFullSlide from "images/pl/home/how-it-works/full/2.jpg";
import thirdPlFullSlide from "images/pl/home/how-it-works/full/3.jpg";
import fourthPlFullSlide from "images/pl/home/how-it-works/full/4.jpg";
import fifthPlFullSlide from "images/pl/home/how-it-works/full/5.jpg";
import sixthPlFullSlide from "images/pl/home/how-it-works/full/6.jpg";
import seventhPlFullSlide from "images/pl/home/how-it-works/full/7.jpg";
import eighthPlFullSlide from "images/pl/home/how-it-works/full/8.jpg";
import ninethPlFullSlide from "images/pl/home/how-it-works/full/9.jpg";
import tenthPlFullSlide from "images/pl/home/how-it-works/full/10.jpg";

import firstUkFullSlide from "images/uk/home/how-it-works/full/1.jpg";
import secondUkFullSlide from "images/uk/home/how-it-works/full/2.jpg";
import thirdUkFullSlide from "images/uk/home/how-it-works/full/3.jpg";
import fourthUkFullSlide from "images/uk/home/how-it-works/full/4.jpg";
import fifthUkFullSlide from "images/uk/home/how-it-works/full/5.jpg";
import sixthUkFullSlide from "images/uk/home/how-it-works/full/6.jpg";
import seventhUkFullSlide from "images/uk/home/how-it-works/full/7.jpg";
import eighthUkFullSlide from "images/uk/home/how-it-works/full/8.jpg";
import ninethUkFullSlide from "images/uk/home/how-it-works/full/9.jpg";
import tenthUkFullSlide from "images/uk/home/how-it-works/full/10.jpg";

import firstEnMobileSlide from "images/en/home/how-it-works/mobile/1.png";
import secondEnMobileSlide from "images/en/home/how-it-works/mobile/2.png";
import thirdEnMobileSlide from "images/en/home/how-it-works/mobile/3.png";
import fourthEnMobileSlide from "images/en/home/how-it-works/mobile/4.png";
import fifthEnMobileSlide from "images/en/home/how-it-works/mobile/5.png";
import sixthEnMobileSlide from "images/en/home/how-it-works/mobile/6.png";
import seventhEnMobileSlide from "images/en/home/how-it-works/mobile/7.png";
import eighthEnMobileSlide from "images/en/home/how-it-works/mobile/8.png";
import ninethEnMobileSlide from "images/en/home/how-it-works/mobile/9.png";
import tenthEnMobileSlide from "images/en/home/how-it-works/mobile/10.png";

import firstPlMobileSlide from "images/pl/home/how-it-works/mobile/1.png";
import secondPlMobileSlide from "images/pl/home/how-it-works/mobile/2.png";
import thirdPlMobileSlide from "images/pl/home/how-it-works/mobile/3.png";
import fourthPlMobileSlide from "images/pl/home/how-it-works/mobile/4.png";
import fifthPlMobileSlide from "images/pl/home/how-it-works/mobile/5.png";
import sixthPlMobileSlide from "images/pl/home/how-it-works/mobile/6.png";
import seventhPlMobileSlide from "images/pl/home/how-it-works/mobile/7.png";
import eighthPlMobileSlide from "images/pl/home/how-it-works/mobile/8.png";
import ninethPlMobileSlide from "images/pl/home/how-it-works/mobile/9.png";
import tenthPlMobileSlide from "images/pl/home/how-it-works/mobile/10.png";

import firstUkMobileSlide from "images/uk/home/how-it-works/mobile/1.png";
import secondUkMobileSlide from "images/uk/home/how-it-works/mobile/2.png";
import thirdUkMobileSlide from "images/uk/home/how-it-works/mobile/3.png";
import fourthUkMobileSlide from "images/uk/home/how-it-works/mobile/4.png";
import fifthUkMobileSlide from "images/uk/home/how-it-works/mobile/5.png";
import sixthUkMobileSlide from "images/uk/home/how-it-works/mobile/6.png";
import seventhUkMobileSlide from "images/uk/home/how-it-works/mobile/7.png";
import eighthUkMobileSlide from "images/uk/home/how-it-works/mobile/8.png";
import ninethUkMobileSlide from "images/uk/home/how-it-works/mobile/9.png";
import tenthUkMobileSlide from "images/uk/home/how-it-works/mobile/10.png";

const useHowItWorksSlides = () => {
  const slides = [
    {
      name: "1",
      en: {
        full: firstEnFullSlide,
        mobile: firstEnMobileSlide,
      },
      pl: {
        full: firstPlFullSlide,
        mobile: firstPlMobileSlide,
      },
      uk: {
        full: firstUkFullSlide,
        mobile: firstUkMobileSlide,
      },
    },
    {
      name: "2",
      en: {
        full: secondEnFullSlide,
        mobile: secondEnMobileSlide,
      },
      pl: {
        full: secondPlFullSlide,
        mobile: secondPlMobileSlide,
      },
      uk: {
        full: secondUkFullSlide,
        mobile: secondUkMobileSlide,
      },
    },
    {
      name: "3",
      en: {
        full: thirdEnFullSlide,
        mobile: thirdEnMobileSlide,
      },
      pl: {
        full: thirdPlFullSlide,
        mobile: thirdPlMobileSlide,
      },
      uk: {
        full: thirdUkFullSlide,
        mobile: thirdUkMobileSlide,
      },
    },
    {
      name: "4",
      en: {
        full: fourthEnFullSlide,
        mobile: fourthEnMobileSlide,
      },
      pl: {
        full: fourthPlFullSlide,
        mobile: fourthPlMobileSlide,
      },
      uk: {
        full: fourthUkFullSlide,
        mobile: fourthUkMobileSlide,
      },
    },
    {
      name: "5",
      en: {
        full: fifthEnFullSlide,
        mobile: fifthEnMobileSlide,
      },
      pl: {
        full: fifthPlFullSlide,
        mobile: fifthPlMobileSlide,
      },
      uk: {
        full: fifthUkFullSlide,
        mobile: fifthUkMobileSlide,
      },
    },
    {
      name: "6",
      en: {
        full: sixthEnFullSlide,
        mobile: sixthEnMobileSlide,
      },
      pl: {
        full: sixthPlFullSlide,
        mobile: sixthPlMobileSlide,
      },
      uk: {
        full: sixthUkFullSlide,
        mobile: sixthUkMobileSlide,
      },
    },
    {
      name: "7",
      en: {
        full: seventhEnFullSlide,
        mobile: seventhEnMobileSlide,
      },
      pl: {
        full: seventhPlFullSlide,
        mobile: seventhPlMobileSlide,
      },
      uk: {
        full: seventhUkFullSlide,
        mobile: seventhUkMobileSlide,
      },
    },
    {
      name: "8",
      en: {
        full: eighthEnFullSlide,
        mobile: eighthEnMobileSlide,
      },
      pl: {
        full: eighthPlFullSlide,
        mobile: eighthPlMobileSlide,
      },
      uk: {
        full: eighthUkFullSlide,
        mobile: eighthUkMobileSlide,
      },
    },
    {
      name: "9",
      en: {
        full: ninethEnFullSlide,
        mobile: ninethEnMobileSlide,
      },
      pl: {
        full: ninethPlFullSlide,
        mobile: ninethPlMobileSlide,
      },
      uk: {
        full: ninethUkFullSlide,
        mobile: ninethUkMobileSlide,
      },
    },
    {
      name: "10",
      en: {
        full: tenthEnFullSlide,
        mobile: tenthEnMobileSlide,
      },
      pl: {
        full: tenthPlFullSlide,
        mobile: tenthPlMobileSlide,
      },
      uk: {
        full: tenthUkFullSlide,
        mobile: tenthUkMobileSlide,
      },
    },
  ];

  return { slides };
};

export default useHowItWorksSlides;
