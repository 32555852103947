import Sidebar from "components/Sidebar";
import FaqItem from "components/faq/FaqItem";
import StillHaveSomeQuestions from "components/faq/StillHaveSomeQuestions";
import SearchInput from "components/form/SearchInput";
import useQuestions from "hooks/useQuestions";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const FAQ: FC = () => {
  const { t, i18n } = useTranslation();
  const contentDivRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const { questions } = useQuestions();

  const clearSearchInput = useCallback(() => {
    setSearchValue("");
  }, []);

  const itemsToRender = useMemo(() => {
    const lang = i18n.language || i18n.language.slice(0, 2);
    const items = questions.map((it) => {
      const langQuestion = it.translations.find(
        // (trans) => trans.lang = i18n.language || i18n.language.slice(0, 2)
        (trans) => trans.lang === lang
      );
      return { title: langQuestion?.title, body: langQuestion?.body };
    });
    if (items.length === 0) return;

    return <FaqItem title={""} items={items} />;
  }, [i18n.language, questions]);
 
  return (
    <section id="faq">
      <div>
        {/* <Sidebar
          items={[
            {
              title: "Creating account",
              onClick: () => {},
            },
            { title: "Orovera keyboard", onClick: () => {} },
            { title: "Personal payments", onClick: () => {} },
            { title: "Business payments", onClick: () => {} },
            { title: "Payment limits", onClick: () => {} },
            { title: "Security and privacy", onClick: () => {} },
          ]}
        /> */}

        <div
          ref={(r) => ((contentDivRef.current as any) = r)}
          className="content"
        >
          <header>
            <div className="title-container">
              <h1 className="title">
                <Trans i18nKey="faq.header.Title">
                  Frequently Asked
                  <span>Questions</span>
                </Trans>
              </h1>

              {/* <p>
                <Trans i18nKey="faq.header.Description" />
              </p> */}
            </div>

            {/* <SearchInput
              value={searchValue}
              placeholder={t("faq.header.Search")}
              onChange={(v) => setSearchValue(v)}
              clear={clearSearchInput}
            /> */}
          </header>

          {itemsToRender}
        </div>
      </div>

      <StillHaveSomeQuestions />
    </section>
  );
};

export default FAQ;
