import { useTranslation } from "react-i18next";
import ava from "images/en/home/control/ava.png";
import mainImgEn from "images/en/home/control/phone-frog.png";
import mainImgPl from "images/pl/home/control/phone-frog.png";
import mainImgUk from "images/uk/home/control/phone-frog.png";
import AppStoreButton from "components/buttons/AppStoreButton";
import GooglePlayButton from "components/buttons/GooglePlayButton";
import useCurrLang from "hooks/useCurrLang";

const mainImg = {
  en: mainImgEn,
  pl: mainImgPl,
  uk: mainImgUk,
};

const Control = () => {
  const { t } = useTranslation();
  const currLang = useCurrLang();

  return (
    <section className="control">
      <div className="container">
        <div className="sections-wrapper">
          <div className="left-section">
            <img src={mainImg[currLang]} alt="frog phone" />
          </div>
          <div className="right-section">
            <h2>
              {t("home.control.title")} <img src={ava} alt="ava" />
            </h2>
            <p>{t("home.control.subtitle")}</p>
            <div className="buttons">
              <AppStoreButton link="/news/2" />
              <GooglePlayButton link="/news/2" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Control;
