import { FC } from "react";
import noteSvg from "icons/note.svg";
import crossSvg from "icons/cross.svg";

interface ModalMenuBtnProps {
  isMenuOpen: boolean;
  onClick(): void;
}

const ModalMenuBtn: FC<ModalMenuBtnProps> = (props) => {
  return (
    <div
      className={`modal-menu-btn ${
        props.isMenuOpen ? "modal-menu-btn_open" : ""
      }`}
      onClick={props.onClick}
    >
      <img src={props.isMenuOpen ? crossSvg : noteSvg} alt="model-menu" />
    </div>
  );
};

export default ModalMenuBtn;
