import { useMemo, useState } from "react";

interface SidebarItem {
  title: string;
  onClick(): void;
}

interface SidebarProps {
  items: SidebarItem[];
  defActiveIndex?: number;
}

const Sidebar = ({ defActiveIndex = 0, ...props }: SidebarProps) => {
  const [activeItemIndex, setActiveItemIndex] =
    useState<number>(defActiveIndex);

  const items = useMemo(() => {
    return props.items.map((it, index) => {
      const isActive = index === activeItemIndex;
      return (
        <div
          className={
            isActive ? "sidebar__item sidebar__item_active" : "sidebar__item"
          }
          onClick={() => {
            setActiveItemIndex(index);
            it.onClick();
          }}
        >
          <div />

          <span>{it.title}</span>
        </div>
      );
    });
  }, [props.items, activeItemIndex]);

  return <div className="sidebar">{items}</div>;
};

export default Sidebar;
