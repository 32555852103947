import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

export interface TextareaProps extends TextareaAutosizeProps {
  onChangeText?(val: string): void;
}

const Textarea = ({ onChangeText, ...rest }: TextareaProps) => {
  return (
    <div className="textarea-container">
      <TextareaAutosize
        onChange={(e) => (onChangeText ? onChangeText(e.target.value) : null)}
        {...rest}
      />
    </div>
  );
};

export default Textarea;
