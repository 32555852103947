import { FC, InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeText?: (val: string) => void;
}

const Input: FC<InputProps> = ({ onChangeText, ...rest }) => {
  return (
    <div className="input-container">
      <input
        onChange={(event) =>
          onChangeText ? onChangeText(event.target.value) : null
        }
        {...rest}
      />
    </div>
  );
};

export default Input;
