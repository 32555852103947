import { useEffect, useState } from "react";
import { ELang } from "types/lang";
import { useTranslation } from "react-i18next";

const useCurrLang = () => {
  const [currLang, setCurrLang] = useState<ELang>(ELang.EN);

  const { i18n } = useTranslation();

  useEffect(() => {
    getCurrLang();
  }, [i18n.resolvedLanguage]);

  const getCurrLang = () => {
    const resolvedLang = i18n.resolvedLanguage;
    let currLang = ELang.EN;

    if (
      resolvedLang === ELang.EN ||
      resolvedLang === ELang.PL ||
      resolvedLang === ELang.UK
    ) {
      currLang = resolvedLang;
    }

    setCurrLang(currLang);
  };

  return currLang;
};

export default useCurrLang;
