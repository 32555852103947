import classNames from "classnames";
import Heading from "./typography/Heading";
import Paragraph from "./typography/Paragraph";

interface CardProps {
  title: string;
  body: string;
  iconSrc: string;
  backgroundImgSrc: string;
  backgroundGradient: string;
  cardSize: "small" | "medium" | "large";
  cardColor?: "dark" | "white";
  className?: string;
}

const Card = ({
  title,
  body,
  iconSrc,
  backgroundImgSrc,
  backgroundGradient,
  cardSize,
  cardColor = "dark",
  className,
}: CardProps) => {
  return (
    <div
      className={classNames("card-wrapper", className, {
        "card-large": cardSize === "large",
        "card-medium": cardSize === "medium",
        "card-small": cardSize === "small",
        "card-dark-color": cardColor === "dark",
        "card-white-color": cardColor === "white",
      })}
      style={{
        backgroundImage: `url(${backgroundImgSrc}), ${backgroundGradient}`,
      }}
    >
      <div className="info-wrapper">
        <div className="icon">
          <img src={iconSrc} />
        </div>
        <Heading size="h3">{title}</Heading>
        <Paragraph size="M">{body}</Paragraph>
      </div>
    </div>
  );
};

export default Card;
