import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const TermsOfAcquiringServicesEN = () => {
  const { t } = useTranslation();

  return (
    <section id="terms-of-services">
      <TermsAndPrivacyTemplate
        title={'TERMS OF ACQUIRING SERVICES \n (SINGLE TRANSACTION AGREEMENT)'}
        titleVersion={t("Version 1.0.")}
        titleUpdated={t("As from twenty seventh of March,  2024.")}
        items={[
          {
            title: '1. DEFINITIONS',
            body: (
              <ol>
                <span>The capitalized terms used in the present Terms of Services, shall have the meaning given to them below:</span>
                <li><strong>Merchant </strong>&ndash; Quicko Sp. z o.o. with its registered office in Warsaw, Poland, offering goods or services on the Sales Service.</li>
                <li><strong>Bank</strong> &ndash; the issuer of the Payment Card, of the Customer or maintaining the payment account for the Merchant or the issuer of BLIK for the Customer.</li>
                <li><strong>BLIK </strong>&ndash; a payment instrument, utilising one-time codes generated in the electronic banking of the Customer , issued as part of a payment scheme run by the Polish company &ndash; Polski Standard Płatności sp. z o.o., which allows the Customer to initiate the Transaction.</li>
                <li><strong>Business Day</strong> - every day from Monday to Friday, excluding public holidays in Poland.</li>
                <li><strong>Fenige</strong> - Fenige S.A. with its registered office in Warsaw, at ul. Promienna 38/1, 03-672 Warsaw, Poland, entered into the register of the entrepreneurs of the National Court Register conducted by the XIV Economic Department District Court for the capital city of Warsaw in Warsaw with the KRS number: 0001057371, with NIP: 1182092036 and REGON: 146693435, e-mail address: <a href="mailto:contact@fenige.pl">contact@fenige.pl</a>, having the status of a domestic payment institution and supervised by the Polish Financial Supervision Authority (Polish: Komisja Nadzoru Finansowego) (UKNF Register: IP42/2017).</li>
                <li><strong>Payment Card</strong> &ndash; a payment instrument; a payment card issued to the Customer under the Mastercard or under the Visa system.</li>
                <li><strong>Payment Organisation</strong> - Mastercard, Visa or Polski Standard Płatności sp. z o.o.; an organization granting licenses to issue Payment Cards or BLIK and providing systems for authorizing and settling Transactions.</li>
                <li><strong>Customer </strong>&ndash; a person using a Payment Card who orders a Transaction to pay for goods or services purchased on the Sales Service;</li>
                <li><strong>3-D Secure Procedure</strong> &ndash; additional authentication procedure for the Payment Card holder, required by Payment Organisations.</li>
                <li><strong>Terms of Services</strong> &ndash; the present terms and conditions, on the basis of which the Single Transaction Agreement between Fenige and the Customer is concluded.</li>
                <li><strong>Sales Service Terms of Services</strong> &ndash; the terms and conditions of use of the Sales Service provided by the Merchant.</li>
                <li><strong>GDPR </strong>- the Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</li>
                <li><strong>Transaction</strong> &ndash; a payment transaction initiated with a Payment Card ordered by the Customer to pay for the goods or services on the Sales Service.</li>
                <li><strong>Single Transaction Agreement </strong>&ndash; an agreement for the execution of a single Payment Transaction within the meaning of the Act, concluded by the Customer with Fenige through acceptance of the Terms of Services by the Customer .</li>
                <li><strong>Act</strong> - the Polish Act of 19 August 2011 on payment services (Polish: Ustawa z dnia 19 sierpnia 2011 r. o usługach płatniczych).</li>
                <li><strong>AML Act </strong>&ndash; the Polish Act of 1 March 2018 on counteracting money laundering and terrorist financing (Polish: Ustawa z dnia 1 marca 2018 r. o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu).</li>
                <li><strong>Payment Order </strong>&ndash; a statement of the Customer containing an instruction to Fenige to execute the Transaction.</li>
              </ol>
            ),
          },
          {
            title: '2. PRELIMINARY PROVISIONS AND CONSENTS',
            body: (
              <ol>
                <li>These Terms of Services define the terms and conditions for the execution, by Fenige, of a single Transaction on behalf of the Customervia the Sales Service &ndash; depending on which of these types of Transactions is offered on the Sales Service.</li>
                <li>The Sales Service remains entirely the service of the Merchant. The Merchant is responsible for its availability, proper functioning and security, including the operation of the Customer&rsquo;s account service on the Sales Service &ndash; if such a service is offered by the Merchant.</li>
                <li>By accepting the Terms of Services, the Customer concludes the Single Transaction Agreement with Fenige on its basis, whereby the Customer undertakes to comply with all the provisions of the Terms of Services, as well as applicable law and to comply with the obligations provided for in the Terms of Services.</li>
                <li>Fenige provides the Transaction service on the basis of the Payment Order received only from the Customer on the Sales Service.</li>
                <li>When executing the Transaction, Fenige accepts Mastercard and Visa Payment Cards.</li>
                <li>The list of currencies in which it is possible to order the Transaction and the currencies into which the conversion of the Transaction amount may take place is available to the Customer on the Sales Service when submitting the Payment Order.</li>
                <li>The provision of Transaction services does not include the opening and maintenance by Fenige of a payment account for the Customer within the meaning of the Act, nor is it related to the opening and maintenance of a bank account within the meaning of the Polish Act of 23 April 1964 &ndash; Civil Code (Polish: Ustawa z dnia 23 kwietnia 1964 r. - Kodeks cywilny) and the Polish Act of 29 August 1997 &ndash; Banking law (Polish: Ustawa z dnia 29 sierpnia 1997 r. - Prawo bankowe) and the activities carried out by Fenige on the basis of the Terms of Services are not banking activities.</li>
                <li>The Customer agrees that Fenige will disclose, to the Merchant, information and data related to them, the Transactions ordered by them on the Sales Service and the complaints submitted by them, i.e. information and data protected by professional secrecy referred to in Art. 11 of the Act (payment secrecy). The Merchant processes this data as their controller in accordance with the applicable provisions on the protection of personal data.</li>
                <li>By accepting the Terms of Services, the Customer requests that the full performance of the Single Transaction Agreement, and thus the performance of the Transaction itself, take place before the statutory deadline for withdrawal from this type of agreement, e. the deadline of 14 days from the acceptance of the Terms of Services.</li>
              </ol>
            ),
          },
          {
            title: '3. EXECUTION OF PAYMENTS',
            body: (
              <ol>
                <li>Ordering each individual Transaction by the Customer through the Website requires them, in advance
                  <ol>
                    <li>to register and login to the Customer's account on the Sales Service on the terms set out in the Sales Service Terms of Services (if the Sales Service requires registration in order to order the execution of Transactions) and accept the content of the present Terms of Services, which means reading its content and committing to comply with its terms and conditions and is tantamount to concluding a Single Transaction Agreement,</li>
                  </ol>
                </li>
                <li>Execution of the Transaction requires the Customer to provide the following information and data:
                  <ol>
                    <li>the Customer&rsquo;s name and surname,</li>
                    <li>the Customer&rsquo;s e-mail address,</li>
                    <li>the BLIK code generated for the Customer,</li>
                    <li>number, expiry date and CVV/CVC of the Payment Card of the Customer whose account is to be debited,</li>
                    <li>code under the 3-D Secure Procedure (if it is required for the Transaction with a given Payment Card),</li>
                  </ol>
                </li>
                <li>In order to execute the Transaction the Customer undertakes to provide true, complete and up-to-date information and data. The Customer is fully responsible for providing incorrect data of the Merchant, as well as an incorrect amount of the Transaction.</li>
                <li>When executing the Transaction, Fenige independently or in cooperation with other entities verifies the Customer's identity and applies appropriate financial security measures.</li>
                <li>If the Transaction involves a currency conversion, before executing the Transaction, the Customer receives information about the actual exchange rate that will be applied by Fenige to the Transaction and the Transaction amount after currency conversion. The Customer shall bear the exchange costs added by Fenige to the exchange rate displayed to the Customer.</li>
                <li>Any refund of the Transaction amount may involve a currency conversion at a different exchange rate than the one applied when submitting the Payment Order.</li>
                <li>Acceptance of the Payment Order from a verified Customer for execution takes place after the Customer provides all the required information and data, clicks on the button on the Sales Service approving the submission of the Payment Order and passes the authentication procedure, and in particular the 3-D Secure Procedure (if required) &ndash; which is equivalent to the Customer&rsquo;s consent to perform the Transaction (authorization).</li>
                <li>Immediately after receiving the Payment Order, Fenige provides the Customer on the Sales Service or sends the following information to the e-mail address provided by the Customer when submitting the Payment Order with the following information:
                  <ol>
                    <li>enabling the Customer to identify the Transaction,</li>
                    <li>the amount of the Transaction in the currency used in the Payment Order,</li>
                    <li>the exchange rate applied to the respective Transaction by Fenige and the amount of the Transaction after currency conversion, if the Transaction involved currency conversion,</li>
                    <li>on the date of receipt of the Payment Order.</li>
                  </ol>
                </li>
                <li>The complete execution of the Payment Order begins on the day on which the Customer has made the funds for the execution of the Payment Order available to Fenige. However, if that day is not a Business Day, the Payment Order shall be deemed to have been received by Fenige on the first Business Day thereafter.</li>
                <li>Fenige will strive to achieve the crediting of the Transaction amount on the Merchant payment account as soon as possible, even within 30 minutes from the moment Fenige receives information from the Sales Service about the submission of a Payment Order, the maximum time for execution of a Transaction is, however, counted until the end of the next Business Day from the moment Fenige receives the Payment Order, i.e. from the date referred to in the paragraph 7 of this section.</li>
                <li>The Customer&rsquo;s Bank or the Merchant&rsquo;s Bank may block, stop or reject the Transaction for security reasons or for other reasons, such as lack of funds in the Customer&rsquo;s account. It is also possible that funds will be blocked in the result of an interrupted or failed Transaction. Fenige will not be responsible for the actions of the Banks in this regard. In the event that it proves impossible to transfer funds to the Merchant&rsquo;s Bank, unless other legal reasons prevent it, Fenige will immediately refund the amount of the Transaction to the Customer.</li>
                <li>Due to the complete execution of the Transaction at the request of the Customer who is a consumer before the statutory deadline for withdrawal from the Single Transaction Agreement, i.e. 14 days from the acceptance of the Terms of Services, the right to withdraw from the Single Transaction Agreement after the execution of the Transaction does no longer apply.</li>
                <li>The Customer may at any time withdraw the consent to execute the Transaction, but not later than until Fenige receives the Payment Order, at which point the Payment Order becomes irrevocable..</li>
              </ol>

            ),
          },
          {
            title: '4. REFUSAL OR SUSPENSION OF THE TRANSACTION',
            body: (
              <ol>
                <li>Fenige may refuse to execute the Transaction, suspend the execution of the Transaction or, if:</li>
                <li>The Customer violates the provisions of the Terms of Services,</li>
                <li>The Customer failed to provide full, complete, valid or true information and data required to perform the Transaction,</li>
                <li>no positive result of the 3-D Secure Process in relation to the Transaction (if required) was obtained,</li>
                <li>The Payment Card of the Customer prevents debiting or crediting the account with funds from the Transaction in accordance with the applicable regulations of the Payment Organisations, it is blocked, cancelled or invalid at the time of the Transaction or if BLIK will prevent the execution of the Transaction,</li>
                <li>The Bank of the Customer or the Bank of the Merchant or another provider maintaining a payment account for the Customer or the Merchant will refuse to execute the Transaction or these institutions will not respond to correct payment messages sent to them,</li>
                <li>this is justified for reasons related to the security of the Transaction, including if Fenige suspects that there is an unlawful use of the Transaction services or an attempt to initiate an unauthorized Transaction,</li>
                <li>Fenige suspects that the Payment Order does not comply with applicable laws, regulations of Payment Organisations or the Terms of Services,</li>
                <li>the risk level of the Transaction subject to the Payment Order, according to risk assessment carried out by Fenige or a third party, is deemed unacceptable,</li>
                <li>conditions to withhold or refuse to execute the Transaction under applicable laws related to anti-money laundering and combating the financing of terrorism are met,</li>
                <li>if Fenige receives such a request from a public authority, including law enforcement.</li>
                <li>Fenige shall not be held liable for damages incurred by the Customer due to non-performance or delay in the execution of the Transaction on the basis of one of the situations indicated above, unless Fenige's liability is provided for by mandatory provisions of law.</li>
                <li>In the event of refusal to execute the Transaction, after Fenige has already come into possession of the Customer&rsquo;s funds, Fenige shall immediately return to the Customer the amount of the Transaction to the payment account from which the Transaction was executed , except when this is unacceptable under generally applicable law.</li>
              </ol>
            ),
          },
          {
            title: '5. COMMISSION AND FEES',
            body: (
              <ol>
                <li>For the execution of Transaction Fenige does not collects any fees from the Client. The Client acknowledges that the Client may pay any additional fees to banks or other payment service providers, in accordance with the content of the contracts between the Customer and these entities (e.g. additional fee to the bank for payment card transactions), however, neither Fenige nor the Merchant is a party to these contracts and these are not fees paid to them. .</li>
              </ol>
            ),
          },
          {
            title: '6. LIABILITY',
            body: (
              <ol>
                <li>Fenige shall be liable to the Customer on the terms specified in the Act, excluding liability for lost profits.</li>
                <li>Fenige shall not be liable for non-performance or improper performance of an obligation resulting from an event of Force Majeure.</li>
                <li>Fenige is not a party and is not responsible for the issuance of the payment instrument in form of the Payment Card or BLIK .</li>
                <li>The Customer is responsible for the use of Fenige services under these Terms of Services in accordance with the provisions of generally applicable law. In particular, the Customer may not use the Transaction services to pay for goods or services that are illegal to trade or to carry out commercial transactions that violate the rights of third parties.</li>
              </ol>
            ),
          },
          {
            title: '7. COMPLAINTS',
            body: (
              <ol>
                <li>Fenige shall consider complaints submitted by Customer regarding Transactions, including identified unauthorized, improperly initiated, not executed or improperly executed Transactions.</li>
                <li>The Customer may file a complaint:
                  <ol>
                    <li>in writing &ndash; by mail to Fenige's address or serving it in person at Fenige's registered office,</li>
                    <li>in oral form - in person at the Fenige headquarters or by phone: +48 576 140 180,</li>
                    <li>in electronic form to the following e-mail address: <a href="mailto:complaints@fenige.pl">complaints@fenige.pl</a>.</li>
                  </ol>
                </li>
                <li>Complaints concerning unauthorized, improperly initiated, unexecuted or improperly executed Transactions should be submitted immediately after the occurrence of objectionable circumstances.</li>
                <li>A written complaint shall contain at least:
                  <ol>
                    <li>data enabling identification of the Customer (name and surname, correspondence address, date of submission of the Payment Order,),</li>
                    <li>description of the subject of the complaint,</li>
                    <li>determination of the request of Customer,</li>
                    <li>indication that the Customer wants the response to the complaint to be sent to their e-mail address &ndash; if it is the Customer's</li>
                  </ol>
                </li>
                <li>The complaint should be accompanied by copies of documents (e.g. confirmations of performance or refusal to perform the Transaction) to which the complaint relates.</li>
                <li>If the data or information provided in the complaint needs to be supplemented for the complaint to be considered, Fenige will ask the Customer to supplement it within the indicated scope and date. In this case, the Customer is obliged to cooperate with Fenige in order to clarify the circumstances of the event they are complaining about.</li>
                <li>Fenige provides a response to the complaint in paper form or, after agreement with the Customer, on another durable medium (e.g. in electronic form to the e-mail address from which the complaint was submitted).</li>
                <li>The response to the complaint is prepared and forwarded to the Customer without undue delay, but in no case later than within 15 Business Days from the date of receipt of the complaint. In particularly complex cases that render it impossible to consider the complaint and respond within the deadline, Fenige:
                  <ol>
                    <li>will explain the reasons for its inability to meet the deadline,</li>
                    <li>will indicate the circumstances that still have to be determined in order to consider the case,</li>
                    <li>will indicate the expected date of response, but in no case longer than 35 Business Days from the date of receipt of the complaint by it.</li>
                  </ol>
                </li>
                <li>To comply with the deadlines referred to in the paragraph 8 of the present section, it is enough to send answers before their expiry, and in the case of answers provided in writing &ndash; sending it in the post office of an operator designated within the meaning of the Polish Act of 23 November 2012 &ndash; Postal Law (Polish: Ustawa z dnia 23 listopada 2012 r. &ndash; Prawo pocztowe). In the event of failure to meet the deadlines and conditions under the paragraph 8 of this section, the complaint is deemed to be considered in accordance with the will of the Customer.</li>
                <li>In the event of acceptance of a complaint resulting in the obligation to return the amount of the Transaction, Fenige shall immediately return the Customer the amount of the Transaction including the Commission to the payment account from which the Transaction was executed, unless Fenige has reasonable and duly documented grounds to suspect fraud, and shall notify the law enforcement authorities in writing about it.</li>
                <li>If Fenige has not accepted the complaint of the Customer, who is a consumer, and the Customer does not agree with the decision of Fenige, the Customer may apply for mediation to one of the arbitration courts operating at the provincial inspectorates of the Trade Inspection. More information is available on the website of the Polish Office of Competition and Consumer Protection (Polish: Urząd Ochrony Konkurencji i Konsument&oacute;w): <a href="https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php">https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</a>. The Customer may also use an amicable method of resolving the dispute before the Arbitration Court at the Polish Financial Supervision Authority (Polish: Sąd Polubowny przy Komisji Nadzoru Finansowego).</li>
              </ol>
            ),
          },
          {
            title: '8. PROTECTION OF PERSONAL DATA',
            body: (
              <ol>
                <li>Providing personal data to the extent necessary, indicated in &sect; 3 of the Terms of Services, is voluntary, but is a prerequisite for the conclusion and performance of the Single Transaction Agreement.</li>
                <li>Fenige has the status of a domestic payment institution, which means that it is an obligated institution within the meaning of the AML Act, which as part of its activities is obliged to apply financial security measures to Customers (including identification and verification of their identity) and fulfil other obligations under the AML Act and in this respect also acts as the controller of their personal data.</li>
                <li>Fenige has appointed a Data Protection Officer, who can be contacted at the following e-mail address: <a href="mailto:iod@fenige.pl">iod@fenige.pl</a> in matters related to the processing of personal data by Fenige.</li>
                <li>Fenige processes personal data in order to:
                  <ol>
                    <li>provide payment services by executing Transactions and performing other activities provided for in the Terms of Services and in the Act (pursuant to Art. 6 sec. 1 (b) of GDPR),</li>
                    <li>the implementation of legal obligations in connection with the need to apply appropriate financial security measures and the implementation of other obligations under the AML Act, as well as other obligations specified in the provisions of generally applicable law, in particular the Act or accounting and tax acts (pursuant to Art. 6 sec. 1 (c) of the GDPR).</li>
                    <li>establishing, investigating or defending against claims &ndash; on the basis of Fenige's legitimate interest, which is the use of the above rights (Art. 6 sec. 1 (f) GDPR).</li>
                  </ol>
                </li>
                <li>Fenige is entitled to provide information about the Customers&rsquo; data and the Transactions they have commissioned to the relevant state and regulatory authorities, if such an obligation arises from the provisions of law, including in particular the Act and the AML Act.</li>
                <li>Personal data may be transferred to the Merchant, entities participating in the processing of the Transaction, such as Payment Organisations, the Customer&rsquo;s Bank and the Merchant&rsquo;s Bank, as well as IT service providers, telecommunications service providers and customer service providers in order to properly execute the Transaction and handle complaints.</li>
                <li>The Customers&rsquo; personal data may be subject to profiling within the meaning of the GDPR in order for Fenige to comply with the obligations imposed on it by the AML Act. In this case the automated processing applies to the person of the Customer and the Transaction ordered by them. The consequence of profiling may be refusal or suspension of the Transaction, blocking the possibility of ordering the execution of Transactions in the future and submitting appropriate notifications to state authorities, as provided for in the AML Act.</li>
                <li>Each and every person, whose personal data is processed by Fenige has the right to access the data concerning them, the right to rectify, correct, request limitation of processing, transfer, deletion, object to their further processing by Fenige, and in the event of consent to the processing of data, to its withdrawal at any time without affecting the lawfulness of the processing, which was carried out on the basis of consent prior to its withdrawal, by writing for this purpose to the following e-mail address: <a href="mailto:iod@fenige.pl">iod@fenige.pl</a>. However, the exercise of the above rights may not affect the rights and obligations of Fenige set out in the paragraphs 4 b) and c) of the present section.</li>
                <li>Personal data are processed for the period required to perform the Transaction and to consider possible complaint. In addition, personal data are processed to the extent necessary for accounting, tax purposes and for the purposes of preventing money laundering and terrorist financing for the period resulting from applicable law and to the extent necessary to establish, pursue or defend against claims for the period of limitation of claims under these Terms of Services and for the duration of pending proceedings.</li>
                <li>Any person, whose personal data is processed by Fenige has the right to lodge a complaint regarding the processing of their data by Fenige with the supervisory authority dealing with the protection of personal data - the President of the Polish Office for Personal Data Protection (Polish: Urząd Ochrony Danch Osobowych).</li>
                <li>Further information on the protection of personal data as well as on the rules for the use of cookies can be found in the Fenige privacy policy available at <a href="https://www.fenige.com">https://www.fenige.com</a>.</li>
              </ol>
            ),
          },
          {
            title: '9. SECURITY',
            body: (
              <ol>
                <li>First of all, the Customer should apply all security measures indicated to the in the payment card issue agreement and in the BLIK issue agreement by the issuers of these payment instruments, in order to safely store these payment instruments. The Customer should also comply with the safety notices and warnings issued by these publishers and provided to them. Notwithstanding the above, Fenige hereby notifies the Customer that in relation to the payment instruments in its possession, as well as personalized or individual passwords, codes, devices, information and data used to order Transactions, they must:
                  <ol>
                    <li>adequately protect them, including not disclose them to anyone or share Website account logging data or payment instruments used to order Transactions,</li>
                    <li>select a login password that is difficult to guess or crack,</li>
                    <li>use locks of electronic devices used to order Transactions and appropriate antivirus software updated on an ongoing basis,</li>
                    <li>not provide anyone with devices or other materials with saved or visible passwords and codes or other information used to pass the authentication procedure, which could result in an unauthorized Transaction, as well as carefully protect such materials and devices, including not using programs to automatically save passwords on them,</li>
                    <li>protect personal data, including documents containing them, from access by unauthorized persons, so that no one can impersonate the Customer during the verification procedure.</li>
                  </ol>
                </li>
                <li>The Customer undertakes to immediately notify Fenige of the loss, theft, misappropriation or unauthorized access to or use of the payment instrument, as well as the password to the account on the Website, one-time password, telephone and any other data used to verify the Customer and authenticate the Transactions ordered by them, which creates the risk of the Transaction being carried out by an unauthorized person using them. The Customer should submit a notification:
                  <ol>
                    <li>by e-mail to the e-mail address indicated on the <a href="https://www.fenige.com">https://www.fenige.com</a> website in the tab: contact,</li>
                    <li>by phone at the phone number indicated on the <a href="https://www.fenige.com">https://www.fenige.com</a> website in the tab: contact.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
          {
            title: '10. CHANGE IN THE CONTENT OF THE TERMS OF SERVICES',
            body: (
                <ol>
                  <li>The present Terms of Services may be amended by Fenige unilaterally at any time.</li>
                  <li>The current content of the Terms of Services is available on the Sales Service.</li>
                </ol>
            ),
          },
          {
            title: '11. TERM THE CUSTOMER IS BOUND BY THE SINGLE TRANSACTION AGREEMENT',
            body: (
                <ol>
                  <span>The Single Transaction Agreement in the version approved by the Customer binds the parties only in relation to one, specific Transaction for which it was concluded (which should be understood as the Customer&rsquo;s acceptance of the Terms of Services immediately before ordering this Transaction, if the Transaction was subsequently ordered before the expiry of the session on the device) and for the time of execution of this Transaction, related to its possible return, as well as for the time of considering complaints and disputes in relation to it.</span>
                </ol>
            ),
          },
          {
            title: '12. FINAL PROVISIONS',
            body: (
              <ol>
                <li>These Terms of Services shall be governed by the laws of Poland.&nbsp; In matters not covered by the Terms of Services, generally applicable provisions of Polish law, and in particular the Act and the AML Act, shall apply.</li>
                <li>In the event that individual provisions of the Terms of Services are found to be invalid or ineffective in whole or in part for any reason, the remaining provisions of the Terms of Services shall remain in force.</li>
                <li>Subject to mandatory provisions, the court competent to deal with disputes arising in connection with the Terms of Services is:
                  <ol>
                    <li>in cases with Customers, who are not consumers, the court competent for the registered office of Fenige,</li>
                    <li>in cases with Customers, who are consumers, the court competent for the place of residence of the consumer, and in the case where the action is directed against Fenige also the court competent for the registered office of Fenige.</li>
                  </ol>
                </li>
                <li>If the Customer is not a consumer, the provisions of the Act, the exclusion of which in relations with entities other than consumers is permissible, shall not apply to it.</li>
                <li>All questions and notifications regarding the present Terms of Services and the provision of services on their basis should be directed to the Fenige customer service office:
                  <ol>
                    <li>by e-mail to the e-mail address indicated on the <a href="https://www.fenige.com">https://www.fenige.com</a> website in the tab: contact,</li>
                    <li>by phone at the phone number indicated on the <a href="https://www.fenige.com">https://www.fenige.com</a> website in the tab: contact,</li>
                    <li>by letter to the address of Fenige's registered office.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
        ]}
      />
    </section>
  );
};

export default TermsOfAcquiringServicesEN;
