import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const PrivacyPolicyPL = () => {
  const { t } = useTranslation();

  return (
    <section id="privacy-policy">
      <TermsAndPrivacyTemplate
        title={t("POLITYKA PRYWATNOŚCI")}
        titleVersion={t("v.1.1.")}
        titleUpdated={t("aktualizacja: 1 lutego 2023 r.")}
        items={[
          {
            title: t("І. POSTANOWIENIA OGÓLNE"),
            body: (
              <ol>
                <li>Prawidłowe zarządzanie Danymi Osobowymi jest dla nas niezwykle ważne. Dlatego traktujemy Twoją prywatność poważnie podczas przetwarzania i ochrony Twoich Danych Osobowych. Odbywa się to zgodnie z przepisami określonymi w obowiązujących przepisach o ochronie prywatności (w tym Ogólnym Rozporządzeniu o Ochronie Danych). Niniejsza Polityka Prywatności dotyczy Aplikacji Orovera dostępnej w Sklepie Aplikacji. Jeśli nie zgadzasz się z postanowieniami niniejszej Polityki Prywatności, musisz powstrzymać się od korzystania z Aplikacji. Rejestrując się w usługach w Aplikacji i zaznaczając pole „Zgadzam się z postanowieniami Regulaminu i Polityki Prywatności” (lub podobnym) wyrażasz nam oraz naszemu dostawcy KYC świadomą zgodę na przetwarzanie i wykorzystywanie Twoich Danych Osobowych zgodnie z postanowieniami niniejszej Polityki Prywatności. Nie będziesz mógł przejść procedury rejestracji i korzystać z Aplikacji w pełnym zakresie, dopóki zgoda nie zostanie udzielona, a Regulamin oraz Polityka Prywatności nie zostaną przeczytane, co będzie technicznie zapewnione.</li>
                <li>Dbamy o to, aby zbierać i wykorzystywać Dane Osobowe tylko w celach niezbędnych do świadczenia naszych Usług.</li>
                <li><strong>Podstawy prawne przetwarzania:</strong>
                  <ol>
                    <li><strong>Istnienie</strong> umowy między Użytkownikiem a Orovera: Rejestrując się w Aplikacji, Ty i Firma zawieracie umowę na świadczenie Usług, zgodnie z postanowieniami Regulaminu. Aby zapewnić Ci Usługi w całości, a także ich poszczególne części, musimy zbierać i przetwarzać pewne Dane Osobowe na Twój temat;</li>
                    <li><strong>Prawnie uzasadniony interes:</strong> Firma korzysta z prawnie uzasadnionego interesu w przetwarzaniu Twoich Danych Osobowych, na przykład gdy Firma przetwarza Twoje dane w celu naprawy błędów w Aplikacji i zapewnienia jej płynnego działania, poprawy funkcjonowania naszych Usług, rozwijania i wprowadzania nowych funkcji i usług, zapewnienia bezpieczeństwa Użytkownika podczas korzystania z Aplikacji, a także zapewnienia bezpiecznej pracy Usług, przekazywania danych w ramach struktury organizacyjnej Firmy itp. Nie będziemy używać prawnie uzasadnionego interesu jako podstawy przetwarzania danych Użytkownika, jeśli prawa, wolności i interesy użytkownika przeważają nad interesem Firmy lub osoby trzeciej;</li>
                    <li><strong>Wymogi ustawowe:</strong> Firma może przetwarzać Dane Osobowe użytkowników, jeśli jest to wymagane przez prawo mające zastosowanie do Orovera. Na przykład Firma może przekazywać Dane Osobowe użytkowników na żądanie sądów lub innych uprawnionych organów;</li>
                    <li><strong>Zgoda:</strong> Firma polega wyłącznie na Twojej zgodzie przy korzystaniu z Danych Osobowych w celu rejestracji w Aplikacji, korzystania z Aplikacji, oferowanych Usług itp.</li>
                  </ol>
                </li>
                <li><strong>Firma nie będzie zbierać wrażliwych Danych Osobowych.</strong> W przypadku, gdyby w innych sytuacjach zaistniała potrzeba zbierania wrażliwych Danych Osobowych, Firma zrobi to wyłącznie za wyraźną zgodą osoby, której dane dotyczą, która może być wycofana w dowolnym momencie.</li>
                <li>Nie przekazujemy Twoich Danych Osobowych osobom trzecim, z wyjątkiem okoliczności wymienionych w niniejszej Polityce Prywatności lub Regulaminie.</li>
                <li>Niniejsza Polityka Prywatności podlega Regulaminowi (który można znaleźć w Aplikacji). Twoje korzystanie z Aplikacji oraz wszelkie Dane Osobowe, które podajesz, pozostają podległe warunkom niniejszej Polityki Prywatności oraz naszego Regulaminu.</li>
                <li>W przypadku znaczącej aktualizacji niniejszej Polityki Prywatności, poprosimy Cię o ponowne wyrażenie zgody na zaktualizowaną Politykę Prywatności, zanim jakiekolwiek zmiany wejdą w życie. W przypadku, gdyby znaczące zmiany były dla Ciebie nieakceptowalne, prosimy o natychmiastowe zaprzestanie korzystania z Naszej Aplikacji. Poinformujemy Cię o drobnych zmianach, wysyłając powiadomienie w Aplikacji. Wszystkie zmiany będą wskazywać datę, od której zaczną obowiązywać.</li>
                <li>Definicje użyte w niniejszej Polityce Prywatności i oznaczone wielką literą, o ile nie są specjalnie wprowadzone w niniejszej Polityce Prywatności, mają znaczenie nadane im w Regulaminie lub, jeśli definicja nie jest dostępna w Regulaminie, mają znaczenie określone w Ogólnym Rozporządzeniu o Ochronie Danych (GDPR).</li>
              </ol>
            ),
          },
          {
            title: t("ІІ. O NAS"),
            body: (
              <ol>
                <li>Podmiotem prawnym, który działa jako Firma Przetwarzająca Dane Osobowe, jest <strong>OROVERA S.A.</strong>, spółka akcyjna z siedzibą w Lublinie (20-103), ul. Rusałka 17a, zarejestrowana w Krajowym Rejestrze Sądowym pod numerem KRS: 0001020988.</li>
                <li>Należy zauważyć, że NIE przetwarzamy, nie przechowujemy ani nie wchodzimy w interakcje z informacjami zbieranymi do celów KYC (podpunkty (b) i (c) punktu 3.3.1 niniejszej Polityki Prywatności). Dane te są przetwarzane przez odpowiednio wyznaczoną i certyfikowaną firmę <strong>DIGITAL GATEWAYS</strong> Sp. z o.o. z zarejestrowanym adresem przy ul. Rondo I. Daszyńskiego 1, 00-843 Warszawa, pod numerem KRS: 0000790370, NIP: 5272896326 (określaną również jako „dostawca KYC”).</li>
              </ol>
            ),
          },
          {
            title: t("ІІІ. JAKIE DANE ZBIERAMY O TOBIE"),
            body: (
              <ol>
                <li>«Dane osobowe» (również nazywane «DO», «Informacje osobiste») oznaczają wszelkie informacje, które identyfikują cię, takie jak twój adres e-mail, login (nazwa użytkownika), zapytania do naszego zespołu wsparcia itp. Jeśli nie można cię zidentyfikować (na przykład, gdy dane osobowe zostały zanonimizowane lub zawierają jedynie informacje statystyczne), niniejsza Polityka nie znajduje zastosowania.</li>
                <li>Zawsze, gdy korzystasz z naszej Aplikacji, zbieramy i przetwarzamy Twoje dane osobowe.</li>
                <li>Dzielimy informacje, które zbieramy o Tobie, w następujący sposób:</li>
                <li><strong>Informacje, które nam samodzielnie przekazujesz:</strong>
                  <ol>
                    <li>Podczas rejestracji konta i korzystania z naszych Usług (np. po inicjacji Instrukcji Płatności), przekazujesz nam swoje dane osobowe, które obejmują następujące informacje:
                      <ol>
                        <li>Imię i nazwisko;</li>
                        <li>Numer telefonu kontaktowego;</li>
                        <li>Adres e-mail (jeśli Instrukcja Płatności jest przetwarzana);</li>
                        <li>Kwotę transakcji (jeśli Instrukcja Płatności jest przetwarzana).</li>
                      </ol>
                    </li>
                    <li>Podczas przeprowadzania procedury KYC, jesteś proszony o wpisanie następujących informacji za pomocą naszego formularza (dane osobowe są przetwarzane i przechowywane przez naszego dostawcę KYC, a my nie działamy jako firma przetwarzająca te dane):
                      <ol>
                        <li>Osobisty identyfikator (ID);</li>
                        <li>Adres zamieszkania.</li>
                      </ol>
                    </li>
                    <li>W pewnych przypadkach (gdy wymagana jest dodatkowa weryfikacja przez bank lub organ zgodności zgodnie z przepisami rządowymi, AML lub zgodnie z obowiązującym prawem) dostawca KYC może wymagać od Ciebie przesłania dodatkowych informacji i dokumentów, mianowicie:
                      <ol>
                        <li>Potwierdzenie tożsamości (takie jak prawo jazdy lub inny dokument);</li>
                        <li>Dowód dokumentu potwierdzającego adres zamieszkania (np. rachunek za media lub wyciąg bankowy);</li>
                        <li>Inne, jeśli jest to wymagane.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>Nie będziesz mógł pełni korzystać z Aplikacji, dopóki nie przejdziesz procedury KYC zgodnie z postanowieniami niniejszej Polityki Prywatności.</li>
                <li>Powyższe informacje (c) i szczegóły są udostępniane tylko na specjalne osobiste żądanie Użytkownika i określone w odpowiednich powiadomieniach/e-mailach lub innych środkach.</li>
                <li>Jedynymi zezwoleniami, o które poprosimy, będą dostęp do klawiatury twojego urządzenia oraz wysyłanie ważnych powiadomień.
                  <ol>
                    <li><strong>Informacje, które zbieramy automatycznie (mogą się stosować nawet jeśli nie rejestrujesz konta użytkownika ani nie korzystasz z oferowanych Usług):</strong>
                      <ol>
                        <li>Zbieramy ogólne anonimowe informacje analityczne dotyczące korzystania z Usług w celu ich optymalizacji.</li>
                      </ol>
                    </li>
                    <li><strong>Informacje, które otrzymujemy od osób trzecich.</strong> Nie wykluczamy możliwości zbierania Twoich danych osobowych z innych źródeł, na przykład z publicznie dostępnych źródeł. Wykorzystujemy te informacje dodatkowo do już zebranych danych osobowych w celu lepszego dostosowania i poprawy naszych usług dla Ciebie oraz w celu weryfikacji dostarczonych nam przez Ciebie danych osobowych.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
          {
            title: t("IV. JAK WYKORZYSTUJEMY TWOJE DANE OSOBOWE"),
            body: (
              <ol>
                <li>Możemy dostarczać, personalizować i udoskonalać naszą Aplikację, łącząc i wykorzystując informacje, które posiadamy o Tobie (w tym informacje otrzymywane na naszej Aplikacji oraz poza nią), aby zrozumieć, w jaki sposób korzystasz z naszej Aplikacji, Usług oraz jak się z nią interaktywujesz, a także z kim i z czym jesteś połączony lub co Cię interesuje. Możemy również używać posiadanych przez nas informacji o Tobie w celach następujących:
                  <ol>
                    <li>Świadczenie, utrzymanie, poprawa i rozwijanie odpowiednich funkcji, treści i Usług Aplikacji;</li>
                    <li>Realizacja Twoich żądań (wsparcie) i gdy jesteś do tego upoważniony/a;</li>
                    <li>Badania i rozwój nowych usług;</li>
                    <li>Wykrywanie i obrona przed oszustwami, nadużyciami lub działaniami niezgodnymi z prawem;</li>
                    <li>Wysyłanie Ci technicznych powiadomień, aktualizacji, alertów bezpieczeństwa, faktur oraz innych wiadomości wsparcia i administracyjnych;</li>
                    <li>Świadczenie obsługi klienta;</li>
                    <li>Komunikacja z Tobą na temat produktów, usług, ofert;</li>
                    <li>Wysyłanie różnych promocji, informacji o wydarzeniach oraz dostarczanie innych wiadomości i informacji, które uważamy, że mogą Cię zainteresować.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
          {
            title: t("V. JAK PRZETWARZAMY TWOJE DANE OSOBOWE"),
            body: (
              <ol>
                <li>Nasza Polityka Prywatności dotyczy tylko Usług oferowanych w Aplikacji. Nasza Aplikacja czasami może zawierać odnośniki do innych stron internetowych i źródeł. Nie kontrolujemy żadnej strony internetowej, do której mogą prowadzić odnośniki zamieszczone na naszej Stronie. Nie ponosimy odpowiedzialności za praktyki prywatności ani treści takich stron.</li>
                <li><strong>Nie przekazujemy Twoich danych osobowych osobom trzecim, z wyjątkiem przypadków wymienionych w niniejszej Polityce Prywatności, które są następujące:</strong>
                  <ol>
                    <li><strong>Z naszymi partnerami.</strong> Możemy udostępniać informacje naszym partnerom, dostawcom, konsultantom i innym dostawcom usług (nie dotyczy to reklamodawców i partnerów reklamowych), którzy potrzebują dostępu do takich informacji do wykonywania pracy dla nas. Wykorzystanie danych osobowych przez partnerów podlegać będzie odpowiednim środkom ochrony poufności i bezpieczeństwa.
                      <ol>
                        <li>Usługi płatnicze są świadczone przez <strong>Quicko Sp. z o.o.</strong> (https://www.quicko.pl/quicko-files) oraz ich powiązane podmioty, poddane ich politykom prywatności i dodatkowym umowom.</li>
                      </ol>
                    </li>
                    <li><strong>Dla celów zgodności z prawem.</strong> Możemy udostępniać informacje w odpowiedzi na prośbę o dane, jeśli wierzymy, że ujawnienie jest zgodne z obowiązującym prawem, przepisami, procesem sądowym lub żądaniem rządowym, w tym, lecz nie tylko, spełniając wymagania dotyczące bezpieczeństwa narodowego lub wymogi organów ścigania. W miarę możliwości, postaramy się zapewnić Ci wcześniejsze powiadomienie przed ujawnieniem Twoich informacji w odpowiedzi na takie żądanie.</li>
                    <li><strong>W sytuacji nagłej.</strong> Możemy udostępniać informacje, jeśli uważamy, że jest to konieczne w celu zapobieżenia nieuchronnemu i poważnemu uszkodzeniu cielesnemu osoby.</li>
                    <li><strong>Do egzekwowania naszych zasad i praw.</strong> Możemy udostępniać informacje, jeśli uważamy, że Twoje działania są niezgodne z naszymi umowami użytkownika, zasadami lub innymi politykami Orovera, lub w celu ochrony praw, własności i bezpieczeństwa naszych oraz innych osób.</li>
                    <li><strong>Z naszymi afiliatami.</strong> Możemy udostępniać informacje pomiędzy i wśród firmy oraz naszych właścicieli, afiliatów, spółek zależnych i innych firm pod wspólną kontrolą i własnością.</li>
                    <li><strong>Za Twoją zgodą.</strong> Możemy udostępniać informacje o Tobie za Twoją zgodą lub według Twojego uznania.</li>
                  </ol>
                </li>
                <li>NIE PRZECHOWUJEMY TWOICH DANYCH KARTY KREDYTOWEJ I INFORMACJI PŁATNICZYCH; SĄ ONE PRZEKAZYWANE BEZPOŚREDNIO DO NASZEGO ZGODNEGO Z PCI-DCC PROCEDURAMI FACYLITACJI PŁATNOŚCI.</li>
                <li>Inne informacje, które nie identyfikują Cię osobiście jako jednostki, są zbierane przez Firmę (takie jak na przykład wzorce użytkowania) i są wyłączną własnością naszą. Możemy używać tych informacji w taki sposób, jak uznajemy za stosowne.</li>
                <li>Możemy udostępniać określone zanonimizowane dane, które nie identyfikują osoby fizycznej, osobom trzecim, takie jak liczba zarejestrowanych użytkowników, wolumen i wzorzec ruchu w Aplikacji itp. Te informacje nie będą w żaden sposób identyfikować Ciebie jako jednostki.</li>
                <li>Jak wspomniano powyżej, gdy wysyłasz nam wiadomości, możemy je przechowywać w celu administrowania Twoimi zapytaniami i poprawy naszych usług. Nie przekazujemy informacji z takich wiadomości osobom trzecim.</li>
                <li>W innych przypadkach nie udostępniamy Twoich danych osobowych, ale przechowujemy je zaszyfrowane na naszych serwerach. Aby uzyskać więcej szczegółów na ten temat, zapoznaj się z sekcją „Bezpieczeństwo Twoich Danych Osobowych”.</li>
              </ol>
            ),
          },
          {
            title: t("VI. OKRES PRZECHOWYWANIA DANYCH"),
            body: (
              <ol>
                <li>Zachowujemy Twoje dane osobowe w celu spełnienia naszych obowiązków prawnych, operacyjnych, umownych i regulacyjnych. Wymagania dotyczące przechowywania danych mogą się różnić w zależności od rodzaju rekordów, dlatego gwarantujemy, że w żadnym przypadku nie będziemy przechowywać Twoich danych osobowych dłużej niż jest to wymagane. Jeśli chciałbyś/chciałabyś dowiedzieć się więcej na temat okresu przechowywania konkretnych danych, skontaktuj się z nami pod adresem info@orovera.com.</li>
              </ol>
            ),
          },
          {
            title: t("VII. BEZPIECZEŃSTWO TWOICH DANYCH OSOBOWYCH"),
            body: (
              <ol>
                <li>Zapewniamy następujące środki bezpieczeństwa w celu ochrony Twoich danych osobowych:
                  <ol>
                    <li>Ocena skuteczności stosowanych środków bezpieczeństwa przed uruchomieniem Aplikacji i jej aktualizacji;</li>
                    <li>Ustanowienie zasad dostępu do danych osobowych przetwarzanych przez Stronę internetową oraz zapewnienie rejestracji i rejestrowania wszystkich działań wykonywanych z danymi osobowymi w systemach informacyjnych Aplikacji;</li>
                    <li>Wykrywanie przypadków nieautoryzowanego dostępu do danych osobowych i podejmowanie odpowiednich środków reakcji;</li>
                    <li>Przywracanie danych osobowych, które zostały zmodyfikowane lub zniszczone z powodu nieautoryzowanego dostępu do nich (w ramach technicznych możliwości Firmy).</li>
                  </ol>
                </li>
                <li><strong>TWOJE DANE OSOBOWE SĄ PRZETWARZANE I PRZECHOWYWANE NA SERWERACH W RÓŻNYCH JURYSDYKCJACH (KRAJE UE), GDZIE ZNAJDUJĄ SIĘ NASZE OBIEKTY I/LUB DOSTAWCY USŁUG. ZAZNACZAJĄC ODPOWIEDNIĄ KRATKĘ WIDOCZNĄ NA STRONIE INTERNETOWEJ W TRAKCIE PROCEDURY REJESTRACYJNEJ, ZGADZASZ SIĘ NA TAKIE PRZENOSZENIE, PRZECHOWYWANIE LUB PRZETWARZANIE. FIRMA PODJĘŁA WSZYSTKIE NIEZBĘDNE KROKI, ABY ZAPEWNIAĆ, ŻE TWOJE DANE OSOBOWE SĄ TRAKTOWANE BEZPIECZNIE I ZGODNIE Z NINIEJSZĄ POLITYKĄ.</strong> Niektóre zabezpieczenia, których używamy, to zapory ogniowe i szyfrowanie danych, kontrola dostępu fizycznego do naszych centrów danych oraz kontrole autoryzacji dostępu do informacji. Firma upoważnia także dostęp do danych osobowych tylko dla pracowników lub kontrahentów, którzy potrzebują ich do wykonywania swoich obowiązków służbowych lub usługowych. Wszystkie nasze zabezpieczenia fizyczne, elektroniczne i proceduralne są zaprojektowane zgodnie z obowiązującymi przepisami prawa. Okresowo dane osobowe mogą być również przechowywane w innych lokalizacjach, i w takich przypadkach Firma zapewni, że dane osobowe będą przechowywane i przetwarzane z odpowiednim poziomem staranności i bezpieczeństwa.</li>
              </ol>
            ),
          },
          {
            title: t("VIII. TWOJE PRAWA"),
            body: (
              <ol>
                <li>Jeśli jesteś mieszkańcem Unii Europejskiej, masz określone prawa ochrony danych zgodnie z RODO:
                  <ol>
                    <li><strong>Masz prawo dostępu i zmiany</strong> pewnych rodzajów danych osobowych za pośrednictwem Aplikacji bez konieczności kontaktowania się z Firmą (to postanowienie dotyczy również mieszkańców spoza UE);</li>
                    <li><strong>Możesz w każdej chwili usunąć kilka rodzajów informacji o swoim koncie, które dostarczyłeś/aś, z odpowiedniej strony.</strong> Jednakże możemy zachować pewne Twoje dane osobowe zgodnie z wymogami prawa lub z prawnych celów biznesowych po usunięciu Twojego konta;</li>
                    <li>Masz prawo otrzymać informacje dotyczące celów zbierania danych osobowych, informacje o osobach trzecich, którym przekazujemy Twoje dane osobowe, informacje o środkach zabezpieczających stosowanych do bezpiecznego przechowywania Twoich danych osobowych oraz okres przechowywania danych osobowych;</li>
                    <li><strong>Masz również prawo żądania całkowitego usunięcia danych osobowych dotyczących Ciebie, wysyłając nam żądanie za pośrednictwem wsparcia [info@orovera.com].</strong> Realizujemy Twoje żądanie w odpowiednim terminie. Jeśli nie ma innej prawnie uzasadnionej podstawy do przechowywania i przetwarzania Twoich danych osobowych, zgodnie z obowiązującym prawem, Firma usunie takie informacje. Jeśli istnieje inna podstawa prawna dla takiego przetwarzania, Firma poinformuje Cię w odpowiednim terminie (to postanowienie dotyczy również mieszkańców spoza UE);</li>
                    <li><strong>Masz również prawo żądania ograniczenia przechowywania i przetwarzania danych osobowych</strong>, jeśli pojawiły się odpowiednie przesłanki prawne lub są przewidziane w obowiązującym prawie;</li>
                    <li>Masz prawo sprzeciwić się przetwarzaniu danych osobowych dotyczących Ciebie w określonych okolicznościach, które są przewidziane w obowiązującym prawie. W przypadku takiego sprzeciwu Firma powstrzyma się od przetwarzania Twoich danych osobowych, jeśli nie będzie innej prawnej podstawy do kontynuowania przetwarzania;</li>
                    <li>Firma bardzo poważnie traktuje Twoje prawa. Jednakże, jeśli uważasz, że nie rozpatrzono Twoich skarg należycie, masz prawo złożyć skargę do odpowiednich organów ochrony danych osobowych;</li>
                    <li><strong>Masz prawo zażądać od Firmy udostępnienia strukturalnego, listowego zestawienia wcześniej dostarczonych danych osobowych</strong> (oraz obecnie przetwarzanych przez Firmę). Możesz również poprosić nas o przesłanie Twoich danych osobowych do wcześniej wybranego przez Ciebie podmiotu trzeciego.</li>
                  </ol>
                </li>
                <li>Aby wysłać żądanie usunięcia, poprawy lub przekazania danych osobowych, skontaktuj się z działem wsparcia za pośrednictwem info@orovera.com.</li>
              </ol>
            ),
          },
          {
            title: t("IX. TRANSFER DANYCH"),
            body: (
              <ol>
                <li>Przy transferze danych osobowych poza Europejski Obszar Gospodarczy lub EFTA zapewniamy, że zapewniono adekwatny poziom bezpieczeństwa danych osobowych. Podczas takiego transferu zapewniamy również stosowanie adekwatnego poziomu ochrony Twoich praw, na podstawie analizy adekwatności przepisów dotyczących ochrony danych osobowych w państwie trzecim, na podstawie zobowiązań umownych, przyjętych przez odbiorcę danych osobowych.</li>
              </ol>
            ),
          },
          {
            title: t("X. POWIADOMIENIE"),
            body: (
              <ol>
                <li>Jeśli Firma zostanie poinformowana o naruszeniu systemów bezpieczeństwa, podejmiemy próbę elektronicznego powiadomienia Ciebie, abyś mógł/mogła podjąć odpowiednie kroki ochronne. Firma może umieścić powiadomienie na naszych stronach internetowych lub w Aplikacji, jeśli wystąpi naruszenie bezpieczeństwa.</li>
                <li>Gdy naruszenie danych osobowych jest prawdopodobne, że spowoduje wysokie ryzyko naruszenia praw i wolności użytkowników, Firma powiadomi Cię tak szybko, jak to możliwe.</li>
                <li>W przypadku przejęcia przez firmę przez trzecią stronę lub połączenia z nią, zastrzegamy sobie prawo, w każdym z tych przypadków, do transferu lub przypisania informacji, które zebraliśmy od naszych użytkowników w ramach takiego połączenia, przejęcia, sprzedaży lub innych zmian kontroli. Jeśli będziemy zaangażowani w połączenie, przejęcie lub jakąkolwiek formę sprzedaży części lub wszystkich swoich aktywów, powiadomimy użytkowników przed transferem danych osobowych i przedmiotem stania się odmiennych zasad ochrony prywatności. W mało prawdopodobnym przypadku naszego bankructwa, niewypłacalności, reorganizacji, zarządu czasowego lub cesji na rzecz wierzycieli, lub stosowania praw lub zasad równościowych dotyczących praw wierzycieli ogólnie, może okazać się, że nie będziemy mogli kontrolować sposobu, w jaki Twoje dane osobowe są traktowane, transferowane lub używane.</li>
              </ol>
            ),
          },
          {
            title: t("XI. ZBIERANIE DANYCH OSOBOWYCH OSÓB NIEPEŁNOLETNICH"),
            body: (
              <ol>
                <li><strong>KORZYSTAJĄC Z APLIKACJI I USŁUG ONLINE ŚWIADCZONYCH PRZEZ FIRMĘ, STWIERDZASZ TUTAJ, ŻE MASZ PONAD 18 LAT LUB SPEŁNIASZ INNE WIEKOWE WYMAGANIA OKREŚLONE W OBOWIĄZUJĄCYCH PRZEPISACH, UMOŻLIWIAJĄCE CI ZAWARCIE NINIEJSZEJ UMOWY. UŻYTKOWNICY PONIŻEJ 18 LAT MOGĄ KORZYSTAĆ Z USŁUG TYLKO Z POSZANOWANIEM PRZEPISÓW OKREŚLONYCH W OBOWIĄZUJĄCYM PRAWIE.</strong></li>
                <li><strong>PONOSISZ CAŁKOWITĄ ODPOWIEDZIALNOŚĆ ZA WSZELKIE KONSEKWENCJE ZWIĄZANE Z UŻYCIEM STRONY INTERNETOWEJ LUB ŚWIADCZONYCH USŁUG BEZ SPEŁNIANIA WYMAGAŃ OKREŚLONYCH W REGULAMINIE LUB POLITYCE PRYWATNOŚCI.</strong></li>
                <li><strong>PERSONEL FIRMY NIE ZBIERA I NIE PRZECHOWUJE ŚWIADOMIE DANYCH WRAŻLIWYCH ANI NIEWRAŻLIWYCH INFORMACJI O OSOBACH PONIŻEJ 14 LAT PRZEZ NASZE USŁUGI I ŻADNA CZĘŚĆ NASZYCH USŁUG NIE JEST SKIEROWANA DO OSÓB PONIŻEJ 14. JEŚLI MASZ MNIEJ NIŻ 14 LAT, PROSZĘ NIE UŻYWAJ USŁUG FIRMY W JAKIKOLWIEK SPOSÓB. JEŚLI FIRMA DOWIE SIĘ, ŻE DANE OSOBOWE OSÓB PONIŻEJ 14 LAT ZOSTAŁY ZBIERANE W USŁUGACH BEZ ZWERYFIKOWANEJ ZGODY RODZICÓW, FIRMA PODEJMIE ODPOWIEDNIE KROKI W CELU USUNIĘCIA TYCH INFORMACJI I ZAWIESZENIA TWOJEGO KONTA.</strong></li>
                <li>DODATKOWO, MUSISZ MIEĆ WIEK WYMAGANY PRZEZ PRAWO TWOJEGO KRAJU, ABY STWORZYĆ KONTO LUB W INNY SPOSÓB KORZYSTAĆ Z USŁUG, LUB MUSIMY UZYSKAĆ WERYFIKOWALNĄ ZGODĘ TWOJEGO RODZICA LUB PRAWNEGO OPIEKUNA, ZGODNIE Z POSTANOWIENIAMI REGULAMINU UŻYTKOWANIA. TYLKO OSOBY POWYŻEJ 18 LAT MOGĄ KORZYSTAĆ Z PEŁNEJ FUNKCJONALNOŚCI APLIKACJI.</li>
              </ol>
            ),
          },
          {
            title: t("XII. XII. SKONTAKTUJ SIĘ Z NAMI"),
            body: (
              <ol>
                <li>Jeśli masz jakiekolwiek pytania dotyczące niniejszej Polityki Prywatności, prosimy o kontakt z nami lub napisanie do nas pod adresem e-mail: info@orovera.com.</li>
              </ol>
            ),
          },
        ]}
      />
    </section>
  );
};

export default PrivacyPolicyPL;
