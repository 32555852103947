import { ReactNode } from "react";

interface HeadingProps {
  size: "h1" | "h2" | "h3" | "h4";
  children: ReactNode;
}

const Heading = ({ size, children }: HeadingProps) => {
  switch (size) {
    case "h1":
      return <h1 className="first-heading">{children}</h1>;
    case "h2":
      return <h2 className="second-heading">{children}</h2>;
    case "h3":
      return <h3 className="third-heading">{children}</h3>;
    case "h4":
      return <h4 className="fourth-heading">{children}</h4>;
  }
};

export default Heading;
