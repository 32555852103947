import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <section id="privacy-policy">
      <TermsAndPrivacyTemplate
        title={t("privacy-policy.Title")}
        titleVersion={t("privacy-policy.titleVersion")}
        titleUpdated={t("privacy-policy.titleUpdated")}
        items={[
          {
            title: t("privacy-policy.sections.1.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.1.Body"}>
                <ol>
                  <li>The proper handling of Personal Data is extremely important to Us. We therefore take your privacy seriously when processing and protecting your Personal Data. This is done in accordance with provisions set out in the applicable privacy laws (including the General Data Protection Regulation). This Privacy Policy applies to an Application Orovera Available in App Marketplaces. If you do not agree with provisions of this Privacy Policy, you have to restrain from using the Application. By registering for services in the Application and checking the box “I agree with provisions of Terms and Privacy Policy” (or similar) you provide Us and our KYC-Provider with an informed consent to process and use your Personal Data as specified in this Privacy Policy. You will not be able to pass the registration procedure and use the Application in full until the consent is provided, and Terms and Privacy Policy are read which will be technically ensured.</li>
                  <li>We take great care to limit the Personal Data We collect and use only for the purposes, which are necessary for providing our Services.</li>
                  <li><strong>Legal grounds for processing.</strong>
                    <ol>
                      <li><strong>Existence</strong> of a contract between the User and Orovera: By registering in App, you and Company enter into a contract for the provision of Services, as is stipulated in the Terms. In order to provide you with Services as a whole, as well as with a separate part of it, We have to collect and process certain Personal Data about you;</li>
                      <li><strong>Legitimate interest:</strong> Company uses a legitimate interest in processing of your Personal Data, for example, when Company processes your data to fix bugs in the Application and to ensure its smooth operation, to improve the work of the our Services, to develop and to introduce new functions and services, to ensure security of User when using the Application, and to ensure secure work of the Services, to transfer data within the organizational structure of Company, etc. We will not use legitimate interest as a basis for processing of the User data if the rights, freedoms and interests of the user override the interest of Company or third-party;</li>
                      <li><strong>Legislative requirements:</strong> Company may process user’s Personal Data, if it is required by the law that applies to Orovera. For example, Company may provide Personal Data on users at the request of courts or other authorized bodies;</li>
                      <li><strong>Consent:</strong> Company relies solely on Your consent when uses Personal Data for the purpose of registering in Application, usage of Application, Services offered, etc.</li>
                    </ol>
                  </li>
                  <li><strong>Company will not collect sensitive Personal Data.</strong> If in any other instance a need arises to collect sensitive Personal Data, Company will do so only with the data subject’s express consent, which can be withdrawn at any time.</li>
                  <li>We do not transfer your Personal Data to third parties, except circumstances mentioned in this Privacy Policy or Terms.</li>
                  <li>This Privacy Policy is subject to the Terms of Use (which may be found in the Application). Your use of the Application and any Personal Data you provide remains subject to the terms of this Privacy Policy and our Terms of Use.</li>
                  <li>In the event of significant updating of this Privacy Policy, We will ask you to re-consent to the updated Privacy Policy before any changes take effect. In the event that significant changes are unacceptable to you, We ask you to stop using Our Application immediately. We will notify You about minor changes by sending notification in the Application. All changes will indicate the date from which they will take effect.</li>
                  <li>The definitions set forth herein and mentioned with capital letter, unless specifically introduced in this Privacy Policy, shall have the meaning as described in Terms of Use or, if the definition cannot be found in Terms of Use, shall have the meaning as is specified in the General Data Protection Regulation.</li>
                </ol>
                {/* <p>
                  1. <strong>Додаток</strong> «Жабка» (далі – Додаток) належить на праві
                  власності ТОВ "4ек", код ЄДРПОУ 44336843 (далі – Компанія) .
                  Всіоб'єкти права інтелектуальної власності, які містяться у
                  додатку, окрім випадків, коли про це окремо зазначено,
                  належать на праві власності Компанії.
                </p>
                <p>
                  2. Дана Політика конфіденційності та захисту персональних
                  даних (далі – Політика конфіденційності) є чинною виключно в
                  рамках Правил користуванняДодатком та не поширюється на web –
                  сайти третіх осіб.
                </p> */}
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.2.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.2.Body"}>
                <ol>
                  <li>The legal entity, which acts as a Personal Data Processing Company is <strong>OROVERA S.A.</strong>, a joint-stock company based in Lublin (20-103), ul.Rusałka 17a, registered in the National Court Register under the KRS number: 0001020988.</li>
                  <li>Be advised, that WE DO NOT process/store or interact with the information collected for the KYC-purposes (sub-clauses (b) and (c) of clause 3.3.1 of this Privacy Policy). This data is processed by the duly assigned and certified company <strong>DIGITAL GATEWAYS</strong> Sp. z with registered address at o.o. ul. Rondo I. Daszyńskiego 1, 00-843 Warszawa, under KRS number 0000790370, NIP: 5272896326 (also referred as to the “KYC-provider”).</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.3.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.3.Body"}>
                <ol>
                  <li>«Personal Data» (also referred as to the «PD», «Personal Information») shall mean any information, that identifies you, i.e. your email, login (username) requests to our support team, and etc. If you cannot be identified (for example, when Personal Data has been anonymized or this information solely bears statistical information), this Policy shall not apply.</li>
                  <li>Whenever you use our Application, We collect and process your Personal Data.</li>
                  <li>We divide the information we collect about you as follows:</li>
                  <li><strong>Information you provide us with by yourself:</strong>
                    <ol>
                      <li>When you register for account and then use our Services (e.g. after initiation of Payment Instruction), you provide Us with your Personal Data which includes following info:
                        <ol>
                          <li>Name and Surname;</li>
                          <li>Contact phone number;</li>
                          <li>Email (if the Payment Instruction is processed);</li>
                          <li>Transaction Amount (if the Payment Instruction is processed).</li>
                        </ol>
                      </li>
                      <li>When the KYC is conducted, you are requested to type-in via our form the following information (but these types of Personal Data are processed, stored by our KYC-Provider and we do not act as a processing company for the following types of information):
                        <ol>
                          <li>Personal ID;</li>
                          <li>Residential address.</li>
                        </ol>
                      </li>
                      <li>In certain cases, (when additional verification by bank or compliance authority is needed according to governmental rules, AML or due to Applicable Law) KYC-Provider may require you to send additional information and documents, namely:
                        <ol>
                          <li>Confirmation of the ID (such as driver license or another document);</li>
                          <li>Proof of address document (e.g. utility bill or bank statement);</li>
                          <li>Other if required. </li>
                        </ol>
                      </li>

                    </ol>
                  </li>
                  <li>You won’t be able to use the Application in full until you pass the KYC-procedure with respect to the provisions of this Privacy Policy.</li>
                  <li>The abovementioned information (c) and details shall be provided only upon special personal request to the User and specified in respective notification/email or other means.</li>
                  <li>The only permissions we will ask access for are the access to your device’ keyboard and for sending you important notifications.
                    <ol>
                      <li><strong>Information We collect automatically (may apply even if You do not register User Account or use offered Services):</strong>
                        <ol>
                          <li>We collect general anonymous analytical information on the use of the Services for their optimization.</li>
                        </ol>
                      </li>
                      <li><strong>Information We receive from third parties.</strong> We do not exclude the possibility of collecting your Personal Data from other sources, for example, from publicly available sources. We use this information in addition to the Personal Data We have already collected about you in order to better personalize and improve our services for you, as well as to verify the Personal Data you provide Us with.</li>
                    </ol>
                  </li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.4.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.4.Body"}>
                <ol>
                  <li>We may deliver, personalize, and improve our Application by combining and using the information We have about you (including information We receive on and off our Application) to understand how you use and interact with our Application, Services and the people or things you’re connected to and interested in. We also may use the information We have about you for the following purposes:
                    <ol>
                      <li>Provide, maintain, improve, and develop relevant features, content, and Services, Application;</li>
                      <li>Fulfil your requests (support) and when authorized by you;</li>
                      <li>Research and develop new Services;</li>
                      <li>Detect and defend against fraudulent, abusive, or unlawful activity;</li>
                      <li>Send You technical notices, updates, security alerts, invoices and other support and administrative messages;</li>
                      <li>Provide customer service;</li>
                      <li>Communicate with you about products, Services, offers;</li>
                      <li>To send you different promotions, and info regarding events, and to provide other news and information we think will be of interest to you;</li>
                    </ol>
                  </li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.5.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.5.Body"}>
                <ol>
                  <li>Our Privacy Policy applies only to the Services, that are offered in the Application. Our App sometimes may contain links to other websites, sources. We do not exercise control over any website that may be linked to from within content on our Website. We are not responsible for the privacy practices or content of such websites.</li>
                  <li><strong>We do not transfer your Personal Data to third parties, except cases, that are mentioned in this Privacy Policy which are next:</strong>
                    <ol>
                      <li><strong>With our partners.</strong> We may share information with affiliates, vendors, consultants, and other service providers (but not with advertisers and ad partners) who need access to such information to carry out work for Us. The partner’s use of Personal Data will be subject to appropriate confidentiality and security measures.
                        <ol>
                          <li>Payment services are provided by the <strong>Quicko Sp. z o.o.</strong> (https://www.quicko.pl/quicko-files) and their related affiliates, sub-contractors subject to their privacy policies and additional agreements.</li>
                          <li>KYC-Provider – <strong>DIGITAL GATEWAYS</strong> Sp. Z;</li>
                        </ol>
                      </li>
                      <li><strong>To comply with the law.</strong> We may share information in response to a request for information if We believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, We will attempt to provide you with prior notice before disclosing your information in response to such a request. </li>
                      <li><strong>In an emergency.</strong> We may share information if We believe it's necessary to prevent imminent and serious bodily harm to a person;</li>
                      <li><strong>To enforce our policies and rights.</strong> We may share information if We believe your actions are inconsistent with our user agreements, rules, or other Orovera policies, or to protect the rights, property, and safety of ourselves and others;</li>
                      <li><strong>With our Affiliates.</strong> We may share information between and among Company and any of our parents, affiliates, subsidiaries, and other companies under common control and ownership;</li>
                      <li><strong>With your consent.</strong> We may share information about you with your consent or at your discretion.</li>
                    </ol>
                  </li>
                  <li>WE DON’T STORE YOUR CREDIT CARD AND PAYMENT INFORMATION; IT’S TRANSFERRED RIGHT TO OUR PCI-DCC COMPLIANT FOR PAYMENT FACILITATION PROCEDURES.</li>
                  <li>Other information, that does not personally identify you as an individual is collected by Company (such as, by way of example, patterns of use) and is exclusively owned by Us. We can use this information in such manner on our sole discretion, deems appropriate.</li>
                  <li>We may share specific aggregated, non-Personal Data with third parties, such as the number of users who have registered with us, the volume and pattern of traffic to and within the App, etc. That information will not identify you, the individual, in any way.</li>
                  <li>As were mentioned above, when you send Us messages, We can keep them for administering of your inquiries, for improving of our services. We shall not transfer information from such messages to third parties.</li>
                  <li>In other cases, We do not share your Personal Data, but retain it encrypted on our servers, for more details about that please see section “Security of Your Personal Data”.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.6.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.6.Body"}>
                <ol>
                  <li>We retain your Personal Data to fulfill our legal, operational, contractual and regulatory obligations. For each type of records, the recordkeeping requirements may vary so We guarantee that in no event shall We retain your Personal Data longer than required. If you would like to know more about the length of retention for a specific data, please contact Us at info@orovera.com.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.7.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.7.Body"}>
                <ol>
                  <li>We provide the following safety measures regarding protection of your Personal Data:
                    <ol>
                      <li>Evaluation of the effectiveness of used security measures, prior to the launch of the Application and its updates;</li>
                      <li>Establishing rules to access to Personal Data processed by the Website, as well as ensuring registration and recording of all actions performed with PD in the information systems of the Application;</li>
                      <li>Detection of the facts of unauthorized access to PD and the adoption of appropriate response measures;</li>
                      <li>Restoration of PD that was modified or destroyed due to unauthorized access to them (within the framework of technical capability of the Company).</li>
                    </ol>
                  </li>
                  <li><strong>WE USE, STORE AND PROCESS YOUR PD ON SERVERS IN VARIOUS JURISDICTIONS (EU-COUNTRIES), WHERE OUR FACILITIES AND/OR SERVICE PROVIDERS ARE LOCATED. BY CHECKING A RELEVANT BOX, VIEWED ON THE WEBSITE DURING REGISTRATION PROCEDURE, YOU AGREE TO THIS TRANSFER, STORING, OR PROCESSING. COMPANY WILL TAKE ALL STEPS REASONABLY NECESSARY TO ENSURE THAT YOUR PERSONAL DATA IS TREATED SECURELY AND IN ACCORDANCE WITH THIS POLICY.</strong> Some of the safeguards We use are firewalls and data encryption, physical access controls to our data centers, and information access authorization controls. Company also authorize access to Personal Data only for those employees or contractors who require it to fulfil their job or service responsibilities. All of our physical, electronic, and procedural safeguards are designed to comply with applicable laws and regulations. From time to time, the Personal Data may be also stored in other locations, and in such cases, Company will ensure that the Personal Data will be stored and processed with the reasonable level of care and security.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.8.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.8.Body"}>
                <ol>
                  <li>If you are a resident of the European Union, you have certain data protection rights under the GDPR:
                    <ol>
                      <li><strong>You can access and change</strong> certain types of the Personal Data through the Application without contacting Company (this clause also applies for the non-EU residents);</li>
                      <li><strong>You may delete several types of your account information, which were provided by yourself, at any time from the relevant page.</strong> But We may retain certain your Personal Data as required by law or for legitimate business purposes after you delete your account;</li>
                      <li><strong>You have the right to receive information regarding purposes of Personal Data</strong> collection, regarding third parties, to which we disclose your Personal Data, information, regarding safeguards that are applicable for secure storage of your Personal Data, period of time for which Personal Data will be stored;</li>
                      <li><strong>You also have the right to demand full erasure of Personal Data concerning you sending Us a request via support [info@orovera.com].</strong> We shall fulfil your request in timely manner. If there is no other lawful ground for storing and processing of your PD, as prescribed by Applicable Law, Company shall erase such information. If there is another legal ground for such processing, Company shall notify you in appropriate period of time (this clause also applies for the non-EU residents);</li>
                      <li><strong>You also have the right to demand restriction of the storing and processing of the PD</strong>, if legal grounds for such demands have arisen / or are prescribed in Applicable Law;</li>
                      <li><strong>You have the right to object processing of Personal Data concerning yourself</strong> under circumstances, that are prescribed in Applicable Law. In case of such objection, Company shall restrain from processing of your PD, if there will be no other legal grounds for continuing of processing;</li>
                      <li>Company takes your rights very seriously. However, if you are of the opinion that We have not dealt with your complaints adequately, you have the right to submit a complaint to the data privacy protection authorities responsible;</li>
                      <li><strong>You have the right to ask Company to provide you with a structured, list of previously provided PD</strong> (and of the currently processed PD by the Company). You also can ask Us to transmit your PD to previously chosen third party.</li>
                    </ol>
                  </li>
                  <li>To send a request for delete, rectification and submission of PD please contact support via or info@orovera.com</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.9.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.9.Body"}>
                <ol>
                  <li>When We transfer PD outside the European Union or EFTA, We ensure, that the adequate level of PD security is provided. During such transfer, We also ensure that adequate level of your rights protection is also applied, on the basis of adequacy analysis of the third country Personal Data protection legislation, on the basis of contractual obligations, assumed by the PD recipient.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.10.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.10.Body"}>
                <ol>
                  <li>If Company becomes aware of security systems breach, then We will attempt to notify you electronically so that you can take appropriate protective steps. Company may post a notice on our websites or in Application if the security breach occurs.</li>
                  <li>When the Personal Data breach is likely to result in a high risk to the rights and freedoms of Users, the Company will inform you as soon as reasonable possible.</li>
                  <li>In the event that Company is acquired by or merged with a third-party entity, We reserve the right, in any of these circumstances, to transfer or assign the information We have collected from our users as part of such merger, acquisition, sale, or other change of control. If we become involved in a merger, acquisition, or any form of sale of some or all of its assets, We will notify Users before Personal Data is transferred and becomes subject to a different privacy policy. In the unlikely event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, we may not be able to control how your Personal Data is treated, transferred, or used.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.11.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.11.Body"}>
                <ol>
                  <li><strong>BY ACCESSING OR USING THE APPLICATION AND ONLINE SERVICES PROVIDED BY COMPANY, YOU HEREBY REASSURE THAT YOU ARE OVER 18 YEARS OLD OR MEET ANOTHER AGE OBLIGATIONS STIPULATED IN THE APPLICABLE LAW EMPOWERING YOU TO ENTER INTO THE PRESENT CONTRACT. USERS UNDER 18 YEARS OLD MAY USE THE SERVICES ONLY WITH RESPECT TO PECULIARITIES ESTABLISHED BY THE APPLICABLE LAW. </strong></li>
                  <li><strong>YOU BARE ALL AND ANY RESPONSIBILITY FOR ANY CONSEQUANCES FOR USAGE OF THE WEBSITE OR PROVIDED SERVICES WITHOUT MEETING REQUIREMENTS SET BY THE TERMS OR THE PRIVACY POLICY.</strong></li>
                  <li><strong>PERSONNEL OF COMPANY DOES NOT KNOWINGLY COLLECT OR MAINTAIN PERSONALLY IDENTIFIABLE INFORMATION OR NON-PERSONALLY IDENTIFIABLE INFORMATION ON OUR SERVICES FROM PERSONS UNDER 14 YEARS OLD, AND NO PART OF OUR SERVICES IS DIRECTED TO PERSONS UNDER 14. IF YOU ARE UNDER 14 YEARS OLD, THEN PLEASE DO NOT USE OR ACCESS THE COMPANY SERVICES AT ANY TIME OR IN ANY MANNER. IF COMPANY LEARNS THAT PERSONALLY IDENTIFIABLE INFORMATION OF PERSONS LESS THAN 14 YEARS OLD HAS BEEN COLLECTED ON THE SERVICES WITHOUT VERIFIED PARENTAL CONSENT, THEN COMPANY WILL TAKE THE APPROPRIATE STEPS TO DELETE THIS INFORMATION AND SUSPEND YOUR ACCOUNT.</strong></li>
                  <li>Additionally, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or We need to have obtained verifiable consent from your parent or legal guardian as it is prescribed in the Terms of Use. Only persons over 18 years old may access the full functionality of the Application.</li>
                </ol>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.12.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.12.Body"}>
                <ol>
                  <li>If You have any questions about this Privacy Policy, please feel free to contact Us or to write to Us at: E-mail: info@orovera.com</li>
                </ol>
              </Trans>
            ),
          },
        ]}
      />
    </section>
  );
};

export default PrivacyPolicy;
