import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import { useRef, useState } from "react";

import "swiper/css";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import arrow from "images/en/home/phone-slider/arrow.svg";
import useCurrLang from "hooks/useCurrLang";
import useKeyboardSlides from "hooks/useKeyboardSlides";

const PhoneSlider = () => {
  const [swiper, setSwiper] = useState<SwiperClass | null>();
  const [phoneCurrentIndex, setPhoneCurrentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const phoneSlides = useKeyboardSlides();
  const currLang = useCurrLang();
  const progressCircle = useRef<any>();

  const onAutoplayTimeLeft = (_s: any, _time: any, progress: any) => {
    progressCircle?.current?.style.setProperty("--progress", 1 - progress);
  };

  const changeSlide = () => {
    swiper?.slideNext();
  };

  const renderPhoneSlides = () => {
    return phoneSlides.map((slide) => {
      return (
        <SwiperSlide key={slide.name}>
          <div className="app-description-wrapper">
            <p>{t("home.header." + slide.name + "Description")}</p>
            <img src={arrow} alt="arrow" />
          </div>
          <div className="blur-background">
            <img src={slide.blur} className="d-none d-lg-inline" />
            <img src={slide.blurMobile} className="d-inline d-lg-none" />
          </div>
          <div className="phone-wrapper">
            <img src={slide.image[currLang]} alt={slide.name} />
          </div>
        </SwiperSlide>
      );
    });
  };

  const renderSlides = () => {
    let slides = [];

    for (let x = 1; x <= 3; x++) {
      for (let y = 0; y <= 4; y++) {
        let slide = (
          <SwiperSlide key={`${x}-${y}`}>
            <img src={phoneSlides[y].imageBottom[currLang]} />
          </SwiperSlide>
        );

        slides.push(slide);
      }
    }

    return slides;
  };

  return (
    <div className="phone-slider-wrapper" id="keyboard-section">
      <div className="bubbles-background">
        <img
          src={require("images/en/home/phone-slider/back-circles.png")}
          alt="background"
        />
      </div>
      <Swiper
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect="fade"
        modules={[EffectFade, Autoplay]}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSwiper={setSwiper}
        onSlideChangeTransitionStart={(swiper) =>
          setPhoneCurrentIndex(swiper.realIndex)
        }
      >
        {renderPhoneSlides()}
      </Swiper>
      <div className="timer" onClick={changeSlide}>
        <div className="background-image">
          <img
            src={require("images/en/home/phone-slider/circles/" +
              phoneSlides[phoneCurrentIndex].name +
              ".png")}
            alt=""
          />
        </div>
        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="21.5"></circle>
          </svg>
        </div>
      </div>
      <div className="footer-carousel">
        <Swiper
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          slidesPerView={"auto"}
          centeredSlides={true}
          spaceBetween={25}
          className="mySwiper"
        >
          {renderSlides()}
        </Swiper>
      </div>
    </div>
  );
};

export default PhoneSlider;
