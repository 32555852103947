export enum NavPath {
  Home = "/",
  FAQ = "faq",
  TermsOfService = "/pages/terms-and-conditions",
  PrivacyPolicy = "/pages/privacy-policy",
  Rates = "/pages/rates",
  ForShareholders = "/for-shareholders",
  // NewsShow = "/news/:articleId",
  NewsShow = "/news/1",
  NewsPublicBetaTesting = "/news/2",
  ContactUs = "/contact-us",


  TermsOfTransactionServicesEN = "/pages/transaction-services-terms/en",
  TermsOfTransactionServicesPL = "/pages/transaction-services-terms/pl",
  TermsOfAcquiringServicesEN = "/pages/acquiring-services-terms/en",
  TermsOfAcquiringServicesPL = "/pages/acquiring-services-terms/pl",

  TermsEN = "/pages/terms-of-use/en",
  TermsPL = "/pages/terms-of-use/pl",
  PrivacyPolicyEN = "/pages/privacy-policy/en",
  PrivacyPolicyPL = "/pages/privacy-policy/pl",
}
