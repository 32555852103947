import { useState } from "react";
import { useTranslation } from "react-i18next";
import NavArrowUp from "../icons/NavArrowUp";
import NavArrowDown from "../icons/NavArrowDown";
import englishFlag from "images/en/navigation/english-flag.svg";
import ukraineFlag from "images/en/navigation/ukraine-flag.svg";
import polandFlag from "images/en/navigation/poland-flag.svg";
import classNames from "classnames";
import moment from "moment";

const languages = [
  { name: "en", title: "EN", titleFull: "English", img: englishFlag },
  { name: "uk", title: "UA", titleFull: "Українська", img: ukraineFlag },
  { name: "pl", title: "PL", titleFull: "Polski", img: polandFlag },
];

const NavLanguageSelect = () => {
  const { i18n, t } = useTranslation();

  let lang = languages[0];

  const foundLang = languages.find((i) => i.name === i18n.resolvedLanguage);

  if (foundLang) lang = foundLang;

  const [active, setActive] = useState(false);

  const setCurrentLang = (lang: string) => {
    i18n.changeLanguage(lang);
    moment.locale(lang);
  };

  const renderItems = () => {
    return languages.map((item) => {
      if (item.name === lang.name) {
        return null;
      }

      return (
        <li key={item.title} onClick={() => setCurrentLang(item.name)}>
          <a href="#">
            <img src={item.img} alt="keyboard" />
            <span className="d-none d-lg-inline">{item.title}</span>
            <span className="d-lg-none">{item.titleFull}</span>
          </a>
        </li>
      );
    });
  };

  return (
    <div
      className={classNames("nav-item nav-select", {
        active,
      })}
      onClick={() => setActive(!active)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => {
        setActive(false);
      }}
    >
      <div className="title d-none d-lg-flex">
        <div className="left-lang">
          <img src={lang.img} alt={lang.title} />
          <p>{lang.title}</p>
        </div>
        {active ? <NavArrowUp /> : <NavArrowDown />}
      </div>
      <div className="title d-lg-none">
        <p>{t("home.nav.Language")}</p>
      </div>
      <div className="body">
        <ul>{renderItems()}</ul>
      </div>
    </div>
  );
};

export default NavLanguageSelect;
