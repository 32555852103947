import calendarIcon from "icons/calendar.svg";
import image from "images/en/shareholders/news-image.png";
import europianImage from "images/en/news/europian-logos.png";
import arrowLeft from "icons/arrow-left.svg";
import { Link } from "react-router-dom";
import { NavPath } from "types/nav";

const NewsShow = () => {
  return (
    <section id="news-show">
      <div className="container">
        <div className="back-to-news">
          <Link to={NavPath.ForShareholders}>
            <img src={arrowLeft} alt="arrow back" />
            <p>Back to all news</p>
          </Link>
        </div>
        <div className="date">
          <img src={calendarIcon} alt="calendar" />
          July 1, 2023
        </div>
        <h2>Projekt UE</h2>
        <div className="body">
          <img src={image} alt="blur orovera app" />
          <h3>Projekt Unijny</h3>
          <p>
            Opracowanie platformy do asynchronicznych płatności pomiędzy
            użytkownikami usług Google Pay, Apple Pay z wykorzystaniem social
            media i kart płatniczych VISA i Mastercard.
          </p>
          <h4>CEL PROJEKTU</h4>
          <p>
            Celem projektu jest opracowanie nowego sposobu płatności, opartego o
            najpopularniejszą metodę komunikacji – komunikatory tekstowe (chat,
            chaty) – w formie dedykowanego modułu klawiatury na urządzenia
            mobilne w połączeniu z portfelami cyfrowymi Apple Pay i Google Pay,
            który pozwoli, bez wychodzenia z aplikacji komunikatora, przesłać
            lub odebrać pieniądze od rozmówcy w szybki i wygodny sposób bez
            podawania dodatkowych informacji.
          </p>
          <p>
            Spółka będzie kierowała swoje usługi i produkt do następujących grup
            odbiorców:
          </p>
          <ul>
            <li>
              osoby prywatne (B2C) – samodzielna rejestracja i dedykowana usługa
              płatności okazjonalnych z wykorzystaniem modułu klawiatury
              płatnościowej oraz możliwość korzystania z funkcjonalności zbiórek
              grupowych;
            </li>
            <li>
              sprzedawcy, odbiorca biznesowy (B2B) – samodzielna rejestracja z
              koniecznością przejścia procesu analizy pod kątem AML i dodatkowe
              opcje, takie jak tworzenie kodów QR i możliwość ich użycia w
              formie fizycznej, na produktach jak również w formie cyfrowej
              (eCommerce);
            </li>
            <li>
              instytucje charytatywne – możliwość skorzystania ze zbiórek
              grupowych.
            </li>
            <p className="mt-md">Wartość projektu: 1 144 000,00 PLN</p>
            <p className="mt-md">
              Dofinansowanie z Funduszy UE: 880 000,00 PLN
            </p>
          </ul>
          <img src={europianImage} alt="europian logos" className="img-fluid" />
        </div>
      </div>
    </section>
  );
};

export default NewsShow;
