import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import { useRef, useState } from "react";

import "swiper/css";
import "swiper/css/effect-fade";
import skipIcon from "icons/skip.svg";
import { useTranslation, Trans } from "react-i18next";
import useHowItWorksSlides from "hooks/useHowItWorksSlides";
import Card from "components/Card";
import useCurrLang from "hooks/useCurrLang";

const HowItWorks = () => {
  const [swiper, setSwiper] = useState<SwiperClass | null>();
  const [phoneCurrentIndex, setPhoneCurrentIndex] = useState<number>(0);
  const { t } = useTranslation();
  const { slides } = useHowItWorksSlides();
  const progressCircle = useRef<any>();
  const progressRect = useRef<any>();
  const currLang = useCurrLang();

  const onAutoplayTimeLeft = (_s: any, _time: any, progress: any) => {
    progressCircle?.current?.style.setProperty("--progress", 1 - progress);
    progressRect?.current?.style.setProperty(
      "width",
      (1 - progress) * 100 + "%"
    );
  };

  const changeSlide = () => {
    swiper?.slideNext();
  };

  const renderPhoneSlides = () => {
    return slides.map((slide) => {
      return (
        <SwiperSlide key={slide.name}>
          <div className="slide-wrapper">
            <img
              src={slide[currLang].mobile}
              alt={slide.name}
              className="d-inline d-lg-none"
            />
            <img
              src={slide[currLang].full}
              alt={slide.name}
              className="d-none d-lg-inline"
            />
          </div>
        </SwiperSlide>
      );
    });
  };

  return (
    <div className="how-it-works">
      <header>
        <h1>
          <Trans i18nKey="home.howItWorks.Title">
            How it <span>Works</span>
          </Trans>
        </h1>
      </header>
      <div className="slider-wrapper">
        <Swiper
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          effect="fade"
          modules={[EffectFade, Autoplay]}
          onAutoplayTimeLeft={onAutoplayTimeLeft}
          onSwiper={setSwiper}
          onSlideChangeTransitionStart={(swiper) =>
            setPhoneCurrentIndex(swiper.realIndex)
          }
        >
          {renderPhoneSlides()}
        </Swiper>
        <div className="progress-container d-flex d-lg-none">
          <div className="progress-wrapper">
            <div className="progress" ref={progressRect}></div>
          </div>
          <span onClick={changeSlide}>{t("general.Skip")}</span>
        </div>
        <div className="timer d-none d-lg-flex" onClick={changeSlide}>
          <span>{t("general.Skip")}</span>
          <div className="progress-wrapper">
            <div className="gray-circle">
              <img src={skipIcon} alt="skip" />
            </div>
            <div className="autoplay-progress" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="23.5"></circle>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="card-group-wrapper">
            <Card
              className="card-mb-2"
              title={t(
                "home.howItWorks.Pay in your browser, no need Orovera app"
              )}
              body={t("home.howItWorks.cardBodyFirst")}
              iconSrc={require("images/en/home/how-it-works/footer/icon-1.png")}
              backgroundImgSrc={require("images/en/home/how-it-works/footer/bg-1.png")}
              backgroundGradient="linear-gradient(180deg, #E4F7FD 0.01%, #CCE4FA 100%)"
              cardSize="small"
            />
            <Card
              className="card-mb-2"
              title={t("home.howItWorks.Ensuring Secure Transactions")}
              body={t("home.howItWorks.cardBodySecond")}
              iconSrc={require("images/en/home/how-it-works/footer/icon-2.png")}
              backgroundImgSrc={require("images/en/home/how-it-works/footer/bg-2.png")}
              backgroundGradient="linear-gradient(180deg, #D6EBDF 0%, #BEEBD1 100%)"
              cardSize="small"
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HowItWorks;
