import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const TermsOfTransactionServicesPL = () => {
  const { t } = useTranslation();

  return (
    <section id="terms-of-services">
      <TermsAndPrivacyTemplate
        title={'REGULAMIN USŁUG TRANSAKCJI CARD-TO-CARD, BLIK-TO-CARD ORAZ ACCOUNT-TO-CARD \n (UMOWA O POJEDYNCZĄ TRANSAKCJĘ)'}
        titleVersion={t("Version 1.0.")}
        titleUpdated={t("As from twenty seventh of March,  2024.")}
        items={[
          {
            title: '1. DEFINICJE',
            body: (
              <ol>
                <span>Terminy stosowane w niniejszym Regulaminie oraz w dokumencie &bdquo;Tabela Opłat i Limit&oacute;w&rdquo;, dostępnym w Serwisie, pisane dużą literą mają znaczenie nadane im poniżej:</span>
                <li><strong>[A] </strong>&ndash; oznacza, iż dany postanowienie Regulaminu odnosi się do usługi Transakcji Account-to-Card.</li>
                <li><strong>[B]</strong> &ndash; oznacza, iż dane postanowienie Regulaminu odnosi się do usługi Transakcji BLIK-to-Card.</li>
                <li><strong>[C]</strong> &ndash; oznacza, iż dane postanowienie Regulaminu odnosi się do usługi Transakcji Card-to-Card.</li>
                <li><strong>Bank</strong> &ndash; wydawca Karty Płatniczej odpowiednio Płatnika lub Odbiorcy albo wydawca BLIK dla Płatnika.</li>
                <li><strong>BLIK </strong>&ndash; instrument płatniczy, wykorzystujący jednorazowe kody generowane w bankowości elektronicznej Płatnika, wydawany w ramach schematu płatniczego prowadzonego przez Polski Standard Płatności sp. z o.o., kt&oacute;ry umożliwia zainicjowanie przez Płatnika Transakcji BLIK-to-Card.</li>
                <li><strong>Dzień Roboczy </strong>- każdy dzień od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy w Polsce.</li>
                <li><strong>Fenige</strong> &ndash; Fenige S.A. z siedzibą w Warszawie, przy ul. Promiennej 38/1, 03-672 Warszawa, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego, prowadzonego przez XIV Wydział Gospodarczy Sądu Rejonowego dla m. st. Warszawy, pod numerem KRS: 0001057371, posiadająca NIP: 1182092036, REGON: 146693435, adres e-mail: <a href="mailto:contact@fenige.pl">contact@fenige.pl</a>, posiadająca status krajowej instytucji płatniczej i nadzorowana przez Komisję Nadzoru Finansowego (Rejestr UKNF: IP42/2017).</li>
                <li><strong>Karta Płatnicza</strong> &ndash; instrument płatniczy; karta płatnicza wydana Płatnikowi: w ramach systemu Mastercard &ndash; kt&oacute;ra umożliwia wykonanie Transakcji w technologii Mastercard MoneySend albo w ramach systemu Visa &ndash; kt&oacute;ra umożliwia wykonanie Transakcji w technologii Visa Direct; z wykluczeniem kart płatniczych wydanych na rzecz os&oacute;b prawnych i jednostek organizacyjnych nieposiadających osobowości prawnej.</li>
                <li><strong>Odbiorca</strong> &ndash; osoba fizyczna, osoba prawna, lub jednostka organizacyjna nie posiadająca osobowości prawnej, posiadacz Karty Płatniczej i odbiorca Transakcji.</li>
                <li><strong>Organizacja Płatnicza</strong> - Mastercard, Visa lub Polski Standard Płatności sp. z o.o.; organizacja udzielająca licencji na wydawanie Kart Płatniczych albo BLIK oraz zapewniająca systemy pozwalające autoryzować i rozliczać Transakcje.</li>
                <li><strong>Partner </strong>&ndash; Orovera A. z siedzibą w Warszawie, właściciel Serwisu.</li>
                <li><strong>PIS</strong> &ndash; ang. Payment Initiation Service; usługa płatnicza inicjowania transakcji płatniczej polegająca na zainicjowaniu zlecenia płatniczego przez dostawcę świadczącego usługę inicjowania transakcji płatniczej na wniosek Płatnika z jego rachunku płatniczego prowadzonego przez innego dostawcę &ndash; regulowana w oddzielnym regulaminie dostawcy tej usługi.</li>
                <li><strong>Płatnik</strong> &ndash; zarejestrowany bądź niezarejestrowany użytkownik Serwisu: będący osobą fizyczną powyżej 16 roku życia, kt&oacute;ry zleca w Serwisie wykonanie Transakcji.</li>
                <li><strong>Procedura 3-D Secure</strong> &ndash; dodatkowa procedura uwierzytelniająca posiadacza Karty Płatniczej, wymagana przez Organizacje Płatnicze.</li>
                <li><strong>Prowizja</strong> &ndash; opłata pobierana od Płatnika za wykonanie Transakcji.</li>
                <li><strong>Regulamin</strong> &ndash; niniejszy regulamin, na podstawie kt&oacute;rego zawierana jest Umowa o Pojedynczą Transakcję pomiędzy Fenige i Płatnikiem.</li>
                <li><strong>Regulamin Serwisu</strong> &ndash; regulamin korzystania z Serwisu zapewniany przez Partnera.</li>
                <li><strong>RODO </strong>- rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych).</li>
                <li><strong>Serwis</strong> &ndash;aplikacja mobilna Orovera, za pośrednictwem kt&oacute;rego Fenige udostępnia oprogramowanie umożliwiające Płatnikowi złożenie Zlecenia Płatniczego, kt&oacute;ry działa zgodnie z Regulaminem Serwisu.</li>
                <li><strong>Transakcja </strong>&ndash; Transakcja Card-to-Card, Transakcja BLIK-to-Card lub Transakcja Account-to-Card.</li>
                <li><strong>Transakcja Account-to-Card </strong>&ndash; transakcja płatnicza polegająca na przekazaniu przez Fenige na zlecenie Płatnika środk&oacute;w pieniężnych, otrzymanych przez Fenige z usługi PIS, do dostawcy prowadzącego rachunek Odbiorcy, do kt&oacute;rego wydana została Karta Płatnicza, w celu uznania tymi środkami rachunku Odbiorcy, będąca usługą płatniczą przekazu pieniężnego w rozumieniu Ustawy; dla uniknięcia wątpliwości sama usługa PIS nie jest częścią Transakcji Account-to-Card, za kt&oacute;rej wykonanie odpowiada Fenige.</li>
                <li><strong>Transakcja BLIK-to-Card</strong> &ndash; transakcja płatnicza polegająca na przekazaniu przez Fenige na zlecenie Płatnika środk&oacute;w pieniężnych z rachunku Płatnika prowadzonego przez innego dostawcę, do dostawcy prowadzącego rachunek Odbiorcy, do kt&oacute;rego wydana została Karta Płatnicza, w celu uznania tymi środkami rachunku Odbiorcy, inicjowana przez Płatnika za pomocą BLIK i będąca usługą płatniczą przekazu pieniężnego w rozumieniu Ustawy.</li>
                <li><strong>Transakcja Card-to-Card </strong>&ndash; transakcja płatnicza polegająca na przekazaniu przez Fenige na zlecenie Płatnika środk&oacute;w pieniężnych z rachunku Płatnika prowadzonego przez innego dostawcę, do kt&oacute;rego wydana została Karta Płatnicza, do dostawcy prowadzącego rachunek Odbiorcy, do kt&oacute;rego wydana została Karta Płatnicza, w celu uznania tymi środkami rachunku Odbiorcy, będąca usługą płatniczą przekazu pieniężnego w rozumieniu Ustawy.</li>
                <li><strong>Transakcja Card-to-Card Cross </strong>&ndash; rodzaj Transakcji Card-to-Card, w kt&oacute;rej przekazanie środk&oacute;w pieniężnych następuje z rachunku płatniczego, do kt&oacute;rego wydana została Karta Płatnicza innej Organizacji Płatniczej niż do rachunku, na kt&oacute;ry środki te są przekazywane.</li>
                <li><strong>Umowa o Pojedynczą Transakcję </strong>&ndash; umowa wykonania pojedynczej Transakcji płatniczej w rozumieniu Ustawy, zawarta przez Płatnika z Fenige poprzez akceptację przez Płatnika Regulaminu.</li>
                <li><strong>Ustawa</strong> &ndash; ustawa z dnia 19 sierpnia 2011 r. o usługach płatniczych.</li>
                <li><strong>Ustawa AML </strong>&ndash; ustawa z dnia 1 marca 2018 roku o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu.</li>
                <li><strong>Zlecenie Płatnicze </strong>&ndash; oświadczenie Płatnika zawierające dyspozycję wykonania przez Fenige Transakcji.</li>
              </ol>
            ),
          },
          {
            title: '2. POSTANOWIENIA WSTĘPNE I ZGODY',
            body: (
              <ol>
                <li>Niniejszy Regulamin określa warunki i zasady wykonania przez Fenige pojedynczej Transakcji Card-to-Card, Transakcji BLIK-to-Card albo Transakcji Account-to-Card na zlecenie Płatnika za pośrednictwem Serwisu &ndash; w zależności, kt&oacute;ry z tych rodzaj&oacute;w Transakcji oferowany jest w Serwisie.</li>
                <li>Serwis stanowi w całości usługę Partnera. Partner odpowiedzialny jest za jego dostępność, prawidłowe funkcjonowanie i bezpieczeństwo, w tym za działanie usługi konta Płatnika w Serwisie &ndash; o ile taka usługa oferowana jest przez Partnera.</li>
                <li>Płatnik akceptując Regulamin zawiera na jego podstawie z Fenige Umowę o Pojedynczą Transakcję, przez co Płatnik zobowiązuje się do przestrzegania wszystkich postanowień Regulaminu, jak i obowiązujących przepis&oacute;w prawa oraz wywiązywania się z przewidzianych w Regulaminie zobowiązań.</li>
                <li>Fenige świadczy usługę Transakcji na podstawie otrzymanego wyłącznie od Płatnika w Serwisie Zlecenia Płatniczego.</li>
                <li>Przy wykonywaniu Transakcji Fenige akceptuje Karty Płatnicze Mastercard i Visa. <strong>[C]</strong></li>
                <li>Wykaz walut, w jakich możliwe jest zlecenie wykonania Transakcji oraz walut na jakie może nastąpić przeliczenie kwoty Transakcji, dostępny jest dla Płatnika w Serwisie podczas składania Zlecenia Płatniczego.</li>
                <li>Świadczenie usług Transakcji nie obejmuje otwarcia i prowadzenia przez Fenige dla Płatnika rachunku płatniczego w rozumieniu Ustawy, nie jest też związane z otwarciem i prowadzeniem rachunku bankowego w rozumieniu ustawy z dnia 23 kwietnia 1964 r. &ndash; Kodeks cywilny oraz ustawy z dnia 29 sierpnia 1997 r. &ndash; Prawo bankowe, a czynności dokonywane przez Fenige na podstawie Regulaminu nie mają charakteru czynności bankowych.</li>
                <li>Płatnik wyraża zgodę na ujawnienie przez Fenige Partnerowi informacji i danych dotyczących jego osoby, zlecanych przez niego w Serwisie Transakcji oraz składanych przez niego reklamacji, tj. informacji i danych chronionych tajemnicą zawodową, o kt&oacute;rej mowa w art. 11 Ustawy (tajemnica płatnicza). Partner przetwarza te dane jako ich administrator zgodnie z obowiązującymi przepisami o ochronie danych osobowych.</li>
                <li>Akceptując Regulamin Płatnik żąda, aby całkowite wykonanie Umowy o Pojedynczą Transakcję, a tym samym wykonanie samej Transakcji, nastąpiło przed upływem ustawowego terminu na odstąpienie od tego rodzaju umowy, terminu 14 dni od zaakceptowania Regulaminu.</li>
              </ol>
            ),
          },
          {
            title: '3. REALIZACJA PŁATNOŚCI',
            body: (
              <ol>
                <li>Zlecenie każdej pojedynczej Transakcji przez Płatnika za pośrednictwem Serwisu wymaga od niego uprzednio:
                  <ol type="A">
                    <li>przejścia procedury identyfikacji i weryfikacji Płatnika zgodnie z zasadami przeciwdziałania praniu pieniędzy i finansowaniu terroryzmu (procedura KYC) &ndash; przy czym Płatnik akceptuje fakt, że jeżeli Fenige nie może zastosować środka bezpieczeństwa finansowego wobec Płatnika z Ustawy AML, np. zweryfikować jego tożsamości, Transakcja nie zostanie wykonana,</li>
                    <li>rejestracji konta Płatnika w Serwisie na zasadach określonych w Regulaminie Serwisu (o ile Serwis wymaga rejestracji w celu zlecania wykonywania Transakcji) i akceptacji treści niniejszego Regulaminu, co oznacza zapoznanie się z jego treścią oraz zobowiązanie się do przestrzegania jego warunk&oacute;w i zasad oraz jest r&oacute;wnoznaczne z zawarciem Umowy o Pojedynczą Transakcję,</li>
                    <li>zalogowania się do konta Płatnika w Serwisie na zasadach określonych w Regulaminie Serwisu (o ile Serwis wymaga rejestracji w celu zlecania wykonywania Transakcji).</li>
                  </ol>
                </li>
                <li>Przeprowadzenie procedury KYC i wykonanie Transakcji wymaga podania przez Płatnika następujących informacji i danych:
                  <ol type="A">
                    <li>imię i nazwisko Płatnika,</li>
                    <li>obywatelstwo Płatnika,</li>
                    <li>numer PESEL Płatnika lub data urodzenia Płatnika - w przypadku gdy nie nadano numeru PESEL, oraz państwo urodzenia Płatnika,</li>
                    <li>seria i numer dokumentu stwierdzającego tożsamość Płatnika,</li>
                    <li>inne dane z dokumentu stwierdzającego tożsamość Płatnika,</li>
                    <li>wizerunek Płatnika,</li>
                    <li>adres zamieszkania Płatnika,</li>
                    <li>adres e-mail Płatnika,</li>
                    <li>wygenerowany dla Płatnika kod BLIK, <strong>[B]</strong></li>
                    <li>numer, data ważności i CVV/CVC Karty Płatniczej Płatnika, kt&oacute;rej rachunek ma zostać obciążony, <strong>[C]</strong></li>
                    <li>kod w ramach Procedury 3-D Secure (o ile jest on wymagany przy Transakcji daną Kartą Płatniczą), <strong>[C]</strong></li>
                    <li>imię i nazwisko Odbiorcy,</li>
                    <li>adres e-mail Odbiorcy (opcjonalnie),</li>
                    <li>numer Karty Płatniczej Odbiorcy, kt&oacute;rej rachunek ma zostać uznany,</li>
                    <li>kwota Transakcji do przekazania Odbiorcy,</li>
                    <li>tytuł Transakcji (opcjonalnie),</li>
                  </ol>
                  <span>&ndash; z zastrzeżeniem, że Fenige może żądać od Płatnika r&oacute;wnież innych informacji i danych potrzebnych jej do oceny ryzyka wykonania Transakcji, w tym w celu przeciwdziałania praniu pieniędzy i finansowaniu terroryzmu, a także ponownego przedstawienia już raz dostarczonych informacji i danych, r&oacute;wnież po złożeniu Zlecenia Płatniczego, kt&oacute;re wszystkie Płatnik zobowiązuje się niezwłocznie dostarczyć.</span>
                </li>
                <li>Płatnik zobowiązuje się do podawania, w celu wykonania Transakcji, prawdziwych, kompletnych i aktualnych informacji i danych. Płatnik jest w pełni odpowiedzialny za podanie nieprawidłowych danych Odbiorcy, jak r&oacute;wnież nieprawidłowej kwoty Transakcji.</li>
                <li>Identyfikacja i weryfikacja tożsamości Płatnika w ramach procedury KYC może zostać przeprowadzona przez zewnętrznego dostawcę w Serwisie lub poza Serwisem, z kt&oacute;rym Partnera lub Fenige łączy osobna umowa wsp&oacute;łpracy.</li>
                <li>Przed złożeniem Zlecenia Płatniczego Płatnik otrzymuje informację o kwocie należnej od niego Prowizji.</li>
                <li>Jeżeli Transakcja wiąże się z przeliczeniem waluty, przed wykonaniem Transakcji Płatnik otrzymuje informację o faktycznym kursie walutowym, kt&oacute;ry zostanie zastosowany przez Fenige do Transakcji oraz o kwocie Transakcji po przeliczeniu walut. Płatnik ponosi koszty wymiany walut wliczone przez Fenige do wyświetlanego Płatnikowi kursu walutowego.</li>
                <li>Płatnik powinien upewnić się w jakiej walucie prowadzony jest rachunek płatniczy Odbiorcy, ponieważ dostawca rachunku płatniczego Odbiorcy dokona swojego przewalutowania, jeżeli waluta jaką otrzyma od Fenige będzie r&oacute;żniła się od waluty w jakiej jest prowadzony rachunek Odbiorcy.</li>
                <li>Ewentualny zwrot kwoty Transakcji może wiązać się z przewalutowaniem po innym kursie, niż ten, kt&oacute;ry został zastosowany podczas składania Zlecenia Płatniczego.</li>
                <li>Przyjęcie Zlecenia Płatniczego od zweryfikowanego Płatnika do realizacji w Serwisie następuje po podaniu przez Płatnika wszystkich wymaganych informacji i danych, kliknięciu w Serwisie w przycisk zatwierdzający złożenie Zlecenia Płatniczego oraz przejściu procedury uwierzytelniającej, w szczeg&oacute;lności Procedury 3-D Secure (o ile jest wymagana) &ndash; co jest r&oacute;wnoznaczne z wyrażeniem zgody przez Płatnika na wykonanie Transakcji (autoryzacja).</li>
                <li>Niezwłocznie po otrzymaniu Zlecenia Płatniczego Fenige udostępnia Płatnikowi w Serwisie lub przesyła na adres e-mail podany przez Płatnika przy złożeniu Zlecenia Płatniczego informację:
                  <ol type="A">
                    <li>umożliwiającą Płatnikowi zidentyfikowanie Transakcji oraz informacje dotyczące Odbiorcy,</li>
                    <li>o kwocie Transakcji w walucie użytej w Zleceniu Płatniczym,</li>
                    <li>o wszelkich opłatach należnych od Płatnika z tytułu Transakcji, w tym wyszczeg&oacute;lnienie kwot tych opłat,</li>
                    <li>o kursie walutowym zastosowanym w danej Transakcji przez Fenige oraz o kwocie Transakcji po przeliczeniu walut, jeżeli Transakcja wiązała się z przeliczaniem waluty,</li>
                    <li>o dacie otrzymania Zlecenia Płatniczego.</li>
                  </ol>
                </li>
                <li>Całkowite wykonanie Zlecenia Płatniczego rozpoczyna się w dniu, w kt&oacute;rym Płatnik przekazał do dyspozycji Fenige środki pieniężne na wykonanie Zlecenia Płatniczego. Jeżeli jednak ten dzień nie jest Dniem Roboczym uznaje się, że Zlecenie Płatnicze zostało otrzymane przez Fenige pierwszego Dnia Roboczego po tym dniu.</li>
                <li>Fenige będzie dążyć do jak najszybszego doprowadzenia do uznania kwotą Transakcji rachunku płatniczego Odbiorcy, nawet w ciągu 30 minut od chwili otrzymania przez Fenige z Serwisu informacji o złożeniu Zlecenia Płatniczego, maksymalny czas wykonania Transakcji jest liczony jednakże do końca następnego Dnia Roboczego od momentu otrzymania przez Fenige Zlecenia Płatniczego, tj. od dnia, o kt&oacute;rym mowa w ust. 11 niniejszego paragrafu.</li>
                <li>Transakcja jest wykonywana przez Fenige zgodnie z podanym przez Płatnika numerem Karty Płatniczej Odbiorcy (unikatowym identyfikatorem), niezależnie od wszelkich innych danych dotyczących Odbiorcy, kt&oacute;re są znane lub dostępne dla Fenige lub innych dostawc&oacute;w usług płatniczych uczestniczących w wykonaniu Transakcji.</li>
                <li>Bank Płatnika lub Bank Odbiorcy może blokować, zatrzymać lub odrzucić Transakcję ze względ&oacute;w bezpieczeństwa lub z innych powod&oacute;w, takich jak np. brak środk&oacute;w pieniężnych na rachunku Płatnika. Możliwe jest r&oacute;wnież, że środki zostaną zablokowane w wyniku przerwanej lub nieudanej Transakcji. Fenige nie ponosi odpowiedzialności za działania Bank&oacute;w w tym zakresie. W przypadku kiedy niemożliwe będzie przekazanie środk&oacute;w Bankowi Odbiorcy, o ile nie stoją temu na przeszkodzie inne względy prawne, Fenige zwr&oacute;ci niezwłocznie kwotę Transakcji Płatnikowi.</li>
                <li>Transakcje mogą być wykonywane do wysokości limit&oacute;w transakcyjnych wprowadzonych przez Fenige i wskazanych w dokumencie &bdquo;Tabela Opłat i Limit&oacute;w&rdquo; dostępnym w Serwisie. W przypadku kiedy tylko część zlecanej kwoty Transakcji przekracza dopuszczalny limit cała Transakcja nie podlega wykonaniu.</li>
                <li>Ze względu na całkowite wykonanie Transakcji na żądanie Płatnika będącego konsumentem przed upływem ustawowego terminu na odstąpienie od Umowy o Pojedynczą Transakcję, tj. terminu 14 dni od zaakceptowania Regulaminu, prawo do odstąpienia od Umowy o Pojedynczą Transakcję po wykonaniu Transakcji nie przysługuje.</li>
                <li>Płatnik może w każdej chwili wycofać zgodę na wykonanie Transakcji, nie p&oacute;źniej jednak niż do chwili otrzymania Zlecenia Płatniczego przez Fenige, w kt&oacute;rym to momencie Zlecenie Płatnicze staje się nieodwołalne. Fenige jest w takim przypadku uprawniona do pobrania opłaty za odwołanie Zlecenia Płatniczego w wysokości pełnej kwoty Prowizji należnej Fenige od Transakcji, kt&oacute;ra miała zostać wykonana na podstawie takiego Zlecenia Płatniczego.</li>
              </ol>
            ),
          },
          {
            title: '4. ODMOWA LUB WSTRZYMANIE WYKONANIA TRANSAKCJI',
            body: (
              <ol>
                <li>Fenige może odm&oacute;wić wykonania Transakcji, wstrzymać wykonanie Transakcji lub zablokować możliwość zlecania wykonywania Transakcji w przyszłości danemu Płatnikowi, lub w stosunku do danego numeru Karty Płatniczej, w przypadku gdy:
                  <ol type="A">
                    <li>Płatnik narusza postanowienia Regulaminu,</li>
                    <li>Płatnik nie podał pełnych, kompletnych, ważnych lub prawdziwych informacji i danych niezbędnych do wykonania Transakcji,</li>
                    <li>nie uzyskano pozytywnego wyniku Procesu 3-D Secure w stosunku do Transakcji (w przypadku kiedy było to wymagane),</li>
                    <li>Karta Płatnicza Płatnika lub Odbiorcy uniemożliwia obciążenie lub uznanie rachunku środkami pieniężnymi z Transakcji zgodnie z obowiązującymi regulacjami Organizacji Płatniczych, będzie zablokowana, zastrzeżona lub nieważna w momencie wykonania Transakcji albo jeżeli BLIK <strong>[B]</strong> będzie uniemożliwiał wykonanie Transakcji,</li>
                    <li>Bank Płatnika lub Bank Odbiorcy albo inny dostawca prowadzący rachunek płatniczy dla Płatnika lub dla Odbiorcy odm&oacute;wi wykonania Transakcji albo instytucje te nie odpowiedzą na prawidłowo wysłane do nich komunikaty płatnicze,</li>
                    <li>jest to uzasadnione przyczynami związanymi z bezpieczeństwem Transakcji, w tym w przypadku podejrzewania przez Fenige, że dochodzi do bezprawnego korzystania z usług Transakcji lub pr&oacute;by zainicjowania nieautoryzowanej Transakcji,</li>
                    <li>Fenige podejrzewa, że Zlecenie Płatnicze nie jest zgodne z obowiązującymi przepisami prawa, regulacjami Organizacji Płatniczych lub Regulaminem,</li>
                    <li>poziom ryzyka Transakcji będącej przedmiotem Zlecenia Płatniczego, według oceny ryzyka przeprowadzonej przez Fenige lub osobę trzecią, jest niedopuszczalny,</li>
                    <li>spełnione są warunki do wstrzymania lub odmowy wykonania Transakcji na podstawie obowiązujących przepis&oacute;w prawa związanych z przeciwdziałaniem praniu pieniędzy i finansowaniu terroryzmu,</li>
                    <li>Fenige otrzyma takie polecenie od organu władzy publicznej, w tym od organ&oacute;w ścigania.</li>
                  </ol>
                </li>
                <li>Fenige nie ponosi odpowiedzialności za szkody poniesione przez Płatnika z powodu niewykonania lub op&oacute;źnienia w wykonaniu Transakcji na podstawie jednej z sytuacji wskazanych powyżej, chyba że odpowiedzialność Fenige przewidują bezwzględnie obowiązujące przepisy prawa.</li>
                <li>W przypadku odmowy wykonania Transakcji, po tym jak Fenige weszło już w posiadanie środk&oacute;w pieniężnych Płatnika, Fenige zwraca Płatnikowi niezwłocznie kwotę Transakcji na rachunek płatniczy, z kt&oacute;rego została wykonana Transakcja (albo na rachunek płatniczy, z kt&oacute;rego Fenige otrzymała przelew od Płatnika w celu wykonania Transakcji Account-to-Card <strong>[A]</strong>), z wyjątkiem kiedy jest to niedopuszczalne na podstawie powszechnie obowiązujących przepis&oacute;w prawa.</li>
              </ol>
            ),
          },
          {
            title: '5. PROWIZJA I OPŁATY',
            body: (
              <ol>
                <li>Fenige ponosi wobec Płatnika odpowiedzialność na zasadach określonych w Ustawie, z wyłączeniem odpowiedzialności za utracone korzyści.</li>
                <li>Fenige nie odpowiada za niewykonanie lub nieprawidłowe wykonanie zobowiązania będącego skutkiem działania zdarzenia siły wyższej.</li>
                <li>Fenige nie jest stroną i nie odpowiada za wykonanie umowy o świadczenie usługi PIS <strong>[A]</strong> i o wydanie instrumentu płatniczego Karty Płatniczej ani BLIK <strong>[B].</strong></li>
                <li>Płatnik odpowiedzialny jest za korzystanie z usług Fenige na podstawie niniejszego Regulaminu zgodnie z przepisami powszechnie obowiązującego prawa. W szczeg&oacute;lności Płatnik nie może korzystać z usług Transakcji do zapłaty za towary lub usługi, kt&oacute;rymi obr&oacute;t jest nielegalny albo w celu realizacji transakcji handlowych, kt&oacute;re naruszają prawa os&oacute;b trzecich.</li>
              </ol>
            ),
          },
          {
            title: '6. ODPOWIEDZIALNOŚĆ',
            body: (
              <ol>
                <li>Fenige ponosi wobec Płatnika odpowiedzialność na zasadach określonych w Ustawie, z wyłączeniem odpowiedzialności za utracone korzyści.</li>
                <li>Fenige nie odpowiada za niewykonanie lub nieprawidłowe wykonanie zobowiązania będącego skutkiem działania zdarzenia siły wyższej.</li>
                <li>Fenige nie jest stroną i nie odpowiada za wykonanie umowy o świadczenie usługi PIS <strong>[A]</strong> i o wydanie instrumentu płatniczego Karty Płatniczej ani BLIK <strong>[B].</strong></li>
                <li>Płatnik odpowiedzialny jest za korzystanie z usług Fenige na podstawie niniejszego Regulaminu zgodnie z przepisami powszechnie obowiązującego prawa. W szczeg&oacute;lności Płatnik nie może korzystać z usług Transakcji do zapłaty za towary lub usługi, kt&oacute;rymi obr&oacute;t jest nielegalny albo w celu realizacji transakcji handlowych, kt&oacute;re naruszają prawa os&oacute;b trzecich.</li>
              </ol>
            ),
          },
          {
            title: '7. REKLAMACJE',
            body: (
              <ol>
                <li>Fenige rozpatruje zgłoszone przez Płatnik&oacute;w reklamacje dotyczące Transakcji, w tym stwierdzonych Transakcji nieautoryzowanych, nieprawidłowo zainicjowanych, niewykonanych lub nienależycie wykonanych.</li>
                <li>Płatnik może złożyć reklamację:
                  <ol type="A">
                    <li>w formie pisemnej &ndash; przesyłką na adres Fenige albo osobiście w siedzibie Fenige,</li>
                    <li>w formie ustnej - osobiście w siedzibie Fenige lub pod numerem telefonu: +48 576 140 180,</li>
                    <li>w postaci elektronicznej na adres e-mail: <a href="mailto:complaints@fenige.pl">complaints@fenige.pl</a>.</li>
                  </ol>
                </li>
                <li>Reklamacje dotyczące Transakcji nieautoryzowanych, nieprawidłowo zainicjowanych, niewykonanych lub nienależycie wykonanych powinny zostać złożone niezwłocznie po zaistnieniu okoliczności budzących zastrzeżenia.</li>
                <li>Reklamacja powinna zawierać co najmniej:
                  <ol type="A">
                    <li>dane umożliwiające identyfikację Płatnika (imię i nazwisko, adres do korespondencji, datę złożenia Zlecenia Płatniczego, imię i nazwisko Odbiorcy),</li>
                    <li>opis przedmiotu reklamacji,</li>
                    <li>określenie żądania Płatnika,</li>
                    <li>wskazanie, że Płatnik chce aby odpowiedź na reklamację została przesłana mu na jego adres e-mail &ndash; o ile taka jest wola Płatnika.</li>
                  </ol>
                </li>
                <li>Do reklamacji powinny być dołączone kopie dokument&oacute;w (np. potwierdzeń dokonania lub odmowy dokonania Transakcji), kt&oacute;rych reklamacja dotyczy.</li>
                <li>Jeżeli podane w reklamacji dane lub informacje wymagają uzupełnienia w celu rozpatrzenia reklamacji, Fenige zwraca się do Płatnika o jej uzupełnienie we wskazanym zakresie i terminie. W takim przypadku Płatnik zobowiązany jest wsp&oacute;łpracować z Fenige, co ma na celu wyjaśnienie okoliczności reklamowanego zdarzenia.</li>
                <li>Fenige udziela odpowiedzi na reklamację w formie papierowej lub, po uzgodnieniu z Płatnikiem, na innym trwałym nośniku informacji (np. w postaci elektronicznej na adres e-mail, z kt&oacute;rego zostało wysłane zgłoszenie reklamacyjne).</li>
                <li>Odpowiedź na reklamację jest przygotowana i przekazana Płatnikowi bez zbędnej zwłoki, jednak nie p&oacute;źniej niż w terminie 15 Dni Roboczych od dnia wpływu reklamacji. W szczeg&oacute;lnie skomplikowanych przypadkach uniemożliwiających rozpatrzenie reklamacji i udzielenie odpowiedzi w terminie, Fenige:
                  <ol type="A">
                    <li>wyjaśni przyczyny braku możliwości dotrzymania terminu,</li>
                    <li>wskaże okoliczności, kt&oacute;re muszą zostać ustalone dla rozpatrzenia sprawy,</li>
                    <li>wskaże przewidywany termin udzielenia odpowiedzi, nie dłuższy jednak niż 35 Dni Roboczych od dnia otrzymania przez nią reklamacji.</li>
                  </ol>
                </li>
                <li>Do zachowania termin&oacute;w, o kt&oacute;rych mowa w ust. 8 niniejszego paragrafu, wystarczy wysłanie odpowiedzi przed ich upływem, a w przypadku odpowiedzi udzielonych na piśmie &ndash; nadanie w plac&oacute;wce pocztowej operatora wyznaczonego w rozumieniu ustawy z dnia 23 listopada 2012 roku &ndash; Prawo pocztowe. W przypadku niedotrzymania termin&oacute;w i warunk&oacute;w z ust. 8 niniejszego paragrafu, reklamację uważa się za rozpatrzoną zgodnie z wolą Płatnika.</li>
                <li>W przypadku uwzględnienia reklamacji, kt&oacute;rej wynikiem jest obowiązek zwrotu kwoty Transakcji, Fenige niezwłocznie zwraca Płatnikowi kwotę Transakcji wraz z Prowizją na rachunek płatniczy, z kt&oacute;rego została wykonana Transakcja (albo na rachunek płatniczy, z kt&oacute;rego Fenige otrzymała przelew od Płatnika w celu wykonania Transakcji Account-to-Card <strong>[A]</strong>), z wyjątkiem przypadku gdy Fenige ma uzasadnione i należycie udokumentowane podstawy, aby podejrzewać oszustwo, i poinformuje o tym w formie pisemnej organy powołane do ścigania przestępstw.</li>
                <li>Jeżeli Fenige nie uznała reklamacji Płatnika będącego konsumentem, a ten nie zgadza się z decyzją Fenige, Płatnik może zwr&oacute;cić się o mediację do jednego z sąd&oacute;w polubownych działających przy wojew&oacute;dzkich inspektoratach Inspekcji Handlowej. Więcej informacji jest dostępnych na stronie Urzędu Ochrony Konkurencji i Konsument&oacute;w: <a href="https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php">https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php</a>. Płatnik może r&oacute;wnież skorzystać z polubownego sposobu rozwiązania sporu przed Sądem Polubownym przy Komisji Nadzoru Finansowego.</li>
              </ol>
              
            ),
          },
          {
            title: '8. OCHRONA DANYCH OSOBOWYCH',
            body: (
              <ol>
                <li>Podanie danych osobowych w zakresie niezbędnym, wskazanym w 3 Regulaminu, jest dobrowolne, ale jest warunkiem koniecznym do zawarcia i wykonania Umowy o Pojedynczą Transakcję.</li>
                <li>Fenige posiada status krajowej instytucji płatniczej, co oznacza, że jest instytucją obowiązaną w rozumieniu Ustawy AML, kt&oacute;ra w ramach prowadzonej działalności ma obowiązek stosowania wobec Płatnik&oacute;w środk&oacute;w bezpieczeństwa finansowego (w tym identyfikacji i weryfikacji ich tożsamości) oraz wypełniania innych obowiązk&oacute;w z Ustawy AML i w tym zakresie pełni r&oacute;wnież rolę administratora ich danych osobowych.</li>
                <li>Fenige wyznaczyła inspektowa ochrony danych, z kt&oacute;rym można skontaktować się pod adresem e-mail: <a href="mailto:iod@fenige.pl">iod@fenige.pl</a> w sprawach dotyczących przetwarzania danych osobowych przez Fenige.</li>
                <li>Fenige przetwarza dane osobowe w celu:
                  <ol type="A">
                    <li>świadczenia usług płatniczych poprzez wykonywanie Transakcji oraz wykonywanie innych czynności przewidzianych w Regulaminie i w Ustawie (na podstawie art. 6 ust. 1 lit. b RODO),</li>
                    <li>realizacji obowiązk&oacute;w prawnych w związku z koniecznością stosowania odpowiednich środk&oacute;w bezpieczeństwa finansowego oraz realizacji innych obowiązk&oacute;w z Ustawy AML, a także innych obowiązk&oacute;w określonych przepisami powszechnie obowiązującego prawa, w szczeg&oacute;lności Ustawy czy ustaw rachunkowych i podatkowych (na podstawie art. 6 ust. 1 lit. c RODO),</li>
                    <li>ustalenia, dochodzenia lub obrony przed roszczeniami &ndash; na podstawie prawnie uzasadnionego interesu Fenige, jakim jest korzystanie z powyższych uprawnień (art. 6 ust. 1 lit. f RODO).</li>
                  </ol>
                </li>
                <li>Fenige jest uprawniona do przekazywania informacji o danych Płatnik&oacute;w oraz o zleconych przez nich Transakcjach odpowiednim organom państwowym i regulacyjnym, jeżeli taki obowiązek wynika z przepis&oacute;w prawa, w tym w szczeg&oacute;lności z Ustawy i Ustawy AML.</li>
                <li>Dane osobowe mogą być przekazywane Partnerowi, podmiotom uczestniczącym w procesowaniu Transakcji, takim jak Organizacje Płatnicze, Bank Płatnika i Bank Odbiorcy oraz dostawcom usług IT, dostawcom usług telekomunikacyjnych i dostawcom usług obsługi klienta w celu prawidłowego wykonania Transakcji oraz obsługi reklamacji.</li>
                <li>Dane osobowe Płatnik&oacute;w mogą podlegać profilowaniu w rozumieniu RODO w celu stosowania przez Fenige obowiązk&oacute;w nałożonych na nią Ustawą AML. Zautomatyzowane przetwarzanie dotyczy w takim przypadku osoby Płatnika i zlecanej przez niego Transakcji. Konsekwencją profilowania może być odmowa lub wstrzymanie wykonania Transakcji, zablokowanie możliwości zlecania wykonywania Transakcji w przyszłości oraz złożenie odpowiednich zawiadomień do organ&oacute;w państwowych przewidzianych w Ustawie AML.</li>
                <li>Każda osoba, kt&oacute;rej dane osobowe przetwarza Fenige, ma prawo dostępu do dotyczących jej danych, prawo ich sprostowania, poprawiania, żądania ograniczenia przetwarzania, przeniesienia, usunięcia, wniesienia sprzeciwu wobec ich dalszego przetwarzania przez Fenige, a w przypadku wyrażenia zgody na przetwarzanie danych do jej wycofania w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej wycofaniem, pisząc w tym celu na adres e-mail: <a href="mailto:iod@fenige.pl">iod@fenige.pl</a>. Wykonywanie powyższych praw nie może jednakże wpłynąć na prawa i obowiązki Fenige określone w ust. 4 b) i c) niniejszego paragrafu.</li>
                <li>Dane osobowe przetwarzane są przez okres niezbędny do wykonania Transakcji i rozpatrzenia reklamacji. Ponadto dane osobowe przetwarzane są w niezbędnym zakresie dla cel&oacute;w rachunkowych, podatkowych oraz dla cel&oacute;w zapobiegania praniu pieniędzy i finansowania terroryzmu przez okres wynikający z obowiązujących przepis&oacute;w prawa oraz w niezbędnym zakresie do ustalenia, dochodzenia lub obrony przed roszczeniami przez okres przedawnienia roszczeń wynikających z niniejszego Regulaminu oraz przez okres trwania toczącego się postępowania.</li>
                <li>Każda osoba, kt&oacute;rej dane osobowe przetwarza Fenige, ma prawo wniesienia skargi dotyczącej przetwarzania jej danych przez Fenige do organu nadzorczego zajmującego się ochroną danych osobowych - Prezesa Urzędu Ochrony Danych Osobowych.</li>
                <li>Pozostałe informacje na temat ochrony danych osobowych, a także na temat zasad wykorzystywania plik&oacute;w cookies zawiera polityka prywatności Fenige dostępna na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a>.</li>
              </ol>
            ),
          },
          {
            title: '9. BEZPIECZEŃSTWO',
            body: (
              <ol>
                <li>Płatnik powinien przede wszystkim stosować wszystkie środki bezpieczeństwa, wskazane mu w umowie o wydanie Karty Płatniczej i w umowie o wydanie BLIK <strong>[B]</strong> przez wydawc&oacute;w tych instrument&oacute;w płatniczych, w celu bezpiecznego przechowywania tych instrument&oacute;w płatniczych. Płatnik powinien r&oacute;wnież stosować się do wydawanych przez tych wydawc&oacute;w i dostarczanych mu komunikat&oacute;w o zachowaniu bezpieczeństwa oraz ostrzeżeń. Niezależnie od powyższego Fenige informuje Płatnika, że w stosunku do posiadanych instrument&oacute;w płatniczych, a także spersonalizowanych lub indywidualnych haseł, kod&oacute;w, urządzeń, informacji i danych wykorzystywanych przy zlecaniu Transakcji, musi on:
                  <ol type="A">
                    <li>odpowiednio je chronić, w tym nikomu nie ujawniać, ani nie udostępniać danych do logowania do konta w Serwisie ani instrument&oacute;w płatniczych służących do zlecania Transakcji,</li>
                    <li>ustalić hasło logowania, kt&oacute;rego odgadnięcie lub przełamanie jest trudne,</li>
                    <li>stosować blokady na urządzeniach elektronicznych służących do zlecania Transakcji oraz odpowiednie i aktualizowane na bieżąco programy antywirusowe,</li>
                    <li>nikomu nie udostępniać urządzeń lub innych materiał&oacute;w z zapisanymi lub widocznymi hasłami i kodami ani innego rodzaju informacjami służącymi do przejścia procedury uwierzytelniającej, co mogłoby spowodować wykonanie nieautoryzowanej Transakcji, a także starannie chronić takie materiały i urządzania, w tym nie używać na nich program&oacute;w do automatycznego zapisywania haseł,</li>
                    <li>chronić dane osobowe, w tym zawierające je dokumenty, przed dostępem os&oacute;b nieuprawnionych, tak aby nikt nie m&oacute;gł podszywać się pod Płatnika podczas procedury weryfikacyjnej.</li>
                  </ol>
                </li>
                <li>Płatnik zobowiązuje się niezwłocznie zgłosić Fenige stwierdzenie utraty, kradzieży, przywłaszczenia albo nieuprawnionego dostępu lub użycia instrumentu płatniczego, a także hasła do konta w Serwisie, hasła jednorazowego, telefonu oraz wszelkich innych danych służących do weryfikacji Płatnika i uwierzytelnienia zlecanych przez niego Transakcji, co stwarza ryzyko przeprowadzenia Transakcji przez osobę nieuprawnioną z ich wykorzystaniem. Płatnik powinien dokonać zgłoszenia:
                  <ol type="A">
                    <li>pocztą elektroniczną na adres e-mail wskazany na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt,</li>
                    <li>telefonicznie pod numerem telefonu wskazany na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt.</li>
                  </ol>
                </li>
              </ol>
            ),
          },
          {
            title: '10. ZMIANA TREŚCI REGULAMINU',
            body: (
                <ol>
                  <li>Niniejszy Regulamin może zostać zmieniony przez Fenige jednostronnie w każdym czasie.</li>
                  <li>Aktualna treść Regulaminu dostępna jest w Serwisie.</li>
                </ol>
            ),
          },
          {
            title: '11. OKRES ZWIĄZANIA PŁATNIKA UMOWĄ O POJEDYNCZĄ TRANSAKCJĘ',
            body: (
                <ol>
                  <span>Umowa o Pojedynczą Transakcję w wersji zaakceptowanej przez Płatnika wiąże strony wyłącznie w stosunku do jednej konkretnej Transakcji, do kt&oacute;rej wykonania została zawarta (przez co należy rozumieć zaakceptowanie przez Płatnika Regulaminu bezpośrednio przed zleceniem tej Transakcji, o ile Transakcja została następnie zlecona przed wygaśnięciem sesji na urządzeniu) oraz przez czas wykonywania tej Transakcji, związanego z nią ewentualnego zwrotu, a także przez czas rozpatrywania reklamacji i spor&oacute;w w stosunku do niej.</span>
                </ol>
            ),
          },
          {
            title: '12. POSTANOWIENIA KOŃCOWE',
            body: (
              <ol>
                <li>Wymagania techniczne dla Płatnika niezbędne do wsp&oacute;łpracy z systemem teleinformatycznym, kt&oacute;rym posługuje się Fenige to posiadanie urządzenia z dostępem do sieci Internet umożliwiającego obsługę Serwisu (zgodnie z opisem wymog&oacute;w technicznych podanym w Regulaminie Serwisu) i posiadanie aktywnej poczty e-mail. Płatnik ponosi wszelkie koszty związane z transmisją danych w sieci Internet.</li>
                <li>Zakazane jest dostarczanie przez Płatnik&oacute;w do systemem teleinformatycznego Fenige treści o charakterze bezprawnym, naruszających zasady wsp&oacute;łżycia społecznego oraz dobre obyczaje.</li>
                <li>Niniejszy Regulamin podlega prawu polskiemu. W sprawach nieuregulowanych w Regulaminie zastosowanie mają powszechnie obowiązujące przepisy polskiego prawa, a w szczeg&oacute;lności Ustawy i Ustawy AML.</li>
                <li>W przypadku, gdy poszczeg&oacute;lne postanowienia Regulaminu okażą się nieważne lub bezskuteczne w całości lub w części z dowolnej przyczyny, pozostałe postanowienia Regulaminu pozostają w mocy.</li>
                <li>Z zastrzeżeniem przepis&oacute;w bezwzględnie obowiązujących, sądem właściwym do rozpatrywania spor&oacute;w powstałych w związku z Regulaminem jest:
                  <ol type="A">
                    <li>w sprawach z Płatnikami nie będącymi konsumentami sąd miejscowo właściwy dla siedziby Fenige,</li>
                    <li>w sprawach z Płatnikami będącymi konsumentami sąd miejscowo właściwy dla miejsca zamieszkania konsumenta, a w przypadku kiedy pow&oacute;dztwo kierowane jest przeciwko Fenige r&oacute;wnież sąd miejscowo właściwy dla siedziby Fenige.</li>
                  </ol>
                </li>
                <li>Jeśli Płatnik nie jest konsumentem, nie stosuje się do niego przepis&oacute;w Ustawy, kt&oacute;rych wyłączenie w stosunkach z podmiotami innymi niż konsumenci jest dopuszczalne.</li>
                <li>Wszelkie pytania i zgłoszenia dotyczące niniejszego Regulaminu i świadczenia na jego podstawie usług należy kierować do biura obsługi klienta Fenige:
                  <ol type="A">
                    <li>pocztą elektroniczną na adres e-mail wskazany na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt,</li>
                    <li>telefonicznie pod numerem telefonu wskazanym na stronie <a href="https://www.fenige.com">https://www.fenige.com/pl</a> w zakładce: kontakt,</li>
                    <li>listowanie na adres siedziby Fenige.</li>
                  </ol>
                </li>
                <span>Wersja Regulaminu obowiązująca od dnia: 27 marca 2024</span>
              </ol>
            ),
          },
        ]}
      />
    </section>
  );
};

export default TermsOfTransactionServicesPL;
