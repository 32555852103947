import Button from "./Button";
// import ButtonApp from "./ButtonApp";
import apple from "icons/apple.svg";

const AppStoreButton = ({ link, openInNewTab }: { link: string; openInNewTab?: boolean }) => {
  // const clickAlert = () => {
  //   alert("Very soon the Orovera magic will be available on your smartphone.");
  // };
  const target = openInNewTab ? '_blank' : '_self';

  return (
    <Button
    // <ButtonApp
      // href="https://apps.apple.com/ua/app/%D0%B6%D0%B0%D0%B1%D0%BA%D0%B0/id1578714013"
      href={link}
      target={target}
      // target="_blank"
      onClick={() => {}}
      // onClick={clickAlert}
      className="download-app-button"
    >
      <img src={apple} alt="app store" />
      <span>App Store</span>
    </Button>
  );
};

export default AppStoreButton;
