import Button from "./Button";
// import ButtonApp from "./ButtonApp";
import googlePlay from "icons/google-play.svg";

const GooglePlayButton = ({ link, openInNewTab }: { link: string; openInNewTab?: boolean }) => {
  // const clickAlert = () => {
  //   alert("Very soon the Orovera magic will be available on your smartphone.");
  // };
  const target = openInNewTab ? '_blank' : '_self';

  return (
    <Button
      // <ButtonApp
      href={link}
      target={target}
      // href="https://play.google.com/store/apps/details?id=ua4ek.board&pcampaignid=web_share"
      // target="_blank"
      // onClick={clickAlert}
      onClick={() => {}}
      className="download-app-button"
    >
      <img src={googlePlay} alt="google play" />
      <span>Google Play</span>
    </Button>
  );
};

export default GooglePlayButton;
