import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useMediaQuery } from "@uidotdev/usehooks";
import Footer from "./Footer";
import Header from "./Header";

const Layout = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <Toaster position={isSmallDevice ? "bottom-center" : "top-center"} />
    </>
  );
};

export default Layout;
