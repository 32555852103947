import { ReactNode } from "react";
import Heading from "./typography/Heading";
import Paragraph from "./typography/Paragraph";

interface SectionHeaderProps {
  title: ReactNode;
  body: ReactNode;
  img?: string;
}

const SectionHeader = ({ title, body, img }: SectionHeaderProps) => {
  return (
    <header className="section-header">
      <div className="heading-wrapper">
        <Heading size="h2">{title}</Heading>
        {img && (
          <div className="image-wrapper">
            <img src={img} />
          </div>
        )}
      </div>
      {typeof body === "string" ? <Paragraph size="L">{body}</Paragraph> : body}
    </header>
  );
};

export default SectionHeader;
