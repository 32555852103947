import UndelineLink, { UndelineLinkProps } from "./UndelineLink";

const UndelineLinkSection = ({ to, name }: UndelineLinkProps) => {
  return (
    <section className="underline-link-section">
      <UndelineLink to={to} name={name} />
    </section>
  );
};

export default UndelineLinkSection;
