import { ReactNode } from "react";

interface ParagraphProps {
  size: "S" | "M" | "L";
  children: ReactNode;
}

const Paragraph = ({ size, children }: ParagraphProps) => {
  const getClassName = () => {
    switch (size) {
      case "S":
        return "paragraph-small";
      case "M":
        return "paragraph-medium";
      case "L":
        return "paragraph-large";
    }
  };

  return <p className={getClassName()}>{children}</p>;
};

export default Paragraph;
