import { Link } from "react-router-dom";

export interface UndelineLinkProps {
  to: string;
  name: string;
}

const UndelineLink = ({ to, name }: UndelineLinkProps) => {
  return (
    <Link to={to} className="underline-link">
      {name}
    </Link>
  );
};

export default UndelineLink;
