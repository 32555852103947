import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { sendMessage } from "api/support";

import Input from "components/form/Input";
import Textarea from "components/form/Textarea";
import bgPng from "images/en/faq/сontact-us.png";
import twistedArrowPng from "images/en/faq/twisted-arrow.png";
import toast from "react-hot-toast";
import Spinner from "components/Spinner";

const StillHaveSomeQuestions = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [messageLoading, setMessageLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleMessageFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setMessageLoading(true);

      await sendMessage(email, message);

      setEmail("");
      setMessage("");

      toast.success(t("general.Success"));
    } catch (err) {
      toast.error(t("general.Something happened, try again later."));
    } finally {
      setMessageLoading(false);
    }
  };

  return (
    <div className="contact-us-section">
      <img src={bgPng} alt="background" />

      <div>
        <h3>{t("faq.still-have-some-question.Title")}</h3>
        <p>{t("faq.still-have-some-question.Description")}</p>
      </div>

      <form onSubmit={handleMessageFormSubmit}>
        <Input
          value={email}
          placeholder={t("faq.still-have-some-question.form.Your email")}
          onChangeText={setEmail}
          required
        />

        <Textarea
          value={message}
          placeholder={t("faq.still-have-some-question.form.Message")}
          onChangeText={setMessage}
          required
        />

        <button className="send-btn" type="submit">
          {messageLoading ? (
            <Spinner />
          ) : (
            t("faq.still-have-some-question.form.Send")
          )}
        </button>

        <img
          className="twisted-arrow"
          src={twistedArrowPng}
          alt="twisted-arrow"
        />
      </form>
    </div>
  );
};

export default StillHaveSomeQuestions;
