import { FC, useMemo, useState } from "react";

interface MobileMenuModalProps {
  title: string;
  isOpen: boolean;
  items: {
    title: string;
    onClick(): void;
  }[];
}

const MobileMenuModal: FC<MobileMenuModalProps> = (props) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const items = useMemo(() => {
    return props.items.map((it, index) => {
      const isActive = index === activeItemIndex;
      return (
        <div
          className={
            isActive
              ? "mobile-modal__item mobile-modal__item_active"
              : "mobile-modal__item"
          }
          onClick={() => {
            setActiveItemIndex(index);
            it.onClick();
          }}
        >
          <div />

          <span>{it.title}</span>
        </div>
      );
    });
  }, [props.items, activeItemIndex]);

  return (
    <div className={`mobile-modal ${props.isOpen ? "mobile-modal_open" : ""}`}>
      <h3>{props.title}</h3>

      <div>{items}</div>
    </div>
  );
};

export default MobileMenuModal;
