import Control from "components/home/Control";
import HowItWorks from "components/home/HowItWorks";
import Main from "components/home/Main";
import PersonalPayments from "components/home/PersonalPayments";
import Security from "components/home/Security";
import useScrollToAnchor from "hooks/useScrollToAnchor";

const Home = () => {
  useScrollToAnchor();

  return (
    <section id="home">
      <Main />
      <PersonalPayments />
      <HowItWorks />
      <Security />
      <Control />
    </section>
  );
};

export default Home;
