import { FormEvent, FormEventHandler, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import frogPainting from "images/en/contact-us/frog-painting.png";
import locationIcon from "icons/location.svg";
import map from "images/en/contact-us/map.jpg";
import arrowImg from "images/en/contact-us/arrow.png";
import frogWelcome from "images/en/contact-us/frog-welcome.png";
import Input from "components/form/Input";
import Textarea from "components/form/Textarea";
import FooterSocials from "components/FooterSocials";
import PhoneInput from "components/PhoneInput";
import CollapseCard from "components/CollapseCard";
import UndelineLinkSection from "components/links/UnderlineLinkSection";
import { sendCallback, sendMessage } from "api/support";
import toast from "react-hot-toast";
import Spinner from "components/Spinner";

type AddressLocationProps = {
  address: string;
};

const AddressLocation = ({ address }: AddressLocationProps) => {
  const { t } = useTranslation();

  return (
    <div className="address-location-wrapper">
      <p className="address">{address}</p>
      {/* <div className="location">
        <img src={locationIcon} alt="location" />
        <p>{t("contactUs.Get directions")}</p>
      </div> */}
    </div>
  );
};

const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);

  const { t } = useTranslation();

  const handleMessageFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setMessageLoading(true);

      await sendMessage(email, message);

      setEmail("");
      setMessage("");

      toast.success(t("general.Success"));
    } catch (err) {
      toast.error(t("general.Something happened, try again later."));
    } finally {
      setMessageLoading(false);
    }
  };

  const handlePhoneFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setPhoneLoading(true);

      await sendCallback(phone);

      setEmail("");
      setMessage("");

      toast.success(t("general.Success"));
    } catch (err) {
      toast.error(t("general.Something happened, try again later."));
    } finally {
      setPhoneLoading(false);
    }

    return;
  };

  return (
    <section id="contact-us">
      <main>
        <div className="container">
          <header>
            <h1>
              {t("contactUs.Contact")}
              <br />
              <span>
                {t("contactUs.Us")}
                <img src={frogPainting} alt="frog painting" />
              </span>
            </h1>
          </header>
          <footer>
            <form onSubmit={handleMessageFormSubmit}>
              <Input
                value={email}
                placeholder={t("contactUs.Your email")}
                onChangeText={setEmail}
                type="email"
                required
              />
              <Textarea
                value={message}
                placeholder={t("contactUs.Message")}
                onChangeText={setMessage}
                minRows={5}
                required
              />
              <div className="button-container">
                <button className="button" type="submit">
                  {messageLoading ? <Spinner /> : t("contactUs.Send")}
                </button>
              </div>
            </form>
            <div className="form-info-container">
              <div className="address-container">
                <h4>{t("contactUs.Address")}</h4>
                <div className="address-location-items">
                  {/* <AddressLocation address="10, Promienna, 03-672, Warszawa, Poland" /> */}
                  <AddressLocation address="17A, Rusałka, 20-103, Lublin, Poland" />
                  {/* <AddressLocation address="13, Hrushevskogo, 78-204, Kolomiya, Ukraine" /> */}
                </div>
              </div>
              <div className="follow-us-container">
                <h4>{t("contactUs.Follow us")}</h4>
                <FooterSocials />
              </div>
            </div>
          </footer>
        </div>
      </main>
      <div className="map">
        <div className="container">
          <img src={map} alt="map" className="img-fluid" />
        </div>
      </div>
      <div className="call-back">
        <div className="call-back-wrapper">
          <div className="container">
            <div className="frog-img-wrapper">
              <img src={frogWelcome} alt="frog welcome" />
            </div>
            <div className="arrow-img-wrapper">
              <img src={arrowImg} alt="arrow" />
            </div>
            <h2>
              <Trans i18nKey="contactUs.callBackTitle"></Trans>
            </h2>
            <PhoneInput
              onChange={setPhone}
              onSubmit={handlePhoneFormSubmit}
              loading={phoneLoading}
            />
          </div>
        </div>
      </div>
      {/* <div className="questions">
        <div className="container">
          <div className="faq-footer-container">
            <h1 className="text-start mb-md">
              {t("contactUs.questionsTitle")}
            </h1>
            <CollapseCard
              containerClassName="mb-sm"
              title="Where do I start?"
              body="Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Repellat officiis dicta est, voluptas
          facilis ea laborum tempore esse distinctio mollitia fuga harum officia
          aperiam rerum voluptatum veritatis corporis eius labore!"
            />
            <CollapseCard
              containerClassName="mb-sm"
              title="How to create an account for online payment? "
              body="Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Repellat officiis dicta est, voluptas
          facilis ea laborum tempore esse distinctio mollitia fuga harum officia
          aperiam rerum voluptatum veritatis corporis eius labore!"
            />
            <CollapseCard
              containerClassName="mb-md"
              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor"
              body="Lorem ipsum dolor, sit amet
          consectetur adipisicing elit. Repellat officiis dicta est, voluptas
          facilis ea laborum tempore esse distinctio mollitia fuga harum officia
          aperiam rerum voluptatum veritatis corporis eius labore!"
            />
            <UndelineLinkSection name={t("contactUs.Go to FAQ")} to="/" />
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default ContactUs;
